import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { fetchData } from 'actions/index'

export const GraphqlPostButton = props => {
  const handleOnClick = () => {
    const context = props.context

    // TODO: featureFlags should be a part of props.data, not a separate param
    props.dispatch(fetchData(context, props.data, props.featureFlags))
  }

  return (
    <button
      className='graphql-post-button button-primary'
      disabled={props.disabled}
      onClick={handleOnClick}
    >
      {props.buttonText}
    </button>
  )
}

GraphqlPostButton.propTypes = {
  dispatch: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
  featureFlags: PropTypes.array,
  data: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  context: PropTypes.string.isRequired,
  gaLabel: PropTypes.string,
}

GraphqlPostButton.defaultProps = {
  buttonText: 'View',
}

const connectedGraphqlPostButton = connect()(GraphqlPostButton)

export default connectedGraphqlPostButton
