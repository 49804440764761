import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { MultiSelect } from 'react-multi-select-component'

import { setGlobalFilter } from 'actions/index'

export const RegionsFilter = props => {
  const selectedRegions = props.regions.map(i => {
    return { label: i, value: i }
  })
  const countryRef = useRef(props.country)
  const [selected, setSelected] = useState(selectedRegions)
  const countryNotSelected = () => !props.country
  const overrideStrings = {
    allItemsAreSelected: 'All Regions',
    selectSomeItems: 'Please select',
  }

  useEffect(() => {
    props.dispatch(
      setGlobalFilter(
        'regions',
        selected.map(a => a.value),
      ),
    )
  }, [selected])

  useEffect(() => {
    if (countryRef.current !== props.country) {
      countryRef.current = props.country
      setSelected([])
    }
  }, [props.country])

  const getOptions = () => {
    const regions = props.countryRegionsMapping[props.country]
    return regions
      ? regions.map(i => {
          return { label: i, value: i }
        })
      : []
  }

  return (
    <div className='regions-filter multi-select-filter'>
      <label className='filter__label'>Regions</label>
      <MultiSelect
        options={getOptions()}
        value={selected}
        disabled={countryNotSelected()}
        onChange={setSelected}
        labelledBy='Select'
        overrideStrings={overrideStrings}
      />
    </div>
  )
}

RegionsFilter.propTypes = {
  dispatch: PropTypes.func.isRequired,
  context: PropTypes.string.isRequired,
  countryRegionsMapping: PropTypes.object.isRequired,
  country: PropTypes.string.isRequired,
  regions: PropTypes.array.isRequired,
}

const connectedFilter = connect()(RegionsFilter)

export default connectedFilter
