import {
  FAILED_ORDER_MOVEMENT_FAILED,
  FAILED_ORDER_MOVEMENT_REQUESTED,
  FAILED_ORDER_MOVEMENT_SUCCEEDED,
} from 'actions/types'

const initialState = {
  isLoading: false,
  country: null,
  movementDate: null,
  responseBody: null,
}

const failedOrderMovements = (state = initialState, action) => {
  switch (action.type) {
    case FAILED_ORDER_MOVEMENT_REQUESTED:
      return {
        ...state,
        isLoading: true,
      }
    case FAILED_ORDER_MOVEMENT_SUCCEEDED:
      return {
        isLoading: false,
        responseBody: action.data.responseBody,
      }
    case FAILED_ORDER_MOVEMENT_FAILED:
      return initialState
    default:
      return state
  }
}

export default failedOrderMovements
