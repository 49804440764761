import {
  MAPPINGS_FETCH_REQUESTED,
  MAPPINGS_FETCH_SUCCEEDED,
  MAPPINGS_FETCH_FAILED,
} from 'actions/types'

const initialState = {
  isLoading: false,
  countryRegionsMapping: {},
  countryProductionSitesMapping: {},
  countryBrandShippersMapping: [],
}

const mappings = (state = initialState, action) => {
  switch (action.type) {
    case MAPPINGS_FETCH_REQUESTED:
      return {
        ...state,
        isLoading: true,
        countryRegionsMapping: {},
        countryProductionSitesMapping: {},
        countryBrandShippersMapping: [],
      }
    case MAPPINGS_FETCH_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        countryRegionsMapping: action.data.countryRegionsMapping,
        countryProductionSitesMapping: action.data.countryProductionSitesMapping,
        countryBrandShippersMapping: action.data.countryBrandShippersMapping,
      }
    case MAPPINGS_FETCH_FAILED:
      return initialState
    default:
      return state
  }
}

export default mappings
