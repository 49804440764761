import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import GraphqlPostButton from 'components/shared/graphqlPostButton/graphqlPostButton'
import { SHIPMENT_OPTIONS } from 'actions/context'

export function ShipmentOptionsPostButton(props) {
  const isBtnDisabled = () => {
    return !props.data.country
  }

  const gaParams = () => {
    return JSON.stringify(props.data)
  }

  return (
    <GraphqlPostButton
      data={props.data}
      disabled={isBtnDisabled()}
      context={SHIPMENT_OPTIONS}
      gaLabel={gaParams()}
    />
  )
}

ShipmentOptionsPostButton.propTypes = {
  data: PropTypes.shape({
    country: PropTypes.string,
    brands: PropTypes.string,
    first: PropTypes.number.isRequired,
    zipcode: PropTypes.string,
    regions: PropTypes.array,
    productionSite: PropTypes.string,
    shipper: PropTypes.string,
    wday: PropTypes.string,
  }),
}

const connectedShipmentOptionsPostButton = connect()(ShipmentOptionsPostButton)
export default connectedShipmentOptionsPostButton
