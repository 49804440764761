import React from 'react'
import PropTypes from 'prop-types'

import Filter from 'components/shared/filter/filter'

export default function RegionsFilter(props) {
  return (
    <Filter
      name='region'
      defaultText='All regions'
      onChange={props.onChange}
      data={props.regions}
    />
  )
}

RegionsFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  regions: PropTypes.array.isRequired,
}
