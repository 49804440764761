import React from 'react'
import ZiplogTable from 'components/shared/ziplogTable/ziplogTable'
import Pagination from 'components/shared/pagination/pagination'
import { SHIPMENT_OPTIONS } from 'actions/context'
import PropTypes from 'prop-types'

import './shipmentOptionsTable.css'
import { displayField, numberToWeekday } from 'helpers/helpers'

import { STATUSES } from 'helpers/constants'

const HEADERS = [
  'id',
  'region',
  'zipcode',
  'weekday',
  'from',
  'to',
  'shipperName',
  'productionSite',
  'transitTime',
  'priority',
  'startDate',
  'endDate',
]

export const ShipmentOptionsTable = ({ shipmentOptions, paginationData, status }) => {
  const rowData = shipmentOptions => {
    return shipmentOptions.map(shipmentOption => ({
      ...shipmentOption,
      endDate: displayField(shipmentOption.endDate),
      weekday: numberToWeekday(shipmentOption.wday),
    }))
  }

  if (status === STATUSES.requested) {
    return <div className='shipment-options-table__loader'>Loading...</div>
  } else if (status === STATUSES.resolved && shipmentOptions.length === 0) {
    return <div className='shipment-options-table__no-results-msg'>No shipment options found.</div>
  } else if (shipmentOptions.length === 0) {
    return null
  }
  return (
    <div>
      <ZiplogTable
        tableName='shipment-options-table'
        headers={HEADERS}
        data={rowData(shipmentOptions)}
      />

      <Pagination context={SHIPMENT_OPTIONS} {...paginationData} />
    </div>
  )
}

ShipmentOptionsTable.propTypes = {
  shipmentOptions: PropTypes.arrayOf(
    PropTypes.shape({
      dayOfWeek: PropTypes.string,
      dayOfWeekNumber: PropTypes.number,
      endDate: PropTypes.string,
      from: PropTypes.string,
      id: PropTypes.number,
      priority: PropTypes.number,
      productionSite: PropTypes.string,
      region: PropTypes.string,
      shipperName: PropTypes.string,
      startDate: PropTypes.string,
      to: PropTypes.string,
      transitTime: PropTypes.number,
      zipcode: PropTypes.string,
    }),
  ),
  paginationData: PropTypes.shape({
    data: PropTypes.object,
    pageInfo: PropTypes.shape({
      endCursor: PropTypes.string,
      hasNextPage: PropTypes.bool,
      hasPreviousPage: PropTypes.bool,
      startCursor: PropTypes.string,
    }),
  }),
  status: PropTypes.number.isRequired,
}

export default ShipmentOptionsTable
