export const STATUSES = {
  initial: 0,
  requested: 1,
  resolved: 2,
  failed: 3,
}

export const WEEKDAYS = {
  Mon: 1,
  Tue: 2,
  Wed: 3,
  Thu: 4,
  Fri: 5,
  All: '',
}
