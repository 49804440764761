import React from 'react'
import PropTypes from 'prop-types'
import BaseModal from 'components/modal/baseModal'

import { connect } from 'react-redux'
import { hideModal } from 'actions/modal'

import './confirmDialog.css'

export const ConfirmDialog = props => {
  const closeModal = () => {
    props.dispatch(hideModal())
  }

  const onConfirmWrap = () => {
    props.onConfirm()
    closeModal()
  }

  return (
    <BaseModal className='confirm-dialog' onClose={closeModal}>
      <div className='confirm-dialog__title'>{props.title}</div>
      <div className='confirm-dialog__content'>
        <div className='confirm-dialog__message'>{props.message}</div>
        <div className='confirm-dialog__warning'>{props.warning}</div>
      </div>
      <div className='confirm-dialog__buttons'>
        <button className='button-secondary' onClick={closeModal}>
          No
        </button>
        <button className='button-primary' onClick={onConfirmWrap}>
          Yes
        </button>
      </div>
    </BaseModal>
  )
}

ConfirmDialog.propTypes = {
  dispatch: PropTypes.func.isRequired,
  title: PropTypes.string,
  message: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  warning: PropTypes.string,
}

ConfirmDialog.defaultProps = {
  title: 'Confirmation',
  message: 'Are you sure?',
  warning: '',
}

function mapStateToProps(state) {
  return {
    message: state.modal.data.message,
    onConfirm: state.modal.data.onConfirm,
    warning: state.modal.data.warning,
  }
}

const connectedConfirmDialog = connect(mapStateToProps)(ConfirmDialog)
export default connectedConfirmDialog
