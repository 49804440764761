import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { setFilters } from 'actions/index'
import { SHIPMENT_OPTIONS } from 'actions/context.js'
import Filter from 'components/shared/filter/filter'
import { isEmpty } from 'lodash'

export const ShippersFilter = props => {
  const { countryBrandShippersMapping = [], country, availableFilters } = props

  const handleChange = e => {
    props.dispatch(setFilters(SHIPMENT_OPTIONS, 'shipper', e.target.value))
  }

  const updateShippers = () => {
    if (country && !isEmpty(countryBrandShippersMapping)) {
      availableFilters['shippers'] =
        country && countryBrandShippersMapping
          ? [
              ...new Set(
                countryBrandShippersMapping.filter(a => a.country === country).map(a => a.name),
              ),
            ]
          : []
      props.dispatch(setFilters(SHIPMENT_OPTIONS, 'availableFilters', availableFilters))
    }
  }

  useEffect(() => {
    updateShippers()
  }, [country, countryBrandShippersMapping])

  updateShippers()

  const countryNotSelected = () => !props.country

  return (
    <Filter
      name='shipper'
      displayName='shipper'
      onChange={handleChange}
      data={props.availableFilters['shippers']}
      selectedValue={props.shipper}
      disabled={countryNotSelected()}
      defaultValue='all'
      defaultText='All Shippers'
      labelText='Shipper'
    />
  )
}

ShippersFilter.propTypes = {
  dispatch: PropTypes.func.isRequired,
  availableFilters: PropTypes.object.isRequired,
  country: PropTypes.string,
  shipper: PropTypes.string.isRequired,
  countryBrandShippersMapping: PropTypes.array,
}

const mapStateToProps = state => {
  return {
    availableFilters: state.shipmentOptions.availableFilters,
    country: state.globalFilters.country,
    shipper: state.shipmentOptions.shipper,
  }
}

const connectedShippersFilter = connect(mapStateToProps)(ShippersFilter)
export default connectedShippersFilter
