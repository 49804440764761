import React from 'react'

import './notFound.css'

const NotFound = () => {
  return (
    <div className='not-found'>
      <img src='images/404_cat.png' alt='not found' className='not-found-image' />
      <p className='not-found-text'>
        Sorry! We lost this <span>shipment</span> page.
      </p>
    </div>
  )
}

export default NotFound
