import React, { useState } from 'react'
import PropTypes from 'prop-types'
import './accordion.css'

const Accordion = ({ title, content }) => {
  const [isActive, setIsActive] = useState(false)

  return (
    <div className='accordion__item'>
      <div className='accordion__title' onClick={() => setIsActive(!isActive)}>
        <div>{title}</div>
        <div>{isActive ? '-' : '+'}</div>
      </div>
      {isActive && <div className='accordion__content'>{content}</div>}
    </div>
  )
}

Accordion.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
}

export default Accordion
