import ConfirmDialog from './confirmDialog/confirmDialog'
import ExceptionsSaveModal from './exceptionsSaveModal/exceptionsSaveModal'
import ExceptionsDeleteModal from './exceptionsDeleteModal/exceptionsDeleteModal'
import deliveryOptionsNewPreviewModal from './deliveryOptionsNew/previewModal'
import deliveryOptionsUpdatePreviewModal from './deliveryOptionsUpdate/previewModal'
import deliveryOptionsPausePreviewModal from './deliveryOptionsPause/previewModal'
import zipcodesNewPreviewModal from './zipcodesNew/previewModal'
import zipcodesUpdatePreviewModal from './zipcodesUpdate/previewModal'
import ShipAddressUpdateModal from './shipAddressUpdateModal/shipAddressUpdateModal'
import orderMovementPreviewModal from './orderMovement/previewModal'
import dynamicOptionsUpdatePreviewModal from './dynamicOptionsUpdate/previewModal'

export const CONFIRM_DIALOG_MODAL = 'CONFIRM_DIALOG_MODAL'
export const EXCEPTIONS_SAVE_MODAL = 'EXCEPTIONS_SAVE_MODAL'
export const EXCEPTIONS_DELETE_MODAL = 'EXCEPTIONS_DELETE_MODAL'
export const DELIVERY_OPTIONS_NEW_PREVIEW_MODAL = 'DELIVERY_OPTIONS_NEW_PREVIEW_MODAL'
export const DELIVERY_OPTIONS_UPDATE_PREVIEW_MODAL = 'DELIVERY_OPTIONS_UPDATE_PREVIEW_MODAL'
export const DELIVERY_OPTIONS_PAUSE_PREVIEW_MODAL = 'DELIVERY_OPTIONS_PAUSE_PREVIEW_MODAL'
export const ZIPCODES_NEW_PREVIEW_MODAL = 'ZIPCODES_NEW_PREVIEW_MODAL'
export const ZIPCODES_UPDATE_PREVIEW_MODAL = 'ZIPCODES_UPDATE_PREVIEW_MODAL'
export const SHIP_ADDRESS_UPDATE_MODAL = 'SHIP_ADDRESS_UPDATE_MODAL'
export const ORDER_MOVEMENT_MODAL = 'ORDER_MOVEMENT_MODAL'
export const DYNAMIC_OPTIONS_UPDATE_PREVIEW_MODAL = 'DYNAMIC_OPTIONS_UPDATE_PREVIEW_MODAL'

export const MODAL_TYPES = {
  [CONFIRM_DIALOG_MODAL]: ConfirmDialog,
  [EXCEPTIONS_SAVE_MODAL]: ExceptionsSaveModal,
  [EXCEPTIONS_DELETE_MODAL]: ExceptionsDeleteModal,
  [DELIVERY_OPTIONS_NEW_PREVIEW_MODAL]: deliveryOptionsNewPreviewModal,
  [DELIVERY_OPTIONS_UPDATE_PREVIEW_MODAL]: deliveryOptionsUpdatePreviewModal,
  [DELIVERY_OPTIONS_PAUSE_PREVIEW_MODAL]: deliveryOptionsPausePreviewModal,
  [ZIPCODES_NEW_PREVIEW_MODAL]: zipcodesNewPreviewModal,
  [ZIPCODES_UPDATE_PREVIEW_MODAL]: zipcodesUpdatePreviewModal,
  [SHIP_ADDRESS_UPDATE_MODAL]: ShipAddressUpdateModal,
  [ORDER_MOVEMENT_MODAL]: orderMovementPreviewModal,
  [DYNAMIC_OPTIONS_UPDATE_PREVIEW_MODAL]: dynamicOptionsUpdatePreviewModal,
}
