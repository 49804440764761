import React from 'react'
import PropTypes from 'prop-types'
import './infoTip.css'

const InfoTip = props => {
  return (
    <div className='infotip'>
      <img className='infotip__icon' src='images/question-mark-icon.svg' alt='infotip__icon' />
      <span className='infotip__text'>{props.data}</span>
    </div>
  )
}

InfoTip.propTypes = {
  data: PropTypes.object,
}

export default InfoTip
