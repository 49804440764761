import React from 'react'
import { filter } from 'lodash'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import DeliveryDaysFilter from './deliveryDaysFilter/deliveryDaysFilter'
import ProductionSitesFilter from './productionSitesFilter/productionSitesFilter'
import RegionsFilter from './regionsFilter/regionsFilter'
import SuburbsFilter from './suburbsFilter/suburbsFilter'
import ShippersFilter from './shippersFilter/shippersFilter'
import { deliveryOptionsUpdateFilters } from 'actions'

import './deliveryOptionsFilters.css'

export class DeliveryOptionsFilters extends React.PureComponent {
  calculateAvailableFilters() {
    const filteredOptions = filter(this.props.data, this.props.activeFilters)

    let productionSites = new Set()
    let regions = new Set()
    let suburbs = new Set()
    let shippers = new Set()
    let deliveryDays = new Set()

    filteredOptions.forEach(filteredOption => {
      productionSites.add(filteredOption.productionSite)
      regions.add(filteredOption.region)
      suburbs.add(filteredOption.suburb)
      shippers.add(filteredOption.shipper)
      deliveryDays.add(filteredOption.deliveryDay)
    })

    return {
      productionSites: [...productionSites],
      regions: [...regions],
      suburbs: [...suburbs],
      shippers: [...shippers],
      deliveryDays: [...deliveryDays],
    }
  }

  updateActiveFilters = e => {
    const filterName = e.target.name
    const filterValue = e.target.value

    let newFilters = this.props.activeFilters

    if (filterValue === 'all') {
      delete newFilters[filterName]
    } else if (filterValue === 'null') {
      newFilters[filterName] = null
    } else {
      newFilters[filterName] = filterValue
    }

    this.props.dispatch(deliveryOptionsUpdateFilters(newFilters))
  }

  render() {
    if (!this.props.data || this.props.data.length < 1) {
      return null
    }

    const availableFilters = this.calculateAvailableFilters()

    return (
      <div className='delivery-options-filters'>
        <ProductionSitesFilter
          onChange={this.updateActiveFilters}
          productionSites={availableFilters.productionSites}
        />

        <ShippersFilter onChange={this.updateActiveFilters} shippers={availableFilters.shippers} />

        <RegionsFilter onChange={this.updateActiveFilters} regions={availableFilters.regions} />

        <SuburbsFilter
          onChange={this.updateActiveFilters}
          suburbs={availableFilters.suburbs}
          country={this.props.country}
        />

        <DeliveryDaysFilter
          onChange={this.updateActiveFilters}
          deliveryDays={availableFilters.deliveryDays}
        />
      </div>
    )
  }
}

DeliveryOptionsFilters.propTypes = {
  dispatch: PropTypes.func.isRequired,
  country: PropTypes.string,
  data: PropTypes.object,
  activeFilters: PropTypes.object,
}

function mapStateToProps(state) {
  return {
    data: state.deliveryOptions.data,
    country: state.globalFilters.country,
    brand: state.globalFilters.brand,
    activeFilters: state.deliveryOptions.filters,
  }
}

const connectedDeliveryOptionsFilters = connect(mapStateToProps)(DeliveryOptionsFilters)

export default connectedDeliveryOptionsFilters
