import { call, put, takeLatest } from 'redux-saga/effects'
import authenticationApi from 'apis/authentication.api'
import { moveOrdersApi } from 'apis/move_orders.api'
import {
  ORDER_MOVEMENT_UPDATE_SUCCEEDED,
  ORDER_MOVEMENT_UPDATE_FAILED,
  ORDER_MOVEMENT_UPDATE_REQUESTED,
  FAILED_ORDER_MOVEMENT_REQUESTED,
  FAILED_ORDER_MOVEMENT_FAILED,
  FAILED_ORDER_MOVEMENT_SUCCEEDED,
} from 'actions/types'
import { graphqlApi } from 'apis/graphql.api'
import { openSuccessAlert } from 'actions/alert/alert'

export function* moveOrders(action) {
  try {
    const response = yield call(moveOrdersApi.moveOrders, action.country, action.csvContent)

    yield call(handleSuccess, response)
  } catch (error) {
    const failureAction = { type: ORDER_MOVEMENT_UPDATE_FAILED }

    authenticationApi.handleError(error, action, failureAction)
    yield put(failureAction)
  }
}

export function* failedOrderMovement(action) {
  try {
    const response = yield call(graphqlApi.postFailedOrderMovements, action)
    const parsedResponse = response.body.data.failedOrderMovements

    yield put({
      type: FAILED_ORDER_MOVEMENT_SUCCEEDED,
      data: { responseBody: parsedResponse },
    })
  } catch (error) {
    const failureAction = { type: FAILED_ORDER_MOVEMENT_FAILED }

    authenticationApi.handleError(error, action, failureAction)

    yield put(failureAction)
  }
}

function* handleSuccess(response) {
  yield put(openSuccessAlert(response.body.message))

  yield put({ type: ORDER_MOVEMENT_UPDATE_SUCCEEDED })
}

export const moveOrdersSagas = [
  takeLatest(ORDER_MOVEMENT_UPDATE_REQUESTED, moveOrders),
  takeLatest(FAILED_ORDER_MOVEMENT_REQUESTED, failedOrderMovement),
]
