import React from 'react'
import PropTypes from 'prop-types'
import { uniq, omit } from 'lodash'

import Filter from 'components/shared/filter/filter'

const DeliveryDateExceptionsFilters = ({ country, data = [], activeFilters, onFiltersUpdate }) => {
  if (!data || data.length < 1) return null

  const availableFilters = {
    productionSite: uniq(data.map(({ productionSite }) => productionSite)),
    region: uniq(data.map(({ region }) => region)),
    suburb: uniq(data.map(({ suburb }) => suburb)),
    shipper: uniq(data.map(({ regularSlot }) => regularSlot.shipperName)),
    deliveryWindow: uniq(data.map(({ regularSlot }) => `${regularSlot.from}-${regularSlot.to}`)),
  }

  const handleProductionSiteFilterChange = e => {
    if (e.target.value === 'all') return onFiltersUpdate(omit(activeFilters, 'productionSite'))

    onFiltersUpdate({ ...activeFilters, productionSite: e.target.value })
  }

  const handleRegionFilterChange = e => {
    if (e.target.value === 'all') return onFiltersUpdate(omit(activeFilters, 'region'))

    const newFilterValue = e.target.value === 'null' ? null : e.target.value

    onFiltersUpdate({ ...activeFilters, region: newFilterValue })
  }

  const handleSuburbFilterChange = e => {
    if (e.target.value === 'all') return onFiltersUpdate(omit(activeFilters, 'suburb'))

    const newFilterValue = e.target.value === 'null' ? null : e.target.value

    onFiltersUpdate({ ...activeFilters, suburb: newFilterValue })
  }

  const handleShipperFilterChange = e =>
    onFiltersUpdate({
      ...activeFilters,
      regularSlot:
        e.target.value === 'all'
          ? omit(activeFilters.regularSlot, 'shipperName')
          : {
              ...activeFilters.regularSlot,
              shipperName: e.target.value,
            },
    })

  const handleDeliveryWindowFilterChange = e => {
    const [from, to] = e.target.value.split('-')

    onFiltersUpdate({
      ...activeFilters,
      regularSlot:
        e.target.value === 'all'
          ? omit(activeFilters.regularSlot, ['to', 'from'])
          : {
              ...activeFilters.regularSlot,
              to,
              from,
            },
    })
  }

  return (
    <div className='delivery-date-exceptions-filters'>
      <Filter
        name='productionSite'
        displayName='FC'
        defaultText='All FCs'
        selectedValue={activeFilters.productionSite}
        onChange={handleProductionSiteFilterChange}
        data={availableFilters.productionSite}
      />
      <Filter
        name='region'
        displayName='Region'
        defaultText='All Regions'
        selectedValue={activeFilters.region}
        onChange={handleRegionFilterChange}
        data={availableFilters.region}
      />
      <Filter
        name='suburb'
        displayName='Suburb'
        defaultText='All Suburbs'
        selectedValue={activeFilters.suburb}
        onChange={handleSuburbFilterChange}
        data={availableFilters.suburb}
        hidden={country.toLowerCase() != 'au'}
      />
      <Filter
        name='shipper'
        displayName='Shipper'
        defaultText='All Shippers'
        selectedValue={activeFilters.regularSlot && activeFilters.regularSlot.shipperName}
        onChange={handleShipperFilterChange}
        data={availableFilters.shipper}
      />
      <Filter
        name='deliveryWindow'
        displayName='Delivery Window'
        defaultText='All Delivery Windows'
        selectedValue={
          activeFilters.regularSlot &&
          `${activeFilters.regularSlot.from}-${activeFilters.regularSlot.to}`
        }
        onChange={handleDeliveryWindowFilterChange}
        data={availableFilters.deliveryWindow}
      />
    </div>
  )
}

DeliveryDateExceptionsFilters.propTypes = {
  onFiltersUpdate: PropTypes.func.isRequired,
  activeFilters: PropTypes.object,
  data: PropTypes.array,
  country: PropTypes.string,
}

export default DeliveryDateExceptionsFilters
