import { call, put } from 'redux-saga/effects'

import { graphqlApi } from 'apis/graphql.api'
import authenticationApi from 'apis/authentication.api'
import {
  DELIVERY_DATE_EXCEPTIONS_FETCH_SUCCEEDED,
  DELIVERY_DATE_EXCEPTIONS_FETCH_FAILED,
} from 'actions/types'

export function* fetchDeliveryDateExceptions(action) {
  try {
    const response = yield call(graphqlApi.postDeliveryDateExceptions, action.data)

    graphqlApi.handleGraphqlErrors(response)
    yield put({
      type: DELIVERY_DATE_EXCEPTIONS_FETCH_SUCCEEDED,
      data: response.body.data.deliveryDateOptions,
    })
  } catch (error) {
    const failureAction = {
      type: DELIVERY_DATE_EXCEPTIONS_FETCH_FAILED,
    }

    authenticationApi.handleError(error, action, failureAction)
    yield put(failureAction)
  }
}
