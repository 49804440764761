import React from 'react'

import './HealthCheck.css'

const HealthCheck = () => {
  const timestamp = new Date().toString()

  return (
    <div className='health-check-status'>
      <span className='timestamp'>{timestamp}</span>
      <span className='ziplog-status'>Ziplog is online</span>
      <span className='env'>Environment: {process.env.REACT_APP_ENV}</span>
    </div>
  )
}

export default HealthCheck
