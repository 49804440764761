import React from 'react'
import Chip from '@mui/material/Chip'
import NewReleasesIcon from '@mui/icons-material/NewReleases'

import './appLogo.css'

const AppLogo = () => {
  const env = process.env.REACT_APP_ENV

  const envIconClassName = env === 'production' ? '--warn-color' : ''

  return (
    <div className='app-logo'>
      <div className='app-logo__img_container'>
        <img className='app-logo__img' src='images/logo.svg' alt='ZipLog' />
      </div>
      <div className='app-logo__env_container'>
        <Chip
          className='app-logo__env'
          label={env}
          icon={<NewReleasesIcon className={`env-icon${envIconClassName}`} />}
        />
      </div>
    </div>
  )
}

export default AppLogo
