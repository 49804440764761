import {
  DELIVERY_OPTIONS_PAUSE_CSV_UPLOADED,
  DELIVERY_OPTIONS_PAUSE_REQUESTED,
  DELIVERY_OPTIONS_PAUSE_SUCCEEDED,
  DELIVERY_OPTIONS_PAUSE_FAILED,
  DELIVERY_OPTIONS_PAUSE_ERRORS_CLEAR,
} from 'actions/types'

const initialState = {
  isLoading: false,
  csvContent: null,
  errors: null,
}

const deliveryOptionsPause = (state = initialState, action) => {
  switch (action.type) {
    case DELIVERY_OPTIONS_PAUSE_CSV_UPLOADED:
      return {
        ...state,
        csvContent: action.csvContent,
      }
    case DELIVERY_OPTIONS_PAUSE_REQUESTED:
      return {
        ...state,
        isLoading: true,
      }
    case DELIVERY_OPTIONS_PAUSE_SUCCEEDED:
    case DELIVERY_OPTIONS_PAUSE_FAILED:
      return {
        isLoading: false,
        csvContent: null,
        errors: action.errors,
      }
    case DELIVERY_OPTIONS_PAUSE_ERRORS_CLEAR:
      return initialState
    default:
      return state
  }
}

export default deliveryOptionsPause
