import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { find } from 'lodash'

import { showModal } from 'actions/modal'
import { EXCEPTIONS_SAVE_MODAL } from 'components/modal/modalTypes'
import './exceptionsSaveButton.css'

export class ExceptionsSaveButton extends React.Component {
  disableButton() {
    const noSelectedExceptions = Object.keys(this.props.newExceptions).length === 0
    const hasErrors = find(this.props.newExceptions, exception => exception.errors)

    return noSelectedExceptions || hasErrors
  }

  displayButton() {
    const { currentUserCanCreateExceptions } = this.props

    return currentUserCanCreateExceptions
  }

  openExceptionsModal = () => {
    this.props.dispatch(showModal(EXCEPTIONS_SAVE_MODAL))
  }

  render() {
    return this.displayButton() ? (
      <div className='exceptions-save-button'>
        <button
          className='exceptions-save-button__button button-primary'
          disabled={this.disableButton()}
          onClick={this.openExceptionsModal}
        >
          SAVE
        </button>
      </div>
    ) : null
  }
}

function mapStateToProps(state) {
  return {
    country: state.globalFilters.country,
    brand: state.globalFilters.brand,
    newExceptions: state.deliveryDateExceptions.newExceptions,
  }
}

ExceptionsSaveButton.propTypes = {
  dispatch: PropTypes.func.isRequired,
  regularDate: PropTypes.string,
  newExceptions: PropTypes.array,
  currentUserCanCreateExceptions: PropTypes.bool,
}

const connectedExceptionsSaveButton = connect(mapStateToProps)(ExceptionsSaveButton)

export default connectedExceptionsSaveButton
