import { OPEN_ALERT } from './types'

export const openAlert = (variant, message, props) => ({
  type: OPEN_ALERT,
  variant,
  message,
  alertProps: props || {},
})

export const openSuccessAlert = (message, props) => openAlert('success', message, props)
export const openErrorAlert = (message, props) => openAlert('error', message, props)
