import { exceptionsApi } from 'apis/exceptions.api'
import { call, put, takeLatest } from 'redux-saga/effects'
import authenticationApi from 'apis/authentication.api'
import {
  DELIVERY_DATE_EXCEPTIONS_DELETE_REQUESTED,
  DELIVERY_DATE_EXCEPTIONS_DELETE_FAILED,
} from 'actions/types'
import { fetchData } from 'actions/index'
import { DELIVERY_DATE_EXCEPTIONS } from 'actions/context'

export function* deleteExceptions(action) {
  try {
    yield call(
      exceptionsApi.deleteExceptions,
      action.country,
      action.brand,
      action.orderType,
      action.regularDeliveryDate,
      action.data,
    )

    const deliveryDateExceptionsData = {
      country: action.country,
      brand: action.brand,
      orderType: action.orderType,
      regularDeliveryDate: action.regularDeliveryDate,
    }

    yield put(fetchData(DELIVERY_DATE_EXCEPTIONS, deliveryDateExceptionsData))
  } catch (error) {
    const failureAction = {
      type: DELIVERY_DATE_EXCEPTIONS_DELETE_FAILED,
    }

    authenticationApi.handleError(error, action, failureAction)
    yield put(failureAction)
  }
}

export const deleteExceptionsSagas = [
  takeLatest(DELIVERY_DATE_EXCEPTIONS_DELETE_REQUESTED, deleteExceptions),
]
