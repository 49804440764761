import { DYNAMIC_OPTIONS_UPDATE_CSV_UPLOADED, DYNAMIC_OPTIONS_UPDATE_REQUESTED } from './types'

export const updateDynamicOptionsCsvUpload = data => {
  return {
    type: DYNAMIC_OPTIONS_UPDATE_CSV_UPLOADED,
    csvContent: data,
  }
}

export const updateDynamicOptions = (
  country,
  brand,
  csvContent,
  firstDeliveryWeek,
  createNewIfNotExist,
) => {
  return {
    type: DYNAMIC_OPTIONS_UPDATE_REQUESTED,
    country: country,
    brand: brand,
    csvContent: csvContent,
    firstDeliveryWeek: firstDeliveryWeek,
    createNewIfNotExist: createNewIfNotExist,
  }
}
