import { SHIPPING_CSVS_UPDATE_FILTERS, SHIPPING_CSVS_UPDATE_LOADING } from './types'

export const setFilters = (key, value) => {
  return {
    type: SHIPPING_CSVS_UPDATE_FILTERS,
    key: key,
    value: value,
  }
}

export const setLoading = loading => {
  return {
    type: SHIPPING_CSVS_UPDATE_LOADING,
    loading,
  }
}
