import React from 'react'
import PropTypes from 'prop-types'

const PauseError = ({ errors = [] }) => {
  const renderErrors = () => {
    if (errors === null || errors.length === 0) return null

    let errorsHtml = ['Errors:']

    errors.forEach((error, index) => {
      errorsHtml.push(<div key={index}>&emsp;{error}</div>)
    })

    return errorsHtml
  }

  return <div>{renderErrors()}</div>
}

PauseError.propTypes = {
  errors: PropTypes.array,
}

export default PauseError
