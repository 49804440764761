import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import CountryFilter from 'components/shared/countryFilter/countryFilter'
import CsvUploader from 'components/shared/csvUploader/csvUploader'
import BrandFilter from 'components/shared/brandFilter/brandFilter'
import InfoMessage from 'components/shared/infoMessage/infoMessage'
import ZiplogLink from 'components/shared/ziplogLink/ziplogLink'

import { showModal } from 'actions/modal'
import { ZIPCODES_NEW_PREVIEW_MODAL } from 'components/modal/modalTypes'

import { createZipcodesCsvUpload } from 'actions/zipcodes'

import './zipcodesNew.css'

const INFO_MESSAGE_TEXT =
  'In order for users to be able to sign up in new zipcodes, please ' +
  'remember to add delivery options.'
const TEMPLATE_LINK =
  'https://drive.google.com/open?id=1J5c1p1M1hdHie3yG8o6MPvW67lPPW-M1rEAmr34Di2c'

export class ZipcodesNew extends React.Component {
  openPreviewModal = () => {
    this.props.dispatch(showModal(ZIPCODES_NEW_PREVIEW_MODAL))
  }

  onFileUpload = csvData => {
    this.props.dispatch(createZipcodesCsvUpload(csvData))
  }

  isBtnDisabled() {
    const { country, brand, csvContent } = this.props
    return !country || !brand || !csvContent
  }

  render() {
    if (this.props.isLoading) {
      return <div className='zipcodes-new__loader'>Loading...</div>
    } else if (this.props.canManage) {
      return (
        <div className='zipcodes-new'>
          <CountryFilter countries={this.props.countriesAllowedToManage} />
          <BrandFilter />
          <InfoMessage message={INFO_MESSAGE_TEXT} />

          <CsvUploader onFileUpload={this.onFileUpload} />

          <ZiplogLink
            href={TEMPLATE_LINK}
            className='zipcodes-new__ziplog-link'
            text='CSV Template'
            openInNewTab
          />

          <button
            className='zipcodes-new__save-button button-primary'
            disabled={this.isBtnDisabled()}
            onClick={this.openPreviewModal}
          >
            Save
          </button>
        </div>
      )
    }

    return null
  }
}

ZipcodesNew.propTypes = {
  country: PropTypes.string,
  brand: PropTypes.string,
  csvContent: PropTypes.string,
  isLoading: PropTypes.bool,
  canManage: PropTypes.bool.isRequired,
  countriesAllowedToManage: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {
    country: state.globalFilters.country,
    brand: state.globalFilters.brand,
    csvContent: state.zipcodesNew.csvContent,
    isLoading: state.zipcodesNew.isLoading,
    canManage: state.currentUser.canManage,
    countriesAllowedToManage: state.currentUser.countriesAllowedToManage,
  }
}

const connectedZipcodesNew = connect(mapStateToProps)(ZipcodesNew)

export default connectedZipcodesNew
