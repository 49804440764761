import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import GraphqlPostButton from 'components/shared/graphqlPostButton/graphqlPostButton'
import { DELIVERY_TRACKING } from 'actions/context'

export function DeliveryTrackingPostButton(props) {
  const isDisabled = () => {
    const { orderNumber, shipmentNumber, trackingNumber } = props

    return !orderNumber && !shipmentNumber && !trackingNumber
  }

  const gaParams = () => {
    return JSON.stringify(generateGraphqlApiData())
  }

  const generateGraphqlApiData = () => {
    return {
      orderNumber: props.orderNumber ?? '',
      shipmentNumber: props.shipmentNumber ?? '',
      trackingNumber: props.trackingNumber ?? '',
    }
  }

  return (
    <GraphqlPostButton
      buttonText={'Search'}
      context={DELIVERY_TRACKING}
      data={generateGraphqlApiData()}
      disabled={isDisabled()}
      gaLabel={gaParams()}
    />
  )
}

DeliveryTrackingPostButton.propTypes = {
  orderNumber: PropTypes.string,
  shipmentNumber: PropTypes.string,
  trackingNumber: PropTypes.string,
}

const connectedShippingLabelsButton = connect()(DeliveryTrackingPostButton)
export default connectedShippingLabelsButton
