import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import Filter from '../filter/filter'
import { clearData, setGlobalFilter } from 'actions/index'

export const CountryFilter = props => {
  const handleChange = e => {
    props.dispatch(clearData(props.context))
    props.dispatch(setGlobalFilter('country', e.target.value))
  }

  return (
    <Filter
      name='country'
      displayName='country'
      displayUpcase
      onChange={handleChange}
      data={props.countries}
      selectedValue={props.country}
      defaultValue=''
      defaultText='Please select'
      labelText='Country'
    />
  )
}

CountryFilter.propTypes = {
  dispatch: PropTypes.func.isRequired,
  countries: PropTypes.array.isRequired,
  country: PropTypes.string,
  context: PropTypes.string,
}

function mapStateToProps(state) {
  return {
    country: state.globalFilters.country,
  }
}

const connectedCountryFilter = connect(mapStateToProps)(CountryFilter)

export default connectedCountryFilter
