import React from 'react'

import Authenticate from './authenticate/authenticate'

import './header.css'

class Header extends React.Component {
  render() {
    return (
      <div className='header'>
        <Authenticate />
      </div>
    )
  }
}

export default Header
