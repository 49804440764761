import React, { Fragment, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import BrandsFilter from 'components/shared/brandsFilter/brandsFilter'
import DeliveryPromisesPostButton from './deliveryPromisesPostButton/deliveryPromisesPostButton'
import DeliveryPromisesTable from './deliveryPromisesTable/deliveryPromisesTable'
import CountryFilter from 'components/shared/countryFilter/countryFilter'
import InfoMessage from 'components/shared/infoMessage/infoMessage'
import RegionsFilter from 'components/shared/regionsFilter/regionsFilter'
import ZipcodeInput from 'components/shared/zipcodeInput/zipcodeInput'
import WdaysFilter from 'components/shared/wdaysFilter/wdaysFilter'
import DeliveryPromisesDownloadButton from './deliveryPromisesDownload/deliveryPromisesDownloadButton'
import { fetchData, setFilters } from 'actions/index'
import { DELIVERY_PROMISES, MAPPINGS } from 'actions/context'
import { formatAllValue } from 'helpers/helpers'

const PER_PAGE = 100

const INFO_MESSAGE_TEXT =
  'This page is a work in progress and should not yet be used for daily work. ' +
  'However you are very welcome to explore it and ask us any questions you may have. ' +
  'Feedback would be awesome.'

export const DeliveryPromises = props => {
  useEffect(() => {
    props.dispatch(fetchData(MAPPINGS, { only: ['countryRegionsMapping'] }))
  }, [])

  const handleZipcodeChange = zipcode => {
    props.dispatch(setFilters(DELIVERY_PROMISES, 'zipcode', zipcode))
  }

  const handleWdaysChange = wdays => {
    props.dispatch(setFilters(DELIVERY_PROMISES, 'wdays', wdays))
  }

  const graphqlData = {
    brand: formatAllValue(props.brands),
    country: props.country,
    regions: props.regions ?? [],
    wdays: props.wdays ?? [],
    zipcode: props.zipcode ?? '',
  }
  const graphqlPostData = {
    ...graphqlData,
    first: PER_PAGE,
  }
  const paginationData = {
    data: graphqlData,
    pageInfo: props.pageInfo,
    perPage: PER_PAGE,
  }

  return (
    <Fragment>
      <InfoMessage message={INFO_MESSAGE_TEXT} />
      <div className='filters-block'>
        <CountryFilter context={DELIVERY_PROMISES} countries={props.countriesAllowedToView} />
        <BrandsFilter context={DELIVERY_PROMISES} />
        <ZipcodeInput changeCallback={handleZipcodeChange} zipcode={props.zipcode} />
        <RegionsFilter
          context={DELIVERY_PROMISES}
          countryRegionsMapping={props.countryRegionsMapping}
          country={props.country}
          regions={props.regions}
        />
        <WdaysFilter
          context={DELIVERY_PROMISES}
          changeCallback={handleWdaysChange}
          wdays={props.wdays}
        />
        <DeliveryPromisesPostButton data={graphqlPostData} />
      </div>

      <div>
        <DeliveryPromisesDownloadButton type={'region'} buttonText={'Download by Region'} />
        <DeliveryPromisesDownloadButton type={'zipcode'} buttonText={'Download by Zipcode'} />
      </div>

      <DeliveryPromisesTable
        deliveryPromises={props.deliveryPromises}
        paginationData={paginationData}
        status={props.status}
      />
    </Fragment>
  )
}

DeliveryPromises.propTypes = {
  dispatch: PropTypes.func.isRequired,
  brands: PropTypes.string,
  countriesAllowedToView: PropTypes.array,
  countryRegionsMapping: PropTypes.object,
  country: PropTypes.string,
  deliveryPromises: PropTypes.array,
  pageInfo: PropTypes.shape({
    endCursor: PropTypes.string,
    hasNextPage: PropTypes.bool,
    hasPreviousPage: PropTypes.bool,
    startCursor: PropTypes.string,
  }).isRequired,
  status: PropTypes.number.isRequired,
  regions: PropTypes.array,
  wdays: PropTypes.array,
  zipcode: PropTypes.string,
}

const mapStateToProps = state => {
  return {
    brands: state.globalFilters.brands,
    countriesAllowedToView: state.currentUser.countriesAllowedToView,
    countryRegionsMapping: state.mappings.countryRegionsMapping,
    country: state.globalFilters.country,
    deliveryPromises: state.deliveryPromises.data,
    pageInfo: state.deliveryPromises.pageInfo,
    status: state.deliveryPromises.status,
    regions: state.globalFilters.regions,
    wdays: state.deliveryPromises.wdays,
    zipcode: state.deliveryPromises.zipcode,
  }
}

const connectedDeliveryPromises = connect(mapStateToProps)(DeliveryPromises)

export default connectedDeliveryPromises
