import React from 'react'
import PropTypes from 'prop-types'
import { uniqWith, isEqual } from 'lodash'

import './slotSelect.css'

class SlotSelect extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: '',
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.enabled !== this.props.enabled && !this.props.enabled) {
      this.setState({ selected: '' })
    }
  }

  getFilteredOptions() {
    if (!this.props.deliveryOptions || !this.props.regularData) {
      return []
    }

    const filteredData = this.props.deliveryOptions
      .filter(elem => elem.region === this.props.regularData.region)
      .map(option => {
        let data = {
          shipperName: option.shipper,
          from: option.from,
          to: option.to,
        }
        if (option.priceTierIdentifier !== undefined && option.priceTierIdentifier !== null) {
          data.priceTierIdentifier = option.priceTierIdentifier
        }
        return data
      })

    const uniqData = uniqWith(filteredData, isEqual)

    return uniqData
  }

  renderOptions() {
    const options = this.getFilteredOptions()
    let optionsHtml = [
      <option key='no-slot-selected' value=''>
        Select Slot
      </option>,
    ]

    options.forEach(option => {
      let text = `${option.shipperName} ${option.from}-${option.to}`
      if (option.priceTierIdentifier !== undefined && option.priceTierIdentifier !== null) {
        text = `${text} (${option.priceTierIdentifier})`
      }
      optionsHtml.push(
        <option key={text} value={JSON.stringify(option)}>
          {text}
        </option>,
      )
    })

    return optionsHtml
  }

  updateSlot = e => {
    const newSlot = e.target.value

    this.setState({
      selected: newSlot,
    })

    this.props.onSelect(e.target.name, JSON.parse(newSlot))
  }

  render() {
    return (
      <select
        className='slot-select'
        name='newSlot'
        value={this.state.selected}
        disabled={!this.props.enabled}
        onChange={this.updateSlot}
      >
        {this.renderOptions()}
      </select>
    )
  }
}

SlotSelect.defaultProps = {
  enabled: false,
}

SlotSelect.propTypes = {
  onSelect: PropTypes.func.isRequired,
  deliveryOptions: PropTypes.array,
  regularData: PropTypes.object,
  enabled: PropTypes.bool,
}

export default SlotSelect
