import React, { Fragment, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'

import { shipmentOptionsApi } from 'apis/shipmentOptions.api.js'

import BrandFilter from 'components/shared/brandFilter/brandFilter'
import CountryFilter from 'components/shared/countryFilter/countryFilter'
import CsvSummary from 'components/shared/csvSummary/csvSummary'
import CsvUploader from 'components/shared/csvUploader/csvUploader'
import InfoMessage from 'components/shared/infoMessage/infoMessage'
import ZiplogLink from 'components/shared/ziplogLink/ziplogLink'

import { showModal } from 'actions/modal'
import { CONFIRM_DIALOG_MODAL } from 'components/modal/modalTypes'

import { without } from 'lodash'

import { STATUSES } from 'helpers/constants'
import { SHIPMENT_OPTIONS } from 'actions/context'

const INFO_MESSAGE_TEXT =
  'This page is a work in progress and should not yet be used for daily work. ' +
  'However you are very welcome to explore it and ask us any questions you may have. ' +
  'Feedback would be awesome.'

const ADD_ZIPCODE_TEMPLATE_LINK =
  'https://docs.google.com/spreadsheets/d/14Jke0WqZg5K1khJYVJUmytfaIrx6xAmeSMKsRxi1feI/edit#gid=1895633289'
const ADD_REGION_TEMPLATE_LINK =
  'https://docs.google.com/spreadsheets/d/14Jke0WqZg5K1khJYVJUmytfaIrx6xAmeSMKsRxi1feI/edit#gid=1719618953'
const REMOVE_ZIPCODE_TEMPLATE_LINK =
  'https://docs.google.com/spreadsheets/d/14Jke0WqZg5K1khJYVJUmytfaIrx6xAmeSMKsRxi1feI/edit#gid=1233725585'
const REMOVE_REGION_TEMPLATE_LINK =
  'https://docs.google.com/spreadsheets/d/14Jke0WqZg5K1khJYVJUmytfaIrx6xAmeSMKsRxi1feI/edit#gid=1847460795'

const ERROR_HEADERS = [
  'zipcode',
  'region',
  'from',
  'to',
  'wday',
  'startdate',
  'enddate',
  'priority',
  'shipper',
  'production_site',
  'transit_time',
]

const SHORT_FORMATTED_KEYS = {
  wday: 'Weekday',
  from: 'From',
  to: 'To',
  startdate: 'Start Date',
  enddate: 'End Date',
  delivery_zipcodes: 'Zipcode',
}

const FORMATTED_KEYS = {
  wday: 'Shipment Options Weekday',
  from: 'Shipment Options From',
  to: 'Shipment Options To',
  startdate: 'Shipment Options Start Date',
  enddate: 'Shipment Options End Date',
}

const PREVIEW_DETAILS = {
  note: {
    key: 'existing_shipment_options',
    message:
      'The following Shipment Options already exist. Note that they will be ignored during the creation process:',
    headers: [
      'area',
      'zipcode',
      'wday',
      'from',
      'to',
      'shipper',
      'production_site',
      'transit_time',
      'priority',
      'startdate',
      'enddate',
    ],
  },
  summary: {
    key: 'aggregated_shipment_options',
    message: 'Preview a summary of the uploaded data:',
    headers: [
      'area',
      'delivery_zipcodes',
      'from',
      'to',
      'wday',
      'startdate',
      'enddate',
      'priority',
      'shipper',
      'production_site',
      'transit_time',
    ],
  },
}

const NOT_AVAILABLE = 'N/A'

export const ShipmentOptionsActions = props => {
  const [selectedFile, setSelectedFile] = useState(null)
  const [resetUploaderKey, setResetUploaderKey] = useState(0)
  const [status, setStatus] = useState(STATUSES.initial)
  const [summary, setSummary] = useState({
    data: [],
    errors: [],
    message: '',
  })

  useEffect(() => {
    abort()
  }, [props.country, props.brand])

  const location = useLocation()
  const actionName = location.pathname === '/shipment_options/remove' ? 'remove' : 'add'
  const isRemoveAction = actionName === 'remove'

  const previewDetails = () => {
    let excludeArea = false
    const data = summary.data && summary.data['aggregated_shipment_options']
    if (data && data[0]) {
      if (!Array.isArray(data[0].area)) {
        excludeArea = true
      }
      const excludedHeaders = []
      if (excludeArea) {
        excludedHeaders.push('area')
      }
      if (isRemoveAction) {
        excludedHeaders.push('startdate')
        excludedHeaders.push('enddate')
      }

      return {
        note: PREVIEW_DETAILS.note,
        summary: {
          ...PREVIEW_DETAILS.summary,
          headers: without(PREVIEW_DETAILS.summary.headers, excludedHeaders),
        },
      }
    }
    return PREVIEW_DETAILS
  }

  const zipcodeTemplateLink = () => {
    if (isRemoveAction) {
      return REMOVE_ZIPCODE_TEMPLATE_LINK
    } else {
      return ADD_ZIPCODE_TEMPLATE_LINK
    }
  }

  const regionTemplateLink = () => {
    if (isRemoveAction) {
      return REMOVE_REGION_TEMPLATE_LINK
    } else {
      return ADD_REGION_TEMPLATE_LINK
    }
  }

  const csvUploaderEnabled = () => {
    return !!(props.country && props.brand && status !== STATUSES.resolved)
  }

  const onFileUpload = file => {
    setSelectedFile(file)
    setStatus(STATUSES.requested)
    /* eslint-disable */
    shipmentOptionsApi
      .validate(props.country, props.brand, file, false, isRemoveAction)
      .end((err, res) => {
        const body = (res && res.body) || {}
        setSummary({
          data: body.summary || {},
          errors: body.errors || [],
          message: body.message || err
        })

        if (res && res.ok) {
          setStatus(STATUSES.resolved)
        } else {
          setStatus(STATUSES.failed)
        }
      })
    /* eslint-enable */
  }

  const submit = () => {
    setStatus(STATUSES.requested)
    /* eslint-disable */
    shipmentOptionsApi
      .bulkAction(props.country, props.brand, selectedFile, actionName)
      .end((err, res) => {
        const body = (res && res.body) || {}
        setSummary({
          data: {},
          errors: [{
            index: NOT_AVAILABLE,
            errors: body.errors || []
          }],
          message: body.message || err
        })

        if (res && res.ok) {
          setStatus(STATUSES.resolved)
          setResetUploaderKey(resetUploaderKey + 1)
        } else {
          setStatus(STATUSES.failed)
        }
      })
    /* eslint-enable */
  }

  const abort = () => {
    setStatus(STATUSES.initial)
    setResetUploaderKey(resetUploaderKey + 1)
  }

  const onAbort = () => {
    props.dispatch(
      showModal(CONFIRM_DIALOG_MODAL, {
        message: 'Are you sure you want to abort this process?',
        onConfirm: abort,
      }),
    )
  }

  const onSubmit = () => {
    props.dispatch(
      showModal(CONFIRM_DIALOG_MODAL, {
        message: `Are you sure you want to ${actionName} these Shipment Options?`,
        onConfirm: submit,
        targetId: 'confirm-submit',
      }),
    )
  }

  return (
    <Fragment>
      <InfoMessage message={INFO_MESSAGE_TEXT} />
      <div className='filters-block'>
        <CountryFilter context={SHIPMENT_OPTIONS} countries={props.countriesAllowedToView} />
        <BrandFilter context={SHIPMENT_OPTIONS} />
      </div>
      <CsvSummary
        onAbort={onAbort}
        errorHeaders={ERROR_HEADERS}
        formattedKeys={FORMATTED_KEYS}
        status={status}
        shortFormattedKeys={SHORT_FORMATTED_KEYS}
        onSubmit={onSubmit}
        summary={summary}
        previewDetails={previewDetails()}
      />
      <div className='bordered-wrapper'>
        <CsvUploader
          buttonText='Upload Shipment Options'
          key={resetUploaderKey}
          enabled={csvUploaderEnabled()}
          onFileUpload={onFileUpload}
        />
        <div className='break' />
        <div className='bordered-wrapper__links'>
          <ZiplogLink href={regionTemplateLink()} text='CSV Template by Region' openInNewTab />
          <ZiplogLink href={zipcodeTemplateLink()} text='CSV Template by Zipcode' openInNewTab />
        </div>
      </div>
    </Fragment>
  )
}

ShipmentOptionsActions.propTypes = {
  dispatch: PropTypes.func.isRequired,
  brand: PropTypes.string,
  countriesAllowedToView: PropTypes.array,
  country: PropTypes.string,
}

const mapStateToProps = state => {
  return {
    brand: state.globalFilters.brand,
    countriesAllowedToView: state.currentUser.countriesAllowedToView,
    country: state.globalFilters.country,
  }
}

const connectedShipmentOptionsActions = connect(mapStateToProps)(ShipmentOptionsActions)

export default connectedShipmentOptionsActions
