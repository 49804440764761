import React from 'react'

const LogIcon = props => (
  <svg viewBox='0 0 24 24' width='1.5em' height='1.5em' {...props}>
    <path
      className='log-icon'
      fill='currentColor'
      d='M20.4 16c.4-.6.6-1.3.6-2 0-2.2-1.8-4-4-4H4c-2.2 0-4 1.8-4 4 0 1.2.5 2.3 1.4 3-.9.7-1.4 1.8-1.4 3 0 2.2 1.8 4 4 4h16c2.2 0 4-1.8 4-4 0-2.1-1.6-3.7-3.6-4zM14 22c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3-10c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zM2 14c0-1.1.9-2 2-2h9.6c-.3.6-.6 1.3-.6 2s.2 1.4.6 2H4c-1.1 0-2-.9-2-2zm8.6 8H4c-1.1 0-2-.9-2-2s.9-2 2-2h6.6c-.3.6-.6 1.3-.6 2s.2 1.4.6 2zm9.4 0c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z'
    />
    <path d='M12 13H3.5c-.3 0-.5.2-.5.5s.2.5.5.5H12v-1zM3 19.5c0 .3.2.5.5.5H9v-1H3.5c-.3 0-.5.2-.5.5z' />
  </svg>
)

export default LogIcon
