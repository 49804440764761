import superagent from 'superagent'
import { helpers } from 'helpers/helpers'

export const moveOrdersApi = {
  moveOrders,
  fetchFile,
  downloadFile,
}

function downloadFile(response, filename) {
  helpers.downloadFile(response.text, filename)
}

function moveOrders(country, csvContent) {
  const body = {
    country,
    csv_content: csvContent,
  }

  return put(body)
}

function fetchFile(country, filename) {
  const url = `${process.env.REACT_APP_LOGISTICS_API_URL}/orders/failed_order`
  const logisticsToken = localStorage.getItem('logisticsToken')
  const params = {
    country: country,
    filename: filename,
  }

  return superagent
    .get(url)
    .query(params)
    .set('Authorization', `Bearer ${logisticsToken}`)
    .set('Content-Type', 'application/json')
    .set('X-Issuer', 'ziplog')
}

function put(body) {
  const url = `${process.env.REACT_APP_LOGISTICS_API_URL}/orders/update`
  const logisticsToken = localStorage.getItem('logisticsToken')

  return superagent
    .put(url)
    .send(body)
    .set('Authorization', `Bearer ${logisticsToken}`)
    .set('Content-Type', 'application/json')
    .set('X-Issuer', 'ziplog')
}
