import { OPEN_ALERT } from 'actions/alert/types'

const initialState = {
  variant: 'info', // success, info, warning, error
  message: '',
  alertProps: {},
}

export default function (state = initialState, { type, variant, message, alertProps }) {
  switch (type) {
    case OPEN_ALERT:
      return {
        ...state,
        key: new Date().getTime(),
        variant: variant || state.variant,
        message: message || state.message,
        alertProps: { ...state.alertProps, ...alertProps },
      }
    default:
      // to preserve alert visual transition during close we wont reset its properties
      return state
  }
}
