import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import CountryFilter from 'components/shared/countryFilter/countryFilter'
import CsvUploader from 'components/shared/csvUploader/csvUploader'
import BrandFilter from 'components/shared/brandFilter/brandFilter'
import InfoMessage from 'components/shared/infoMessage/infoMessage'
import { setFilters, updateZipcodesCsvUpload } from 'actions/zipcodes'
import ZiplogDatepicker from 'components/shared/ziplogDatepicker/ziplogDatepicker'
import ZiplogLink from 'components/shared/ziplogLink/ziplogLink'

import { showModal } from 'actions/modal'
import { ZIPCODES_UPDATE_PREVIEW_MODAL } from 'components/modal/modalTypes'

import './zipcodesUpdate.css'

const TEMPLATE_LINK =
  'https://docs.google.com/spreadsheets/d/1vUE3w0QReH7D3DLRwtWK1tX6wK_aGpug8IJD-50uEbw/edit#gid=0'

export const ZipcodesUpdate = props => {
  useEffect(() => {
    const date = initialFirstDeliveryDate()
    const wday = getWday(date)
    const formattedDate = date.toISOString().split('T')[0]

    props.dispatch(setFilters('firstDeliveryDateWday', wday))
    props.dispatch(setFilters('firstDeliveryDate', formattedDate))
  }, [])

  const initialFirstDeliveryDate = () => {
    let date = new Date()
    date = date.setDate(date.getDate() + 7)
    return new Date(date)
  }

  const openPreviewModal = () => {
    props.dispatch(showModal(ZIPCODES_UPDATE_PREVIEW_MODAL))
  }

  const onFileUpload = csvData => {
    props.dispatch(updateZipcodesCsvUpload(csvData))
  }

  const isBtnDisabled = () => {
    const { country, brand, firstDeliveryDate, csvContent } = props
    return !country || !brand || !csvContent || !firstDeliveryDate
  }

  const getWday = date => {
    const wdayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

    return wdayNames[date.getDay()]
  }

  const handleDeliveryDatePicker = date => {
    const wday = getWday(new Date(date))

    props.dispatch(setFilters('firstDeliveryDateWday', wday))
    props.dispatch(setFilters('firstDeliveryDate', date))
  }

  const infoMessageText = () => {
    return `Based on the given First Delivery Date, the orders will be moved from <b>${props.firstDeliveryDateWday}</b> (${props.firstDeliveryDate})`
  }

  if (props.isLoading) {
    return <div className='zipcodes-update__loader'>Loading...</div>
  } else if (props.canManage) {
    return (
      <div className='zipcodes-update'>
        <CountryFilter countries={props.countriesAllowedToManage} />
        <BrandFilter />
        <ZiplogDatepicker
          defaultDate={props.firstDeliveryDate}
          onDateSelect={handleDeliveryDatePicker}
          labelText='First Delivery Date'
        />

        {props.firstDeliveryDate && <InfoMessage message={infoMessageText()} />}

        <CsvUploader onFileUpload={onFileUpload} />

        <ZiplogLink
          href={TEMPLATE_LINK}
          className='zipcodes-update__ziplog-link'
          text='CSV Template'
          openInNewTab
        />

        <button
          className='zipcodes-update__save-button button-primary'
          disabled={isBtnDisabled()}
          onClick={openPreviewModal}
        >
          Save
        </button>
      </div>
    )
  }
}

ZipcodesUpdate.propTypes = {
  country: PropTypes.string,
  brand: PropTypes.string,
  firstDeliveryDate: PropTypes.string,
  firstDeliveryDateWday: PropTypes.string,
  csvContent: PropTypes.string,
  isLoading: PropTypes.bool,
  canManage: PropTypes.bool.isRequired,
  countriesAllowedToManage: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
  return {
    country: state.globalFilters.country,
    brand: state.globalFilters.brand,
    firstDeliveryDate: state.zipcodesUpdate.firstDeliveryDate,
    firstDeliveryDateWday: state.zipcodesUpdate.firstDeliveryDateWday,
    csvContent: state.zipcodesUpdate.csvContent,
    isLoading: state.zipcodesUpdate.isLoading,
    canManage: state.currentUser.canManage,
    countriesAllowedToManage: state.currentUser.countriesAllowedToManage,
  }
}

const connectedZipcodesUpdate = connect(mapStateToProps)(ZipcodesUpdate)

export default connectedZipcodesUpdate
