import React from 'react'
import PropTypes from 'prop-types'
import { useGoogleLogin } from '@react-oauth/google'

import './authenticate.css'

export const GoogleAuthButton = props => {
  const login = useGoogleLogin({
    onSuccess: response => props.onSuccess(response),
    onError: response => props.onFailure(response),
  })

  return (
    <button className='button-primary' title='Login' onClick={() => login()}>
      Login
    </button>
  )
}

GoogleAuthButton.propTypes = {
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
}

export default GoogleAuthButton
