import React from 'react'

import './helpGuideCaption.css'
import ZiplogLink from 'components/shared/ziplogLink/ziplogLink'

export default function HelpGuideCaption() {
  const link =
    'https://marleyspoon.atlassian.net/wiki/spaces/SE/pages/2420736126/Missing+Label+Guidelines'
  const text = 'How to fix label errors'

  return (
    <div className='help-guide-caption'>
      <strong>Help guide: </strong>
      <ZiplogLink href={link} text={text} openInNewTab className={'help-guide-caption__link'} />
    </div>
  )
}
