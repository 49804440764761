import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import BaseTable from 'components/shared/baseTable/baseTable'
import InfoMessage from 'components/shared/infoMessage/infoMessage'

import { displayField } from 'helpers/helpers'
import { startCase } from 'lodash'

const Preview = props => {
  const { details, summary } = props
  const noteKey = details.note && details.note.key
  const summaryKey = details.summary.key

  const renderNoteColumns = (row, index) => {
    const fieldsToDisplay = details.note.headers.map(key => {
      return (
        <td key={`note-fields-${key}-${index}`} className='csv-summary__table__row-column'>
          {displayField(row.fields[key])}
        </td>
      )
    })

    return (
      <Fragment>
        <td key={`note-index-${index}`} className='csv-summary__table__row-column'>
          {row.index}
        </td>
        {fieldsToDisplay}
      </Fragment>
    )
  }

  const renderNoteRows = () => {
    return summary.data[noteKey].map((row, index) => {
      return (
        <Fragment key={`note-${index}`}>
          <tr key={`note-row-${index}`}>{renderNoteColumns(row, index)}</tr>
        </Fragment>
      )
    })
  }

  const renderNote = () => {
    if (!details.note || !summary.data[noteKey] || summary.data[noteKey].length === 0) {
      return null
    }

    const headers = ['Row'].concat(details.note.headers)
    const formattedHeaders = headers.map(header => {
      return props.shortFormattedKeys[header] || startCase(header)
    })

    return (
      <div className='csv-summary__wrapper csv-summary__note'>
        <InfoMessage message={details.note.message} className='note-message' />
        <BaseTable headers={formattedHeaders} tableName='csv-summary__table'>
          {renderNoteRows()}
        </BaseTable>
      </div>
    )
  }

  const renderSummaryColumns = (row, index) => {
    const fieldsToDisplay = details.summary.headers.map(key => {
      return (
        <td key={`data-fields-${key}-${index}`} className='csv-summary__table__row-column'>
          {displayField(row[key])}
        </td>
      )
    })

    return <Fragment>{fieldsToDisplay}</Fragment>
  }

  const renderSummaryRows = () => {
    return summary.data[summaryKey].map((row, index) => {
      return (
        <Fragment key={`data-${index}`}>
          <tr key={`data-row-${index}`}>{renderSummaryColumns(row, index)}</tr>
        </Fragment>
      )
    })
  }

  const renderSummary = () => {
    if (!summary.data[summaryKey]) {
      return null
    }
    const formattedHeaders = details.summary.headers.map(header => {
      return props.shortFormattedKeys[header] || startCase(header)
    })

    return (
      <Fragment>
        <div className='csv-summary__wrapper csv-summary__data'>
          <InfoMessage message={details.summary.message} />
          <BaseTable headers={formattedHeaders} tableName='csv-summary__table'>
            {renderSummaryRows()}
          </BaseTable>
        </div>
        <div className='csv-summary__buttons'>
          <button className='button button-secondary' onClick={props.onAbort}>
            Abort
          </button>
          <button className='button button-primary' onClick={props.onSubmit}>
            Submit
          </button>
        </div>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <InfoMessage message={summary.message} />
      {renderNote()}
      {renderSummary()}
    </Fragment>
  )
}

Preview.defaultProps = {
  onAbort: () => {},
  onSubmit: () => {},
  shortFormattedKeys: {},
}

Preview.propTypes = {
  details: PropTypes.object.isRequired,
  onAbort: PropTypes.func,
  onSubmit: PropTypes.func,
  shortFormattedKeys: PropTypes.object,
  summary: PropTypes.object.isRequired,
}

export default Preview
