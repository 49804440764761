import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { saveAs } from 'file-saver'
import moment from 'moment'
import { shipmentsApi } from 'apis/shipments.api.js'
import ZiplogDatepicker from 'components/shared/ziplogDatepicker/ziplogDatepicker'
import Loading from 'components/shared/loading/loading'
import { SHIPPING_CSVS, SHIPPING_CSVS_FILTER_DATA } from 'actions/context'
import { fetchData } from 'actions/index'
import { setLoading, setFilters } from 'actions/shippingCsvs'
import CountryFilter from 'components/shared/countryFilter/countryFilter'
import ProductionSitesFilter from './productionSitesFilter/productionSitesFilter'
import { handleError } from 'apis/shared/handleApiError'

import './shippingCsvs.css'

const LM_CUSTOMER_CODE = { ms: '421700', dn: '271100' }

export const ShippingCsvs = props => {
  const {
    country,
    countryProductionSitesMapping = {},
    shippers,
    productionSite,
    productionSites,
    deliveryDate,
    loading,
  } = props

  useEffect(() => {
    props.dispatch(fetchData(SHIPPING_CSVS_FILTER_DATA))
    props.dispatch(setFilters('deliveryDate', moment().format('YYYY-MM-DD')))
  }, [])

  // this filters the available production sites once a country is selected
  useEffect(() => {
    const productionSites = country ? countryProductionSitesMapping[country] : []

    if (!productionSites) {
      return
    }

    props.dispatch(setFilters('productionSites', productionSites))
  }, [country])

  const handleDeliveryDatePicker = date => {
    props.dispatch(setFilters('deliveryDate', date))
  }

  const getFileType = name => {
    return name === 'lm' ? 'txt' : 'csv'
  }

  const handleCsvDownload = (name, country, brand) => {
    props.dispatch(setLoading(true))

    const site = productionSite === 'All' ? productionSites : productionSite

    shipmentsApi
      .getShipments(name, country, brand, deliveryDate, site)
      .then(response => {
        saveAsFile(response.body, name, brand)
        props.dispatch(setLoading(false))
      })
      .catch(error => {
        handleError(
          error,
          'There are no shipments for the given parameters. Which is fine! Just no deliveries for this day.',
        )
        props.dispatch(setLoading(false))
      })

    // N.B finally() throws an error in Codeship.
    // We may have to upgrade our Node version or use a polyfill.
    // https://stackoverflow.com/questions/54231083/jest-throws-finally-is-not-a-function
  }

  const saveAsFile = (data, name, brand) => {
    const file = new global.File([data], generateShipperFileName(name, brand), {
      type: 'text/plain',
    })

    saveAs(file)
  }

  // TO-DO: fetch this from Logistics API instead of generating here
  const generateShipperFileName = (name, brand) => {
    let fileName = null
    const fileType = getFileType(name)

    switch (name) {
      case 'lm':
        fileName = LMfilename(deliveryDate, fileType, brand)
        break
      case 'courier-express':
      case 'hungry':
      case 't-force':
      case 'uds':
        fileName = udsFilename(deliveryDate, name, brand, fileType)
        break
      default:
        fileName = `export_${name}_${deliveryDate}.${fileType}`
    }

    return fileName
  }

  const LMfilename = (deliveryDate, fileType, brand) => {
    const date = moment(deliveryDate).format('YYYYMMDD')
    let customerCode = LM_CUSTOMER_CODE[brand]
    return `${customerCode}${date}0000000.${fileType}`
  }

  const udsFilename = (deliveryDate, name, brand, fileType) => {
    const date = moment(deliveryDate).format('YYYY_MMM_DD')
    const brandTitle = brand === 'dn' ? 'DNRLY' : 'MS'

    return `${name.toUpperCase()}_${brandTitle}_${date}.${fileType}`
  }

  const getShippersByCountry = () => {
    return shippers && shippers.filter(shipper => shipper.country === country)
  }

  const shippersByCountry = country && getShippersByCountry()

  return (
    <div>
      {loading && <Loading />}

      <div>
        <ZiplogDatepicker
          defaultDate={deliveryDate}
          onDateSelect={handleDeliveryDatePicker}
          labelText='Delivery Date'
        />

        <CountryFilter context={SHIPPING_CSVS} countries={props.countriesAllowedToView} />

        <ProductionSitesFilter />
      </div>

      {country && shippersByCountry && shippersByCountry.length === 0 && (
        <div> No shipping CSVs for selected country </div>
      )}

      <div className='exporter'>
        <div className='exporter-button-wrapper'>
          {country ? (
            shippers &&
            shippersByCountry.map(shipper => {
              const { name, country, brand } = shipper
              const fileType = getFileType(name)

              return (
                <button
                  key={`${country}-${name}-${brand}`}
                  className={`button-${name} button-primary`}
                  onClick={() => handleCsvDownload(name, country, brand)}
                >
                  {`Download ${name} - ${brand} ${fileType} file`}
                </button>
              )
            })
          ) : (
            <div> Please select a country </div>
          )}
        </div>
      </div>
    </div>
  )
}

ShippingCsvs.propTypes = {
  dispatch: PropTypes.func.isRequired,
  productionSite: PropTypes.string,
  country: PropTypes.string,
  countryProductionSitesMapping: PropTypes.object,
  shippers: PropTypes.array,
  productionSites: PropTypes.array,
  deliveryDate: PropTypes.string,
  loading: PropTypes.bool,
  countriesAllowedToView: PropTypes.array,
}

const mapStateToProps = state => {
  return {
    productionSite: state.shippingCsvs.productionSite,
    country: state.globalFilters.country,
    countryProductionSitesMapping: state.shippingCsvs.data.countryProductionSitesMapping,
    shippers: state.shippingCsvs.data.shippers,
    productionSites: state.shippingCsvs.productionSites,
    deliveryDate: state.shippingCsvs.deliveryDate,
    loading: state.shippingCsvs.loading,
    countriesAllowedToView: state.currentUser.countriesAllowedToView,
  }
}

const connectedShippingCsvs = connect(mapStateToProps)(ShippingCsvs)

export default connectedShippingCsvs
