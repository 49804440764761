import React from 'react'

import InfoTip from 'components/shared/infoTip/infoTip'

class CutoffInfoTip extends React.Component {
  cutoffText() {
    return (
      '"Cutoff" as displayed in the table below is the day on which billing begins ' +
      'and no further changes can be made to orders (excluding VIP changes). ' +
      'Billing starts around 1am local time. Users have until 11:59pm local time of the day ' +
      'before to edit their order.'
    )
  }

  render() {
    return <InfoTip data={this.cutoffText()} />
  }
}

export default CutoffInfoTip
