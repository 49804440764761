import { call, put } from 'redux-saga/effects'
import { graphqlApi } from 'apis/graphql.api'
import authenticationApi from 'apis/authentication.api'

import { DELIVERY_OPTIONS_FETCH_SUCCEEDED, DELIVERY_OPTIONS_FETCH_FAILED } from 'actions/types'

export function* fetchDeliveryOptions(action) {
  try {
    const response = yield call(graphqlApi.postDeliveryOptions, action.data)

    graphqlApi.handleGraphqlErrors(response)

    yield put({
      type: DELIVERY_OPTIONS_FETCH_SUCCEEDED,
      data: response.body.data.deliveryOptions,
    })
  } catch (error) {
    const failureAction = {
      type: DELIVERY_OPTIONS_FETCH_FAILED,
    }

    authenticationApi.handleError(error, action, failureAction)
    yield put(failureAction)
  }
}
