import React from 'react'
import PropTypes from 'prop-types'

import './viewLabelLink.css'

export function ViewLabelLink(props) {
  const labelLink = () => {
    const baseLink = `/shipping_labels/${props.shipmentNumber}`

    if (props.featureFlags.length > 0) {
      return baseLink + `?featureFlags=${props.featureFlags.join(',')}`
    } else {
      return baseLink
    }
  }

  return (
    <a href={labelLink()} target='_blank' rel='noopener noreferrer' className='view-label-link'>
      <img
        src='images/magnifying-glass-icon.svg'
        alt='magnifying-glass shipping-labels-view-label-icon'
        className='view-label-link__icon'
      />
    </a>
  )
}

ViewLabelLink.propTypes = {
  shipmentNumber: PropTypes.string.isRequired,
  featureFlags: PropTypes.array,
}

export default ViewLabelLink
