import { SHOW_MODAL, HIDE_MODAL } from 'actions/types'

const initialState = {
  modalType: null,
  data: {},
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SHOW_MODAL:
      return {
        ...state,
        modalType: action.payload.modalType,
        data: action.payload.data,
      }
    case HIDE_MODAL:
      return initialState
    default:
      return state
  }
}
