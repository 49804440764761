import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { showModal } from 'actions/modal'
import { EXCEPTIONS_DELETE_MODAL } from 'components/modal/modalTypes'

import './deleteButton.css'

export class DeleteButton extends React.Component {
  hasExistingException() {
    return !!this.props.exceptionToDelete.exceptionalDeliveryDate
  }

  displayButton() {
    let { currentUserCanDestroyExceptions } = this.props

    return currentUserCanDestroyExceptions && this.hasExistingException()
  }

  openDeleteModal = () => {
    this.props.dispatch(
      showModal(EXCEPTIONS_DELETE_MODAL, {
        exceptionsToDelete: [this.props.exceptionToDelete],
      }),
    )
  }

  render() {
    return this.displayButton() ? (
      <img
        src='images/trash-icon.svg'
        alt='exceptions delete button icon'
        className='exceptions-delete-button'
        onClick={this.openDeleteModal}
      />
    ) : null
  }
}

DeleteButton.propTypes = {
  dispatch: PropTypes.func.isRequired,
  exceptionToDelete: PropTypes.object.isRequired,
  country: PropTypes.string.isRequired,
  brand: PropTypes.string.isRequired,
  regularDeliveryDate: PropTypes.string.isRequired,
  currentUserCanDestroyExceptions: PropTypes.bool.isRequired,
}

function mapStateToProps(state) {
  return {
    country: state.globalFilters.country,
    brand: state.globalFilters.brand,
    regularDeliveryDate: state.globalFilters.regularDeliveryDate,
  }
}

const connectedDeleteButton = connect(mapStateToProps)(DeleteButton)

export default connectedDeleteButton
