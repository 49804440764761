import {
  DELIVERY_OPTIONS_CSV_FETCH_FAILED,
  DELIVERY_OPTIONS_CSV_FETCH_REQUESTED,
  DELIVERY_OPTIONS_CSV_FETCH_SUCCEEDED,
} from 'actions/types'

const initialState = {
  isLoading: false,
}

const deliveryOptionsCSV = (state = initialState, action) => {
  switch (action.type) {
    case DELIVERY_OPTIONS_CSV_FETCH_REQUESTED:
      return {
        isLoading: true,
      }
    case DELIVERY_OPTIONS_CSV_FETCH_SUCCEEDED:
      return {
        isLoading: false,
      }
    case DELIVERY_OPTIONS_CSV_FETCH_FAILED:
      return {
        isLoading: false,
      }
    default:
      return state
  }
}

export default deliveryOptionsCSV
