import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { fetchData } from 'actions/index'
import { ROLES } from 'actions/context.js'
import { usersApi } from 'apis/users.api.js'
import InfoMessage from 'components/shared/infoMessage/infoMessage'
import RolesSelect from './rolesSelect/rolesSelect'
import './users.css'

export const Users = props => {
  const [email, setEmail] = useState('')
  const [selectedRoles, setSelectedRoles] = useState([])
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    props.dispatch(fetchData(ROLES, null))
  }, [])

  const handleEmailInput = event => {
    const value = event.target.value

    setEmail(value)
  }

  const handleSelectedRolesSelect = selected => {
    setSelectedRoles(selected)
  }

  const disableButton = () => {
    const isValidEmail = /\S+@\S+\.\S+/.test(email)
    return !isValidEmail
  }

  const handleSuccess = user => {
    const successMessage = `${user.email} was successfuly created`
    setErrorMessage('')
    setSuccessMessage(successMessage)
    setEmail('')
    setSelectedRoles([])
  }

  const handleError = response => {
    setErrorMessage(response?.error)
    setSuccessMessage('')
  }

  const createUser = () => {
    const roles = selectedRoles.map(role => role.value)

    usersApi
      .createUser(email, roles)
      .then(response => handleSuccess(response?.body))
      .catch(error => handleError(error?.response?.body))
  }

  return (
    <div className='users'>
      <div>
        <InfoMessage message={successMessage} />
        <InfoMessage message={errorMessage} className={'error-message'} />

        <label className='ziplog-users__label'>Email</label>
        <input
          type='text'
          name='email'
          placeholder='example@marleyspoon.com'
          className='ziplog-users__email-input'
          value={email}
          onChange={handleEmailInput}
        />
      </div>
      <RolesSelect
        selectedValue={selectedRoles}
        onChange={handleSelectedRolesSelect}
        roles={props.data}
      />
      <button className='button-primary' disabled={disableButton()} onClick={createUser}>
        Create
      </button>
    </div>
  )
}

Users.propTypes = {
  dispatch: PropTypes.func.isRequired,
  data: PropTypes.object,
}

const mapStateToProps = state => {
  return {
    data: state.roles.data,
  }
}

const connectedUsers = connect(mapStateToProps)(Users)

export default connectedUsers
