import React from 'react'
import PropTypes from 'prop-types'

import './zipcodeInput.css'

class ZipcodeInput extends React.PureComponent {
  onInputChanged = e => {
    const zipcode = e.target.value
    this.props.changeCallback(zipcode)
  }

  render() {
    return (
      <div className='zipcode-input'>
        {<label className='zipcode-input__label'>Zipcode</label>}
        <input
          type='text'
          name='zipcode'
          placeholder='(optional)'
          value={this.props.zipcode}
          onChange={this.onInputChanged}
        />
      </div>
    )
  }
}

ZipcodeInput.propTypes = {
  changeCallback: PropTypes.func.isRequired,
  zipcode: PropTypes.string,
}

export default ZipcodeInput
