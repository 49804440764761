import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import Filter from 'components/shared/filter/filter'
import { setFilters } from 'actions/index'
import { SHIPMENT_OPTIONS } from 'actions/context'
import { isEmpty } from 'lodash'

export const ProductionSitesFilter = props => {
  const { countryProductionSitesMapping = {}, country, availableFilters } = props

  const handleChange = e => {
    props.dispatch(setFilters(SHIPMENT_OPTIONS, 'productionSite', e.target.value))
  }

  const updateProductionSites = () => {
    if (country && !isEmpty(countryProductionSitesMapping)) {
      availableFilters['productionSites'] =
        country && countryProductionSitesMapping && countryProductionSitesMapping[country]
          ? countryProductionSitesMapping[country]
          : []
      props.dispatch(setFilters(SHIPMENT_OPTIONS, 'availableFilters', availableFilters))
    }
  }

  useEffect(() => {
    updateProductionSites()
  }, [country, countryProductionSitesMapping])

  updateProductionSites()

  const countryNotSelected = () => !props.country

  return (
    <Filter
      name='productionSite'
      displayName='productionSite'
      displayUpcase
      onChange={handleChange}
      data={props.availableFilters['productionSites']}
      selectedValue={props.productionSite}
      disabled={countryNotSelected()}
      defaultValue='all'
      defaultText='All FCs'
      labelText='Production Site'
    />
  )
}

ProductionSitesFilter.propTypes = {
  dispatch: PropTypes.func.isRequired,
  availableFilters: PropTypes.object.isRequired,
  country: PropTypes.string,
  productionSite: PropTypes.string,
  countryProductionSitesMapping: PropTypes.object,
}

const mapStateToProps = state => {
  return {
    availableFilters: state.shipmentOptions.availableFilters,
    country: state.globalFilters.country,
    productionSite: state.shipmentOptions.productionSite,
  }
}

const connectedProductionSitesFilter = connect(mapStateToProps)(ProductionSitesFilter)
export default connectedProductionSitesFilter
