import { HIDE_MODAL, SHOW_MODAL } from './types'

export const showModal = (modalType, data) => {
  return {
    type: SHOW_MODAL,
    payload: {
      modalType,
      data,
    },
  }
}

export const hideModal = () => {
  return {
    type: HIDE_MODAL,
  }
}
