import superagent from 'superagent'
import { snakeCase, transform } from 'lodash'

// Converts camelCase keys to snake_case
const toSnakeCase = obj =>
  transform(obj, (res, value, key) => {
    // Don't convert keys with numbers
    if (key == 'line1' || key == 'line2') {
      return (res[key] = value)
    } else {
      return (res[snakeCase(key)] = value)
    }
  })

const changeShipAddress = (number, shipAddress) => patch('address', number, shipAddress)

// Send PATCH requests to Logistics API
const patch = (action, shipmentNumber, body) => {
  const url = `${process.env.REACT_APP_LOGISTICS_API_URL}/shipments/${shipmentNumber}/${action}`
  const logisticsToken = localStorage.getItem('logisticsToken')

  return superagent
    .patch(url)
    .send({ address: toSnakeCase(body['address']) })
    .set('Authorization', `Bearer ${logisticsToken}`)
    .set('Content-Type', 'application/json')
    .set('X-Issuer', 'ziplog')
}

export const shipAddressApi = { changeShipAddress }
