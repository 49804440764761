import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import BaseTable from 'components/shared/baseTable/baseTable'
import InfoMessage from 'components/shared/infoMessage/infoMessage'

import { displayField } from 'helpers/helpers'
import { startCase } from 'lodash'

const NOT_AVAILABLE = 'N/A'
const BASE_KEY = 'base'

const ValidationErrors = props => {
  const { summary, headers } = props
  const generalErrors = summary.errors.filter(error => {
    return error.index === NOT_AVAILABLE
  })
  const rowErrors = summary.errors.filter(error => {
    return error.index !== NOT_AVAILABLE
  })
  const selectedHeaders = rowErrors[0]
    ? Object.keys(rowErrors[0].fields).filter(key => {
        return headers.includes(key)
      })
    : []
  const formattedHeaders = selectedHeaders.map(
    key => props.shortFormattedKeys[key] || startCase(key),
  )

  const headMessage = () => {
    const errors = generalErrors
      .map(error => {
        return Object.values(error.errors).flat().join(', ')
      })
      .join('</br>')

    if (errors.length === 0) {
      return summary.message.toString()
    }
    return `${summary.message}</br></br>${errors}`
  }

  const renderColumns = (row, index) => {
    const fieldsToDisplay = selectedHeaders.map(key => {
      return (
        <td key={`fields-${key}-${index}`} className={'csv-summary__table__row-column'}>
          {displayField(row.fields[key])}
        </td>
      )
    })

    const message = Object.entries(row.errors)
      .map(([key, value]) => {
        if (key === BASE_KEY) {
          return value
        }
        const fieldName = props.formattedKeys[key] || startCase(key)
        return `<b>${fieldName}</b> ${value}`
      })
      .join('</br>')
    const html = { __html: message }

    return (
      <Fragment>
        <td key={`index-${index}`} className='csv-summary__table__row-column'>
          {row.index}
        </td>
        {fieldsToDisplay}

        <td
          key={`message-${index}`}
          className='csv-summary__table__row-column'
          dangerouslySetInnerHTML={html}
        />
      </Fragment>
    )
  }

  const renderRows = () => {
    return rowErrors.map((row, index) => {
      return (
        <Fragment key={index}>
          <tr key={`row-${index}`}>{renderColumns(row, index)}</tr>
        </Fragment>
      )
    })
  }

  const renderTable = () => {
    if (!rowErrors[0]) {
      return null
    }

    const headers = ['Row'].concat(formattedHeaders).concat(['Message'])

    return (
      <BaseTable headers={headers} tableName='csv-summary__table'>
        {renderRows()}
      </BaseTable>
    )
  }

  return (
    <div className='csv-summary__wrapper csv-summary__errors'>
      <InfoMessage message={headMessage()} className='error-message' />
      {renderTable()}
    </div>
  )
}

ValidationErrors.defaultProps = {
  headers: [],
  formattedKeys: {},
  shortFormattedKeys: {},
}

ValidationErrors.propTypes = {
  formattedKeys: PropTypes.object,
  shortFormattedKeys: PropTypes.object,
  headers: PropTypes.array,
  summary: PropTypes.object.isRequired,
}

export default ValidationErrors
