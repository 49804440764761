import React from 'react'
import PropTypes from 'prop-types'
import './deliveryTrackingAccordion.css'

import Accordion from 'components/shared/accordion/accordion'
import DeliveryTrackingInformation from '../deliveryTrackingInformation/deliveryTrackingInformation'
import DeliveryTrackingTable from '../deliveryTrackingTable/deliveryTrackingTable'
import { STATUSES } from 'helpers/constants'

const DeliveryTrackingAccordion = ({ data, status }) => {
  const renderContent = shipment => {
    const latestActivity =
      shipment.shipmentTrackingActivities.length > 0 ? shipment.shipmentTrackingActivities[0] : {}
    return (
      <div className='delivery-tracking-accordion__content'>
        <DeliveryTrackingInformation information={shipment} latestActivity={latestActivity} />
        <DeliveryTrackingTable activities={shipment.shipmentTrackingActivities} />
      </div>
    )
  }

  const renderTitle = shipment => {
    return (
      <div className='delivery-tracking-accordion__title'>
        <b>Shipment nº:</b> {shipment.shipmentNumber} / <b>Tracking nº:</b>{' '}
        {shipment.trackingNumber} / <b>Order nº</b>: {shipment.orderNumber}
      </div>
    )
  }

  const renderAccordion = shipment => {
    return (
      <Accordion
        key={shipment.shipmentNumber}
        title={renderTitle(shipment)}
        content={renderContent(shipment)}
      />
    )
  }

  if (status === STATUSES.requested) {
    return <div className='delivery-tracking-accordion__loader'>Loading...</div>
  } else if (status === STATUSES.resolved && data.length === 0) {
    return <div className='delivery-tracking-accordion__no-results-msg'>No shipments found.</div>
  } else {
    return <div>{data.map(shipment => renderAccordion(shipment))}</div>
  }
}

DeliveryTrackingAccordion.propTypes = {
  status: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      orderNumber: PropTypes.string.isRequired,
      shipmentNumber: PropTypes.string.isRequired,
      trackingNumber: PropTypes.string.isRequired,
    }),
  ),
}

DeliveryTrackingAccordion.defaultProps = {
  status: STATUSES.initial,
  data: [],
}

export default DeliveryTrackingAccordion
