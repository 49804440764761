import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { MODAL_TYPES } from './modalTypes/index'

export const ModalContainer = props => {
  if (!props.modalType) {
    return null
  }
  const SpecificModal = MODAL_TYPES[props.modalType]

  return <SpecificModal />
}

ModalContainer.propTypes = {
  modalType: PropTypes.string,
}

function mapStateToProps(state) {
  return {
    modalType: state.modal.modalType,
  }
}

export default connect(mapStateToProps)(ModalContainer)
