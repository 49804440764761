import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import { connect } from 'react-redux'

const BaseAlert = ({ alert }) => {
  const { variant, alertProps, message, key } = alert

  const [snackPack, setSnackPack] = useState([])
  const [open, setOpen] = useState(false)
  const [messageInfo, setMessageInfo] = useState()

  useEffect(() => {
    if (snackPack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackPack[0] })
      setSnackPack(prev => prev.slice(1))
      setOpen(true)
    } else if (snackPack.length && messageInfo && open) {
      // Close an active snack when a new one is added
      setOpen(false)
    }
  }, [snackPack, messageInfo, open])

  useEffect(() => {
    if (key) {
      // When the key is changed - new alert was received
      setSnackPack(prev => [...prev, { message, key }])
    }
  }, [key])

  const handleClose = (e, reason) => {
    if (reason === 'clickaway') return

    setOpen(false)
  }

  const handleExited = () => setMessageInfo(undefined)

  return (
    <Snackbar
      key={messageInfo?.key}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={10000} // 10 seconds
      open={open}
      onClose={handleClose}
      transitionDuration={300}
      TransitionProps={{ onExited: handleExited }}
      {...alertProps}
    >
      <Alert severity={variant} onClose={handleClose}>
        {messageInfo?.message}
      </Alert>
    </Snackbar>
  )
}

BaseAlert.propTypes = {
  alert: PropTypes.object,
}

const mapStateToProps = ({ alert }) => ({ alert })

const connectedAlert = connect(mapStateToProps)(BaseAlert)

export default connectedAlert
