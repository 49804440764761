import { call, put } from 'redux-saga/effects'
import { graphqlApi } from 'apis/graphql.api'
import authenticationApi from 'apis/authentication.api'

import { DELIVERY_PROMISES_FETCH_SUCCEEDED, DELIVERY_PROMISES_FETCH_FAILED } from 'actions/types'

function hasNextPage(pageInfo, data) {
  return data.before != null || pageInfo.hasNextPage
}

function hasPreviousPage(pageInfo, data) {
  return data.after != null || pageInfo.hasPreviousPage
}

export function* fetchDeliveryPromises(action) {
  try {
    const response = yield call(graphqlApi.postDeliveryPromises, action.data)

    graphqlApi.handleGraphqlErrors(response)

    yield put({
      type: DELIVERY_PROMISES_FETCH_SUCCEEDED,
      deliveryPromises: response.body.data.deliveryPromises.edges.map(e => e['node']),
      pageInfo: {
        ...response.body.data.deliveryPromises.pageInfo,
        hasNextPage: hasNextPage(response.body.data.deliveryPromises.pageInfo, action.data),
        hasPreviousPage: hasPreviousPage(response.body.data.deliveryPromises.pageInfo, action.data),
      },
    })
  } catch (error) {
    const failureAction = {
      type: DELIVERY_PROMISES_FETCH_FAILED,
    }

    authenticationApi.handleError(error, action, failureAction)
    yield put(failureAction)
  }
}
