import React from 'react'
import PropTypes from 'prop-types'
import { truncate } from 'lodash'

import { weekdayString } from 'helpers/dateHelper'
import ZiplogTable from 'components/shared/ziplogTable/ziplogTable'

import './flashMessage.css'

export default function FlashMessage(props) {
  if (props.showMessage) {
    const deliveryOptionString = deliveryOption => {
      return (
        `${weekdayString(deliveryOption.wday)}, ` +
        `${deliveryOption.shipper_name} ` +
        `${deliveryOption.from}-${deliveryOption.to}`
      )
    }

    const reformattedErrors = props.errors.map(error => {
      let reformattedError = {}

      if (Array.isArray(error['area'])) {
        reformattedError['area'] = truncate(error.area.join(', '), {
          length: 30,
          separator: ' ',
        })
      } else {
        reformattedError['area'] = error.area
      }

      reformattedError['deliveryOption'] = deliveryOptionString(error.delivery_option)
      reformattedError['newDeliveryOption'] = deliveryOptionString(error.new_delivery_option)
      reformattedError['message'] = error.message

      return reformattedError
    })

    return (
      <div className={`flash-message ${reformattedErrors.length > 0 ? 'error' : 'success'}`}>
        <button className='flash-message__close' onClick={() => props.onClose()}>
          ✕
        </button>
        <p className='flash-message__message'>{props.message}</p>
        {reformattedErrors.length > 0 && (
          <div className='flash-message__table_wrapper'>
            <ZiplogTable
              tableName='flash-message__table'
              headers={Object.keys(reformattedErrors[0])}
              data={reformattedErrors}
            />
          </div>
        )}
      </div>
    )
  }

  return ''
}

FlashMessage.propTypes = {
  showMessage: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  errors: PropTypes.array,
  onClose: PropTypes.func.isRequired,
}
