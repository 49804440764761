import { call, put } from 'redux-saga/effects'
import { graphqlApi } from 'apis/graphql.api'
import authenticationApi from 'apis/authentication.api'

import { AUDIT_LOGS_FETCH_SUCCEEDED, AUDIT_LOGS_FETCH_FAILED } from 'actions/types'

export function* fetchAuditLogs(action) {
  try {
    const response = yield call(graphqlApi.postAuditLogs)

    graphqlApi.handleGraphqlErrors(response)
    yield put({
      type: AUDIT_LOGS_FETCH_SUCCEEDED,
      data: response.body.data.auditLogs,
    })
  } catch (error) {
    const failureAction = {
      type: AUDIT_LOGS_FETCH_FAILED,
    }

    authenticationApi.handleError(error, action, failureAction)
    yield put(failureAction)
  }
}
