import React from 'react'

import InfoTip from 'components/shared/infoTip/infoTip'

const TypeInfoTip = () => {
  const typeInfoText =
    'When choosing "incident" we will not show a banner to customers ' +
    'informing them that their order was moved. Please make sure to inform them if needed.'

  return <InfoTip data={typeInfoText} />
}

export default TypeInfoTip
