import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import GraphqlPostButton from 'components/shared/graphqlPostButton/graphqlPostButton'
import { DELIVERY_OPTIONS } from 'actions/context'

export class DeliveryOptionsPostButton extends React.PureComponent {
  isBtnDisabled() {
    return !this.props.country || !this.props.brand || this.props.isLoading
  }

  gaParams() {
    return JSON.stringify(this.generateGraphqlData())
  }

  generateGraphqlData() {
    return {
      country: this.props.country,
      brand: this.props.brand,
      zipcode: this.props.zipcode,
    }
  }

  render() {
    return (
      <GraphqlPostButton
        data={this.generateGraphqlData()}
        disabled={this.isBtnDisabled()}
        context={DELIVERY_OPTIONS}
        gaLabel={this.gaParams()}
      />
    )
  }
}

DeliveryOptionsPostButton.propTypes = {
  zipcode: PropTypes.string,
  country: PropTypes.string,
  brand: PropTypes.string,
  isLoading: PropTypes.bool,
}

function mapStateToProps(state) {
  return {
    isLoading: state.deliveryOptions.isLoading,
    country: state.globalFilters.country,
    brand: state.globalFilters.brand,
  }
}

const connectedDeliveryOptionsPostButton = connect(mapStateToProps)(DeliveryOptionsPostButton)

export default connectedDeliveryOptionsPostButton
