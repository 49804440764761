import {
  DELIVERY_TRACKING_FETCH_FAILED,
  DELIVERY_TRACKING_FETCH_REQUESTED,
  DELIVERY_TRACKING_FETCH_SUCCEEDED,
} from 'actions/types'
import { STATUSES } from 'helpers/constants'

const initialState = {
  status: STATUSES.initial,
  data: [],
}

const deliveryTracking = (state = initialState, action) => {
  switch (action.type) {
    case DELIVERY_TRACKING_FETCH_REQUESTED:
      return {
        ...state,
        status: STATUSES.requested,
        data: [],
      }
    case DELIVERY_TRACKING_FETCH_SUCCEEDED:
      return {
        ...state,
        status: STATUSES.resolved,
        data: action.data,
      }
    case DELIVERY_TRACKING_FETCH_FAILED:
      return { ...initialState, status: STATUSES.failed }
    default:
      return state
  }
}

export default deliveryTracking
