import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import CSVDownloadButton from 'components/shared/csvDownloadButton/csvDownloadButton'

export class DownloadDeliveryOptionsByRegionButton extends Component {
  headers() {
    let headers = [
      { label: 'Region', key: 'region' },
      { label: 'From', key: 'from' },
      { label: 'To', key: 'to' },
      { label: 'Shipper', key: 'shipper' },
      { label: 'Wday', key: 'wday' },
      { label: 'Production Site', key: 'productionSite' },
      { label: 'Transit Time', key: 'transitTime' },
      { label: 'Price Tier ID', key: 'priceTierIdentifier' },
    ]

    if (this.props.country === 'au') {
      headers.splice(1, 0, { label: 'Suburb', key: 'suburb' })
    }

    return headers
  }

  fileName() {
    const { country, brand, filters } = this.props
    let filename = `delivery_options_${country}_${brand}`
    if (Object.entries(filters).length > 0) {
      filename = `${filename}_${Object.values(this.props.filters).join('_')}`
    }

    return `${filename}.csv`
  }

  render() {
    return (
      <CSVDownloadButton
        filename={this.fileName()}
        data={this.props.data}
        headers={this.headers()}
        text='Download CSV by Region'
      />
    )
  }
}

DownloadDeliveryOptionsByRegionButton.propTypes = {
  country: PropTypes.string,
  brand: PropTypes.string,
  filters: PropTypes.object,
  data: PropTypes.object,
}

function mapStateToProps(state) {
  return {
    country: state.globalFilters.country,
    brand: state.globalFilters.brand,
    filters: state.deliveryOptions.filters,
  }
}

const connectedDownloadDeliveryOptionsByRegionButton = connect(mapStateToProps)(
  DownloadDeliveryOptionsByRegionButton,
)

export default connectedDownloadDeliveryOptionsByRegionButton
