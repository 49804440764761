import moment from 'moment'
import {
  SHIPPING_CSVS_FILTER_DATA_FETCH_REQUESTED,
  SHIPPING_CSVS_FILTER_DATA_FETCH_SUCCEEDED,
  SHIPPING_CSVS_FILTER_DATA_FETCH_FAILED,
  SHIPPING_CSVS_UPDATE_FILTERS,
  SHIPPING_CSVS_UPDATE_LOADING,
} from 'actions/types'

const today = moment().format('YYYY-MM-DD')

const initialState = {
  data: {},
  productionSites: [],
  productionSite: 'All',
  deliveryDate: today,
  loading: false,
}

const shippingCsvs = (state = initialState, action) => {
  switch (action.type) {
    case SHIPPING_CSVS_FILTER_DATA_FETCH_REQUESTED:
      return {
        ...state,
        loading: true,
      }
    case SHIPPING_CSVS_FILTER_DATA_FETCH_SUCCEEDED:
      return {
        ...state,
        loading: false,
        data: action.data,
      }
    case SHIPPING_CSVS_FILTER_DATA_FETCH_FAILED:
      return initialState
    case SHIPPING_CSVS_UPDATE_FILTERS:
      return {
        ...state,
        [action.key]: action.value,
      }
    case SHIPPING_CSVS_UPDATE_LOADING:
      return {
        ...state,
        loading: action.loading,
      }
    default:
      return state
  }
}

export default shippingCsvs
