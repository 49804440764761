import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'

import './deliveryDateSelect.css'

class DeliveryDateSelect extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: '',
    }
  }

  _formatDate(dateString) {
    return dateString ? moment(dateString).format('ddd, MMMM D') : null
  }

  _deliveryDatesInWeek() {
    const startDate = moment(this.props.deliveryDate).startOf('isoWeek')
    let dates = []

    for (let i = 0; i < 7; i++) {
      dates.push(startDate.clone().add(i, 'days').format('YYYY-MM-DD'))
    }

    return dates
  }

  _renderOptions() {
    const dates = this._deliveryDatesInWeek()
    let optionsHtml = [
      <option key='no-date-selected' value=''>
        Select Date
      </option>,
    ]

    dates.forEach(date => {
      optionsHtml.push(
        <option key={date} value={date}>
          {this._formatDate(date)}
        </option>,
      )
    })

    return optionsHtml
  }

  onSelectChange = e => {
    const newDate = e.target.value

    this.setState({ selected: newDate })

    this.props.onSelect(e.target.name, newDate)
  }

  render() {
    return (
      <select
        className='delivery-date-select'
        name='newDate'
        onChange={this.onSelectChange}
        value={this.state.selected}
      >
        {this._renderOptions()}
      </select>
    )
  }
}

DeliveryDateSelect.propTypes = {
  deliveryDate: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
}

export default DeliveryDateSelect
