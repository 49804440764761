import { shipmentsApi } from 'apis/shipments.api'
import authenticationApi from 'apis/authentication.api'
import { call, put, takeLatest } from 'redux-saga/effects'

import {
  SHIPPING_CSVS_FILTER_DATA_FETCH_REQUESTED,
  SHIPPING_CSVS_FILTER_DATA_FETCH_SUCCEEDED,
  SHIPPING_CSVS_FILTER_DATA_FETCH_FAILED,
} from 'actions/types'

export function* fetchShippingCsvFilterData(action) {
  try {
    const response = yield call(shipmentsApi.fetchFilterData)

    yield put({
      type: SHIPPING_CSVS_FILTER_DATA_FETCH_SUCCEEDED,
      data: response.body.data,
    })
  } catch (error) {
    const failureAction = {
      type: SHIPPING_CSVS_FILTER_DATA_FETCH_FAILED,
    }

    authenticationApi.handleError(error, action, failureAction)

    yield put(failureAction)
  }
}

export const fetchShippingCsvFilterDataSagas = [
  takeLatest(SHIPPING_CSVS_FILTER_DATA_FETCH_REQUESTED, fetchShippingCsvFilterData),
]
