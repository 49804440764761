import superagent from 'superagent'

const mappingParams = params => {
  const formattedParams = {}

  if (params && params.only) {
    formattedParams['only[]'] = params.only
  }

  return formattedParams
}

const fetchMappings = params => {
  const url = `${process.env.REACT_APP_LOGISTICS_API_URL}/mappings`
  const logisticsToken = localStorage.getItem('logisticsToken')

  return superagent
    .get(url)
    .query(mappingParams(params))
    .set('Authorization', `Bearer ${logisticsToken}`)
    .set('Content-Type', 'application/json')
    .set('X-Issuer', 'ziplog')
}

export const mappingsApi = { fetchMappings }
