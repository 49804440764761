import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { connect } from 'react-redux'
import { getTokenInfo } from 'actions/authentication'
import { AUTHENTICATION_LOGIN_REQUESTED } from 'actions/types'
import UserInfo from './userInfo/userInfo'
import GoogleAuthButton from './googleAuthButton'
import { openErrorAlert } from 'actions/alert/alert'
import './authenticate.css'

export const Authenticate = props => {
  const { dispatch, isLoggedIn, email } = props

  useEffect(() => {
    const logisticsToken = localStorage.getItem('logisticsToken')

    if (logisticsToken) dispatch(getTokenInfo())
  }, [])

  const onFailure = error => dispatch(openErrorAlert(error.message))

  const onSuccess = response => {
    localStorage.setItem('googleToken', response.access_token)
    dispatch({ type: AUTHENTICATION_LOGIN_REQUESTED })
  }

  if (isLoggedIn) {
    return <UserInfo email={email} />
  }

  return (
    <div className='login-button'>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <GoogleAuthButton onSuccess={onSuccess} onFailure={onFailure} />
      </GoogleOAuthProvider>
    </div>
  )
}

Authenticate.propTypes = {
  dispatch: PropTypes.func.isRequired,
  email: PropTypes.string,
  isLoggedIn: PropTypes.bool,
}

function mapStateToProps(state) {
  return {
    isLoggedIn: state.currentUser.isLoggedIn,
    email: state.currentUser.email,
  }
}

const connectedAuthenticate = connect(mapStateToProps)(Authenticate)

export default connectedAuthenticate
