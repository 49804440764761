import authenticationApi from 'apis/authentication.api'
import { call, put, takeLatest } from 'redux-saga/effects'

import { setIsLoggedIn } from 'actions/authentication'
import {
  AUTHENTICATION_LOGIN_REQUESTED,
  AUTHENTICATION_TOKEN_REFRESH_REQUESTED,
  AUTHENTICATION_TOKEN_INFO_REQUESTED,
} from 'actions/types'

export function* login(_action) {
  try {
    const response = yield call(authenticationApi.login)

    yield put(setIsLoggedIn(response.body))
  } catch (error) {
    authenticationApi.handleError(error, null, null)
  }
}

export function* refreshToken(action) {
  try {
    const response = yield call(authenticationApi.login)
    yield put(setIsLoggedIn(response.body))

    if (action.replayAction) {
      yield put(action.replayAction)
    }
  } catch (error) {
    authenticationApi.handleError(error, null, null)
  }
}

export function* getTokenInfo(_action) {
  try {
    yield call(authenticationApi.getTokenInfo)
  } catch (error) {
    authenticationApi.handleError(error, null, null)
  }
}

export const authenticationSagas = [
  takeLatest(AUTHENTICATION_LOGIN_REQUESTED, login),
  takeLatest(AUTHENTICATION_TOKEN_REFRESH_REQUESTED, refreshToken),
  takeLatest(AUTHENTICATION_TOKEN_INFO_REQUESTED, getTokenInfo),
]
