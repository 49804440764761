import { SHIP_ADDRESS_FETCH_SUCCEEDED, SHIP_ADDRESS_UPDATE_REQUESTED } from './types'

export const changeShipAddress = shipAddress => {
  return {
    type: SHIP_ADDRESS_UPDATE_REQUESTED,
    data: { address: shipAddress },
  }
}

export const shipAddressFetchSuccess = shipAddress => {
  return {
    type: SHIP_ADDRESS_FETCH_SUCCEEDED,
    data: shipAddress,
  }
}
