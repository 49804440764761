import React from 'react'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import ZplLabel from './zplLabel/zplLabel'
import { shippingLabelApi } from 'apis/shippingLabel.api'
import { handleError } from 'apis/shared/handleApiError'
import './shippingLabel.css'

function fetchShippingLabelReducer(state, action) {
  switch (action.type) {
    case 'fetch':
      return {
        loading: true,
        image: null,
        filetype: null,
        error: null,
      }
    case 'success':
      return {
        loading: false,
        image: action.data.image,
        filetype: action.data.filetype,
        error: null,
      }
    case 'error': {
      return {
        ...state,
        loading: false,
        error: action.error.message,
      }
    }
    default:
      throw new Error(`Action ${action.type} is not supported.`)
  }
}

export const ShippingLabel = props => {
  const [state, dispatch] = React.useReducer(fetchShippingLabelReducer, {
    loading: true,
    image: null,
    filetype: null,
    error: null,
  })
  const shipmentNumber = props.match.params.shipmentNumber

  const featureFlags = searchString => {
    let featureFlags = []

    if (searchString.length > 0) {
      const queryObj = queryString.parse(searchString)
      if (queryObj['featureFlags']) {
        featureFlags = queryObj['featureFlags'].split(',')
      }
    }

    return featureFlags
  }

  React.useEffect(() => {
    dispatch({ type: 'fetch' })

    const fetchShippingLabel = async () => {
      try {
        const response = await shippingLabelApi.getShippingLabel(
          shipmentNumber,
          featureFlags(props.location.search),
        )
        dispatch({ type: 'success', data: response.body })
      } catch (error) {
        dispatch({ type: 'error', error: error })
        handleError(error)
      }
    }

    fetchShippingLabel()
  }, [])

  if (state.loading) {
    return (
      <div className='shipping-label__wrapper'>
        <div className='shipping-label__loader'>Loading...</div>
      </div>
    )
  }

  if (state.error) {
    return (
      <div className='shipping-label__wrapper'>
        <div className='shipping-label__content'>
          {`No active shipping label found for shipment ${shipmentNumber}.`}
        </div>
      </div>
    )
  }

  if (state.filetype === 'zpl') {
    return (
      <div className='shipping-label__wrapper'>
        <ZplLabel imageText={state.image} />
      </div>
    )
  }

  return (
    <div className='shipping-label__wrapper'>
      <img
        src={`data:image/${state.filetype};base64,${state.image}`}
        className='shipping-label__content'
        alt='shipping label'
      />
    </div>
  )
}

ShippingLabel.propTypes = {
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.string,
  match: PropTypes.object,
}

export default ShippingLabel
