import { call, put, takeLatest } from 'redux-saga/effects'
import authenticationApi from 'apis/authentication.api'
import { shipAddressApi } from 'apis/shipAddress.api'
import {
  SHIP_ADDRESS_UPDATE_REQUESTED,
  SHIP_ADDRESS_UPDATE_SUCCEEDED,
  SHIP_ADDRESS_UPDATE_FAILED,
} from 'actions/types'
import { openSuccessAlert } from 'actions/alert/alert'

export function* changeShipAddress(action) {
  try {
    const body = action.data?.address

    yield call(shipAddressApi.changeShipAddress, body['shipmentId'], {
      address: body,
    })

    yield call(handleSuccess)
  } catch (error) {
    const failureAction = { type: SHIP_ADDRESS_UPDATE_FAILED }

    authenticationApi.handleError(error, action, failureAction)
    yield put(failureAction)
  }
}

function* handleSuccess() {
  yield put({ type: SHIP_ADDRESS_UPDATE_SUCCEEDED })

  yield put(openSuccessAlert('The shipping address was successfully updated.'))
}

export const changeShipAddressSagas = [takeLatest(SHIP_ADDRESS_UPDATE_REQUESTED, changeShipAddress)]
