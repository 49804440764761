import React from 'react'
import { connect } from 'react-redux'
import { filter } from 'lodash'
import queryString from 'query-string'
import PropTypes from 'prop-types'
import DeliveryOptionsFilters from './deliveryOptionsFilters/deliveryOptionsFilters'
import DeliveryOptionsPostButton from './deliveryOptionsPostButton/deliveryOptionsPostButton'
import DeliveryOptionsTable from './deliveryOptionsTable/deliveryOptionsTable'
import DownloadDeliveryOptionsByZipcodeButton from './downloadDeliveryOptionsByZipcodeButton/downloadDeliveryOptionsByZipcodeButton'
import DownloadDeliveryOptionsByRegionButton from './downloadDeliveryOptionsByRegionButton/downloadDeliveryOptionsByRegionButton'
import CountryFilter from 'components/shared/countryFilter/countryFilter'
import BrandFilter from 'components/shared/brandFilter/brandFilter'
import ZipcodeInput from 'components/shared/zipcodeInput/zipcodeInput'
import { clearData, setGlobalFilters, fetchData } from 'actions/index'
import { DELIVERY_OPTIONS } from 'actions/context'

import './deliveryOptions.css'

export class DeliveryOptions extends React.PureComponent {
  constructor(props) {
    super(props)
    let zipcode
    const urlParams = this.props.location.search

    if (urlParams.length > 0) {
      const queryObj = queryString.parse(urlParams)
      zipcode = queryObj.zipcode
      this.dispatchGlobalFilters(queryObj)
      this.fetchDeliveryOptionsData(queryObj)
    }

    this.state = { zipcode }
  }

  dispatchGlobalFilters(queryObj) {
    const data = this.generateGlobalFiltersData(queryObj)
    this.props.dispatch(setGlobalFilters(data))
  }

  fetchDeliveryOptionsData(queryObj) {
    if (queryObj.country) {
      queryObj.brand = queryObj.brand ? queryObj.brand : 'ms'
      this.props.dispatch(fetchData(DELIVERY_OPTIONS, queryObj))
    }
  }

  generateGlobalFiltersData(queryObj) {
    let data = {}

    if (queryObj.country) {
      data.country = queryObj.country.toLowerCase()
    }
    if (queryObj.brand) {
      data.brand = queryObj.brand.toLowerCase()
    }

    return data
  }

  filteredDeliveryOptions() {
    return this.props.deliveryOptions
      ? filter(this.props.deliveryOptions, this.props.activeFilters)
      : null
  }

  getZipcodeCallback = zipcode => {
    this.setState({ zipcode })
  }

  componentWillUnmount() {
    this.props.dispatch(clearData(DELIVERY_OPTIONS))
  }

  renderDownloadButtons(data) {
    if (data && data.length > 0) {
      return (
        <div className='delivery-options__download-buttons'>
          <DownloadDeliveryOptionsByRegionButton data={data} />
          <DownloadDeliveryOptionsByZipcodeButton zipcode={this.state.zipcode} />
        </div>
      )
    }
  }

  render() {
    return (
      <div className='delivery-options'>
        <div className='delivery-options__required-filters'>
          <CountryFilter context={DELIVERY_OPTIONS} countries={this.props.countriesAllowedToView} />
          <BrandFilter context={DELIVERY_OPTIONS} />
          <ZipcodeInput zipcode={this.state.zipcode} changeCallback={this.getZipcodeCallback} />
          <DeliveryOptionsPostButton zipcode={this.state.zipcode} />
        </div>

        <DeliveryOptionsFilters />

        {this.renderDownloadButtons(this.filteredDeliveryOptions())}

        <DeliveryOptionsTable
          data={this.filteredDeliveryOptions()}
          isLoading={this.props.deliveryOptionsLoading}
          country={this.props.country}
        />
      </div>
    )
  }
}

DeliveryOptions.propTypes = {
  location: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  deliveryOptions: PropTypes.array,
  deliveryOptionsLoading: PropTypes.bool,
  country: PropTypes.string,
  suburb: PropTypes.string,
  region: PropTypes.string,
  shipper: PropTypes.string,
  productionSite: PropTypes.string,
  deliveryDay: PropTypes.string,
  countriesAllowedToView: PropTypes.array,
  activeFilters: PropTypes.object,
}

function mapStateToProps(state) {
  return {
    country: state.globalFilters.country,
    deliveryOptions: state.deliveryOptions.data,
    deliveryOptionsLoading: state.deliveryOptions.isLoading,
    activeFilters: state.deliveryOptions.filters,
    region: state.deliveryOptions.filters.region,
    suburb: state.deliveryOptions.filters.suburb,
    shipper: state.deliveryOptions.filters.shipper,
    productionSite: state.deliveryOptions.filters.productionSite,
    deliveryDay: state.deliveryOptions.filters.deliveryDay,
    countriesAllowedToView: state.currentUser.countriesAllowedToView,
  }
}

const connectedDeliveryOptions = connect(mapStateToProps)(DeliveryOptions)

export default connectedDeliveryOptions
