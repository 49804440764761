import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import BaseModal from 'components/modal/baseModal'
import { hideModal } from 'actions/modal'
import { updateZipcodes } from 'actions/zipcodes'

import './previewModal.css'

export const PreviewModal = props => {
  const closeModal = () => {
    props.dispatch(hideModal())
  }

  const submitUpdateZipcodes = () => {
    props.dispatch(
      updateZipcodes(props.country, props.brand, props.firstDeliveryDate, props.csvContent),
    )

    closeModal()
  }

  return (
    <BaseModal onClose={closeModal}>
      <p className='zipcodes-add-preview-modal__warning_text'>
        If you are updating <b>transit times and/or production sites</b>, <br />
        please inform the Digital Logistics team, if any
        <b> future Exceptions </b>
        exist for those zipcodes. <br />
        <br />
        Upcoming Exceptions will <b>NOT</b> be auto-updated with this action.
      </p>

      <p className='zipcodes-add-preview-modal__text'>
        Are you sure you want to update these zipcodes?
      </p>
      <button className='zipcodes-add-preview-modal__cancel-button' onClick={closeModal}>
        Cancel
      </button>
      <button
        className='button-primary zipcodes-add-preview-modal__submit-button'
        onClick={submitUpdateZipcodes}
      >
        {`Yes, I'm sure`}
      </button>
    </BaseModal>
  )
}

PreviewModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  country: PropTypes.string,
  brand: PropTypes.string,
  firstDeliveryDate: PropTypes.string,
  csvContent: PropTypes.string,
}

function mapStateToProps(state) {
  return {
    country: state.globalFilters.country,
    brand: state.globalFilters.brand,
    firstDeliveryDate: state.zipcodesUpdate.firstDeliveryDate,
    csvContent: state.zipcodesUpdate.csvContent,
  }
}

const connectedPreviewModal = connect(mapStateToProps)(PreviewModal)

export default connectedPreviewModal
