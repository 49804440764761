import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import BaseModal from 'components/modal/baseModal'
import { hideModal } from 'actions/modal'
import { updateDynamicOptions } from 'actions/dynamicOptions'

import './previewModal.css'

export const PreviewModal = props => {
  const closeModal = () => {
    props.dispatch(hideModal())
  }

  const submitUpdateDynamicOptions = () => {
    props.dispatch(
      updateDynamicOptions(
        props.country,
        props.brand,
        props.csvContent,
        props.firstDeliveryWeek,
        props.createNewIfNotExist,
      ),
    )

    closeModal()
  }

  return (
    <BaseModal onClose={closeModal}>
      <p className='dynamic-options-update-preview-modal__text'>
        Are you sure you want to update these dynamic options?
      </p>
      <button className='dynamic-options-update-preview-modal__cancel-button' onClick={closeModal}>
        Cancel
      </button>
      <button
        className='button-primary dynamic-options-update-preview-modal__submit-button'
        onClick={submitUpdateDynamicOptions}
      >
        {`Yes, I'm sure`}
      </button>
    </BaseModal>
  )
}

PreviewModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  country: PropTypes.string,
  brand: PropTypes.string,
  csvContent: PropTypes.string,
  firstDeliveryWeek: PropTypes.string,
  createNewIfNotExist: PropTypes.bool,
}

function mapStateToProps(state) {
  return {
    country: state.globalFilters.country,
    brand: state.globalFilters.brand,
    csvContent: state.dynamicOptionsUpdate.csvContent,
    firstDeliveryWeek: state.modal.data.firstDeliveryWeek,
    createNewIfNotExist: state.modal.data.createNewIfNotExist,
  }
}

const connectedPreviewModal = connect(mapStateToProps)(PreviewModal)

export default connectedPreviewModal
