import {
  ZIPCODES_CREATE_CSV_UPLOADED,
  ZIPCODES_CREATE_REQUESTED,
  ZIPCODES_UPDATE_CSV_UPLOADED,
  ZIPCODES_UPDATE_REQUESTED,
  ZIPCODES_UPDATE_FILTERS,
} from './types'

export const setFilters = (key, value) => {
  return {
    type: ZIPCODES_UPDATE_FILTERS,
    key: key,
    value: value,
  }
}

export const updateZipcodesCsvUpload = data => {
  return {
    type: ZIPCODES_UPDATE_CSV_UPLOADED,
    csvContent: data,
  }
}

export const updateZipcodes = (country, brand, firstDeliveryDate, data) => {
  return {
    type: ZIPCODES_UPDATE_REQUESTED,
    country: country,
    brand: brand,
    firstDeliveryDate: firstDeliveryDate,
    csvContent: data,
  }
}

export const createZipcodesCsvUpload = data => {
  return {
    type: ZIPCODES_CREATE_CSV_UPLOADED,
    csvContent: data,
  }
}

export const createZipcodes = (country, brand, data) => {
  return {
    type: ZIPCODES_CREATE_REQUESTED,
    country: country,
    brand: brand,
    csvContent: data,
  }
}
