import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import AppConfig from 'config'
import Filter from '../filter/filter'
import { clearData, setGlobalFilter } from 'actions/index'

export const BrandFilter = props => {
  const handleChange = e => {
    props.dispatch(clearData(props.context))
    props.dispatch(setGlobalFilter('brand', e.target.value))
  }

  return (
    <Filter
      name='brand'
      displayUpcase
      onChange={handleChange}
      data={AppConfig.brands}
      selectedValue={props.brand}
      labelText='Brand'
    />
  )
}

BrandFilter.propTypes = {
  dispatch: PropTypes.func.isRequired,
  brand: PropTypes.string,
  context: PropTypes.string,
}

function mapStateToProps(state) {
  return {
    brand: state.globalFilters.brand,
  }
}

const connectedBrandFilter = connect(mapStateToProps)(BrandFilter)

export default connectedBrandFilter
