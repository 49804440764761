import {
  DELIVERY_OPTIONS_FETCH_REQUESTED,
  DELIVERY_OPTIONS_FETCH_SUCCEEDED,
  DELIVERY_OPTIONS_FETCH_FAILED,
  DELIVERY_OPTIONS_UPDATE_FILTERS,
  DELIVERY_OPTIONS_CLEAR_DATA,
} from 'actions/types'

const initialState = {
  isLoading: false,
  data: null,
  filters: {},
}

const deliveryOptions = (state = initialState, action) => {
  switch (action.type) {
    case DELIVERY_OPTIONS_FETCH_REQUESTED:
      return {
        ...state,
        isLoading: true,
        data: null,
      }
    case DELIVERY_OPTIONS_FETCH_SUCCEEDED:
      return {
        isLoading: false,
        data: action.data,
        filters: {},
      }
    case DELIVERY_OPTIONS_FETCH_FAILED:
      return initialState
    case DELIVERY_OPTIONS_UPDATE_FILTERS:
      return {
        ...state,
        filters: action.filters,
      }
    case DELIVERY_OPTIONS_CLEAR_DATA:
      return {
        ...state,
        isLoading: false,
        data: null,
      }
    default:
      return state
  }
}

export default deliveryOptions
