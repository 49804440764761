import {
  TRACKED_SHIPPERS_FETCH_FAILED,
  TRACKED_SHIPPERS_FETCH_REQUESTED,
  TRACKED_SHIPPERS_FETCH_SUCCEEDED,
  TRACKING_ACTIVITIES_CSVS_UPDATE_FILTERS,
  TRACKING_ACTIVITIES_CSVS_UPDATE_LOADING,
} from 'actions/types'

const initialState = {
  brand: '',
  shipper: '',
  shippers: [],
  loading: false,
}

const trackingActivitiesCsvs = (state = initialState, action) => {
  switch (action.type) {
    case TRACKED_SHIPPERS_FETCH_REQUESTED:
      return {
        ...state,
        loading: true,
      }
    case TRACKED_SHIPPERS_FETCH_SUCCEEDED:
      return {
        ...state,
        loading: false,
        shippers: action.data,
      }
    case TRACKED_SHIPPERS_FETCH_FAILED:
      return initialState
    case TRACKING_ACTIVITIES_CSVS_UPDATE_FILTERS:
      return {
        ...state,
        [action.key]: action.value,
      }
    case TRACKING_ACTIVITIES_CSVS_UPDATE_LOADING:
      return {
        ...state,
        loading: action.loading,
      }
    default:
      return state
  }
}

export default trackingActivitiesCsvs
