export const AUDIT_LOGS = 'AUDIT_LOGS'
export const DELIVERY_DATE_EXCEPTIONS = 'DELIVERY_DATE_EXCEPTIONS'
export const DELIVERY_EXCEPTIONS_PERMISSIONS = 'DELIVERY_EXCEPTIONS_PERMISSIONS'
export const DELIVERY_OPTIONS = 'DELIVERY_OPTIONS'
export const DELIVERY_PROMISES = 'DELIVERY_PROMISES'
export const DELIVERY_TRACKING = 'DELIVERY_TRACKING'
export const MAPPINGS = 'MAPPINGS'
export const ROLES = 'ROLES'
export const SHIPPING_CSVS = 'SHIPPING_CSVS'
export const SHIPPING_CSVS_FILTER_DATA = 'SHIPPING_CSVS_FILTER_DATA'
export const SHIPPING_LABELS = 'SHIPPING_LABELS'
export const SHIPMENT_OPTIONS = 'SHIPMENT_OPTIONS'
export const TRACKED_SHIPPERS = 'TRACKED_SHIPPERS'
export const TRACKING_ACTIVITIES_CSVS = 'TRACKING_ACTIVITIES_CSVS'
