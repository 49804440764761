import {
  AUTHENTICATION_LOGGED_OUT,
  AUTHENTICATION_LOGIN_SUCCEEDED,
  AUTHENTICATION_TOKEN_INFO_REQUESTED,
  AUTHENTICATION_TOKEN_REFRESH_REQUESTED,
} from './types'

export const setIsLoggedIn = data => {
  localStorage.setItem('logisticsToken', data.token)

  return {
    type: AUTHENTICATION_LOGIN_SUCCEEDED,
    payload: data,
  }
}

export const setIsLoggedOut = () => {
  localStorage.removeItem('logisticsToken')

  return {
    type: AUTHENTICATION_LOGGED_OUT,
  }
}

export const refreshToken = (replayAction, failureAction) => {
  return {
    type: AUTHENTICATION_TOKEN_REFRESH_REQUESTED,
    replayAction,
    failureAction,
  }
}

export const getTokenInfo = () => {
  return {
    type: AUTHENTICATION_TOKEN_INFO_REQUESTED,
  }
}
