import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { helpers } from 'helpers/helpers'
import InfoMessage from 'components/shared/infoMessage/infoMessage'

class OrderGenerationMessage extends React.PureComponent {
  formatDate(date) {
    return date.format('MMMM D')
  }

  formatDateWithDay(date) {
    return date.format('dddd, MMMM D')
  }

  weekStart(stringDate) {
    return moment(stringDate).startOf('isoWeek')
  }

  weekEnd(stringDate) {
    return moment(stringDate).endOf('isoWeek')
  }

  orderGenerationDate(country, stringDate) {
    return moment(helpers.orderGenerationDate(country, stringDate))
  }

  buildMessage() {
    const { date, country } = this.props
    const generationDate = this.orderGenerationDate(country, date)
    const startDate = this.weekStart(date)
    const endDate = this.weekEnd(date)
    const today = moment()

    return (
      `Orders for ${this.formatDate(startDate)} - ${this.formatDate(endDate)} ` +
      `in ${country.toUpperCase()} ` +
      `${generationDate > today ? 'will be' : 'were'} generated on ` +
      `${this.formatDateWithDay(generationDate)}.`
    )
  }

  messageDisplay() {
    const { date, country, brand, orderType } = this.props
    const displayMessage = country && brand && date && orderType === 'regular'

    return displayMessage ? this.buildMessage() : ''
  }

  render() {
    return <InfoMessage message={this.messageDisplay()} className={'order-generation-message'} />
  }
}

OrderGenerationMessage.propTypes = {
  country: PropTypes.string,
  brand: PropTypes.string.isRequired,
  date: PropTypes.string,
  orderType: PropTypes.string,
}

export default OrderGenerationMessage
