import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { MultiSelect } from 'react-multi-select-component'

import { clearData, setGlobalFilter } from 'actions/index'

export const CountriesFilter = props => {
  const upperCasedOptions = options => {
    return options.map(i => {
      const upperCased = i.toUpperCase()
      return { label: upperCased, value: upperCased }
    })
  }

  const allCountries = upperCasedOptions(props.allCountries)
  const countries = props.countries.length === 0 ? allCountries : upperCasedOptions(props.countries)
  const [selected, setSelected] = useState(countries)
  const overrideStrings = {
    allItemsAreSelected: 'All Countries',
    selectSomeItems: 'Please select',
  }

  useEffect(() => {
    props.dispatch(clearData(props.context))
    props.dispatch(
      setGlobalFilter(
        'countries',
        selected.map(a => a.value),
      ),
    )
  }, [selected])

  return (
    <div className='countries-filter multi-select-filter'>
      <label className='filter__label'>Countries</label>
      <MultiSelect
        options={allCountries}
        value={selected}
        onChange={setSelected}
        labelledBy='Select'
        overrideStrings={overrideStrings}
      />
    </div>
  )
}

CountriesFilter.propTypes = {
  dispatch: PropTypes.func.isRequired,
  allCountries: PropTypes.array.isRequired,
  context: PropTypes.string.isRequired,
  countries: PropTypes.array.isRequired,
}

CountriesFilter.defaultProps = {
  countries: [],
}

const mapStateToProps = state => {
  return {
    countries: state.globalFilters.countries,
  }
}

const connectedFilter = connect(mapStateToProps)(CountriesFilter)

export default connectedFilter
