import { ROLES_FETCH_REQUESTED, ROLES_FETCH_SUCCEEDED, ROLES_FETCH_FAILED } from 'actions/types'

const initialState = {
  data: null,
}

const roles = (state = initialState, action) => {
  switch (action.type) {
    case ROLES_FETCH_REQUESTED:
      return {
        data: null,
      }
    case ROLES_FETCH_SUCCEEDED:
      return {
        ...state,
        data: action.data,
      }
    case ROLES_FETCH_FAILED:
      return initialState
    default:
      return state
  }
}

export default roles
