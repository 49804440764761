import React from 'react'
import PropTypes from 'prop-types'

import Accordion from 'components/shared/accordion/accordion'
import ZiplogTable from 'components/shared/ziplogTable/ziplogTable'
import { displayField, numberToWeekday } from 'helpers/helpers'

import './deliveryPromisesAccordion.css'

const SHIPMENT_OPTIONS_HEADERS = [
  'weekday',
  'from',
  'to',
  'shipper',
  'productionSite',
  'transitTime',
  'priority',
  'startDate',
  'endDate',
]

const DeliveryPromisesAccordion = ({ keyValue, shipmentOptions }) => {
  const renderTitle = () => {
    return `${shipmentOptions.length} shipment option(s)`
  }

  const rowData = shipmentOptions => {
    return shipmentOptions.map(shipmentOption => ({
      ...shipmentOption,
      weekday: numberToWeekday(shipmentOption.wday),
      shipper: shipmentOption.shipperName,
      endDate: displayField(shipmentOption.endDate),
    }))
  }

  const renderContent = shipmentOptions => {
    return (
      <ZiplogTable
        tableName='shipment-options-inner-table'
        headers={SHIPMENT_OPTIONS_HEADERS}
        data={rowData(shipmentOptions)}
      />
    )
  }

  return (
    <Accordion
      key={keyValue}
      title={renderTitle(shipmentOptions)}
      content={renderContent(shipmentOptions)}
    />
  )
}

DeliveryPromisesAccordion.propTypes = {
  keyValue: PropTypes.string.isRequired,
  shipmentOptions: PropTypes.arrayOf(
    PropTypes.shape({
      shipper: PropTypes.string,
    }),
  ),
}

DeliveryPromisesAccordion.defaultProps = {
  shipmentOptions: [],
}

export default DeliveryPromisesAccordion
