import React from 'react'
import PropTypes from 'prop-types'

import GraphqlPostButton from 'components/shared/graphqlPostButton/graphqlPostButton'
import { DELIVERY_PROMISES } from 'actions/context'

export default function DeliveryPromisesPostButton(props) {
  const isDisabled = () => {
    return !props.data.country
  }

  const gaParams = () => {
    return JSON.stringify(props.data)
  }

  return (
    <GraphqlPostButton
      data={props.data}
      disabled={isDisabled()}
      context={DELIVERY_PROMISES}
      gaLabel={gaParams()}
    />
  )
}

DeliveryPromisesPostButton.propTypes = {
  data: PropTypes.shape({
    brand: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    first: PropTypes.number.isRequired,
    regions: PropTypes.array,
    wdays: PropTypes.array,
    zipcode: PropTypes.string,
  }),
}
