import React, { Fragment, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { deliveryPromisesApi } from 'apis/deliveryPromises.api.js'

import BrandFilter from 'components/shared/brandFilter/brandFilter'
import CountryFilter from 'components/shared/countryFilter/countryFilter'
import CsvSummary from 'components/shared/csvSummary/csvSummary'
import CsvUploader from 'components/shared/csvUploader/csvUploader'
import InfoMessage from 'components/shared/infoMessage/infoMessage'
import ZiplogLink from 'components/shared/ziplogLink/ziplogLink'

import { showModal } from 'actions/modal'
import { CONFIRM_DIALOG_MODAL } from 'components/modal/modalTypes'

import { without } from 'lodash'

import { STATUSES } from 'helpers/constants'
import { DELIVERY_PROMISES } from 'actions/context'

const INFO_MESSAGE_TEXT =
  'This page is a work in progress and should not yet be used for daily work. ' +
  'However you are very welcome to explore it and ask us any questions you may have. ' +
  'Feedback would be awesome.'

const DELIVERY_PROMISES_INFO_TEXT =
  'Updating here will move all orders (regular and special) and user plans to the new delivery promise. Old Delivery Promises will no longer be available.'

const ZIPCODE_TEMPLATE_LINK =
  'https://docs.google.com/spreadsheets/d/1-W-s7_qo506Tn4gsLJwJE3LkmT2jTlDdn4km-x2MlQw/edit#gid=409412900'
const REGION_TEMPLATE_LINK =
  'https://docs.google.com/spreadsheets/d/1-W-s7_qo506Tn4gsLJwJE3LkmT2jTlDdn4km-x2MlQw/edit#gid=1099274271'
const ERROR_HEADERS = [
  'zipcode',
  'region',
  'from',
  'to',
  'wday',
  'effective_from_week',
  'new_from',
  'new_to',
  'new_wday',
]
const SHORT_FORMATTED_KEYS = {
  wday: 'Weekday',
  new_wday: 'New Weekday',
}
const FORMATTED_KEYS = {
  wday: 'Weekday',
  new_wday: 'New Weekday',
}
const PREVIEW_DETAILS = {
  summary: {
    key: 'aggregated_delivery_promises',
    message: 'Preview a summary of the uploaded data:',
    headers: [
      'region',
      'zipcodes',
      'from',
      'to',
      'wday',
      'effective_from_week',
      'new_from',
      'new_to',
      'new_wday',
    ],
  },
}
const NOT_AVAILABLE = 'N/A'

export const DeliveryPromisesReplace = props => {
  const [selectedFile, setSelectedFile] = useState(null)
  const [resetUploaderKey, setResetUploaderKey] = useState(0)
  const [status, setStatus] = useState(STATUSES.initial)
  const [summary, setSummary] = useState({
    data: [],
    errors: [],
    message: '',
  })

  useEffect(() => {
    abort()
  }, [props.country, props.brand])

  const previewDetails = () => {
    const data = summary.data && summary.data['aggregated_delivery_promises']
    if (data && data[0]) {
      if (!Array.isArray(data[0].area)) {
        return PREVIEW_DETAILS
      }
      // exclude area for CSVs by zipcode
      return {
        summary: {
          ...PREVIEW_DETAILS.summary,
          headers: without(PREVIEW_DETAILS.summary.headers, 'area'),
        },
      }
    }
    return PREVIEW_DETAILS
  }

  const csvUploaderEnabled = () => {
    return !!(props.country && props.brand && status !== STATUSES.resolved)
  }

  const onFileUpload = file => {
    setSelectedFile(file)
    setStatus(STATUSES.requested)

    /* eslint-disable */
    deliveryPromisesApi
      .validate(props.country, props.brand, file, true)
      .end((err, res) => {
        const body = (res && res.body) || {}
        setSummary({
          data: body.summary || {},
          errors: body.errors || [],
          message: body.message || err
        })

        if (res && res.ok) {
          setStatus(STATUSES.resolved)
        } else {
          setStatus(STATUSES.failed)
        }
      })
    /* eslint-enable */
  }

  const submit = () => {
    setStatus(STATUSES.requested)
    /* eslint-disable */
    deliveryPromisesApi
      .bulkReplace(props.country, props.brand, selectedFile)
      .end((err, res) => {
        const body = (res && res.body) || {}
        setSummary({
          data: {},
          errors: [{
            index: NOT_AVAILABLE,
            errors: body.errors || []
          }],
          message: body.message || err
        })

        if (res && res.ok) {
          setStatus(STATUSES.resolved)
          setResetUploaderKey(resetUploaderKey + 1)
        } else {
          setStatus(STATUSES.failed)
        }
      })
    /* eslint-enable */
  }

  const abort = () => {
    setStatus(STATUSES.initial)
    setResetUploaderKey(resetUploaderKey + 1)
  }

  const onAbort = () => {
    props.dispatch(
      showModal(CONFIRM_DIALOG_MODAL, {
        message: 'Are you sure you want to abort this process?',
        onConfirm: abort,
      }),
    )
  }

  const onSubmit = () => {
    props.dispatch(
      showModal(CONFIRM_DIALOG_MODAL, {
        message: 'Are you sure you want to replace these Delivery Promises?',
        onConfirm: submit,
        targetId: 'confirm-submit',
      }),
    )
  }

  return (
    <Fragment>
      <InfoMessage message={INFO_MESSAGE_TEXT} />
      <div className='filters-block'>
        <CountryFilter context={DELIVERY_PROMISES} countries={props.countriesAllowedToView} />
        <BrandFilter context={DELIVERY_PROMISES} />
      </div>
      <InfoMessage message={DELIVERY_PROMISES_INFO_TEXT} />
      <CsvSummary
        onAbort={onAbort}
        errorHeaders={ERROR_HEADERS}
        shortFormattedKeys={SHORT_FORMATTED_KEYS}
        formattedKeys={FORMATTED_KEYS}
        status={status}
        onSubmit={onSubmit}
        summary={summary}
        previewDetails={previewDetails()}
      />
      <div className='bordered-wrapper'>
        <CsvUploader
          buttonText='Upload Delivery Promises'
          key={resetUploaderKey}
          enabled={csvUploaderEnabled()}
          onFileUpload={onFileUpload}
        />
        <div className='break' />
        <div className='bordered-wrapper__links'>
          <ZiplogLink href={REGION_TEMPLATE_LINK} text='CSV Template by Region' openInNewTab />
          <ZiplogLink href={ZIPCODE_TEMPLATE_LINK} text='CSV Template by Zipcode' openInNewTab />
        </div>
      </div>
    </Fragment>
  )
}

DeliveryPromisesReplace.propTypes = {
  dispatch: PropTypes.func.isRequired,
  brand: PropTypes.string,
  countriesAllowedToView: PropTypes.array,
  country: PropTypes.string,
}

const mapStateToProps = state => {
  return {
    brand: state.globalFilters.brand,
    countriesAllowedToView: state.currentUser.countriesAllowedToView,
    country: state.globalFilters.country,
  }
}

const connectedDeliveryPromisesReplace = connect(mapStateToProps)(DeliveryPromisesReplace)

export default connectedDeliveryPromisesReplace
