import React, { useState, useEffect } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import PropTypes from 'prop-types'
import { enGB } from 'date-fns/esm/locale'

registerLocale('en-GB', enGB)

const SingleDatePicker = props => {
  const [deliveryDate, setDeliveryDate] = useState(props.defaultDateSetter(props.defaultDate))

  useEffect(() => {
    // When query strings are passed via the URL, this gets the date from the global store, and
    // updates the state of this component.
    // So that it displays the date passed via the query string.
    // However, I don't know how useful this query string filtering is, at the moment.

    setDeliveryDate(props.defaultDateSetter(props.defaultDate))
  }, [props.defaultDate])

  const updateSelectedDate = dates => {
    setDeliveryDate(dates)
    props.onDateSelect(props.formatDate(dates))
  }

  return (
    <DatePicker
      name='selectedDate'
      selected={deliveryDate}
      onChange={updateSelectedDate}
      dateFormat='MMMM d, yyyy'
      showWeekNumbers
      locale='en-GB'
    />
  )
}

SingleDatePicker.propTypes = {
  defaultDateSetter: PropTypes.func.isRequired,
  formatDate: PropTypes.func.isRequired,
  onDateSelect: PropTypes.func.isRequired,
  defaultDate: PropTypes.string,
}

export default SingleDatePicker
