import React from 'react'
import PropTypes from 'prop-types'

import './baseModal.css'

const BaseModal = props => {
  React.useEffect(() => {
    const listenKeyboard = event => {
      if (event.key === 'Escape' || event.keyCode === 27) {
        props.onClose()
      }
    }

    window.addEventListener('keydown', listenKeyboard)

    return () => {
      window.removeEventListener('keydown', listenKeyboard)
    }
  }, [])

  const onOverlayClick = () => props.onClose()

  const onDialogClick = event => event.stopPropagation()

  return (
    <div>
      <div className='base-modal__overlay' />
      <div className='base-modal__content' onClick={onOverlayClick}>
        {/*
          Anything inside the Dialog Box (your actual modal content),
          will resize the Box accordingly.
        */}
        <div className='base-modal__dialog' onClick={onDialogClick}>
          {props.children}
        </div>
      </div>
    </div>
  )
}

BaseModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
}

export default BaseModal
