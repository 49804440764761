import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import CountryFilter from 'components/shared/countryFilter/countryFilter'
import CsvUploader from 'components/shared/csvUploader/csvUploader'
import { moveOrderCsvUpload } from 'actions/orderMovement'

import { showModal } from 'actions/modal'
import { ORDER_MOVEMENT_MODAL } from 'components/modal/modalTypes'
import ZiplogLink from 'components/shared/ziplogLink/ziplogLink'

import './orderMovement.css'
import InfoMessage from 'components/shared/infoMessage/infoMessage'

const TEMPLATE_LINK =
  'https://docs.google.com/spreadsheets/d/1pXTq9j_wv7bSmp1tUQnNzP7-gqLXjT-VfnRGSm67MU4/edit#gid=44674135'

export class OrderMovement extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeFilters: {},
    }
  }

  openPreviewModal = () => {
    this.props.dispatch(showModal(ORDER_MOVEMENT_MODAL))
  }

  isBtnDisabled() {
    const { country, csvContent } = this.props
    return !country || !csvContent
  }

  onFileUpload = csvData => {
    this.props.dispatch(moveOrderCsvUpload(csvData))
  }

  infoMessageText = () => {
    return 'Moving orders can take several minutes'
  }

  render() {
    if (this.props.isLoading) {
      return <div className='order-movement__loader'>Loading...</div>
    } else if (this.props.canManage) {
      return (
        <div>
          <CountryFilter countries={this.props.countriesAllowedToManage} />
          <InfoMessage message={this.infoMessageText()} />
          <CsvUploader onFileUpload={this.onFileUpload} />
          <ZiplogLink
            href={TEMPLATE_LINK}
            className='order-movement__ziplog-link'
            text='CSV Template'
            openInNewTab
          />
          <button
            className='order-movement__save-button button-primary'
            disabled={this.isBtnDisabled()}
            onClick={this.openPreviewModal}
          >
            Save
          </button>
        </div>
      )
    }
  }
}

OrderMovement.propTypes = {
  dispatch: PropTypes.func.isRequired,
  country: PropTypes.string,
  csvContent: PropTypes.string,
  isLoading: PropTypes.bool,
  canManage: PropTypes.bool.isRequired,
  countriesAllowedToManage: PropTypes.array.isRequired,
}

function mapStateToProps(state) {
  return {
    country: state.globalFilters.country,
    csvContent: state.orderMovement.csvContent,
    canManage: state.currentUser.canManage,
    isLoading: state.orderMovement.isLoading,
    countriesAllowedToManage: state.currentUser.countriesAllowedToManage,
  }
}

const connectedOrderMovement = connect(mapStateToProps)(OrderMovement)

export default connectedOrderMovement
