/**
 * From ReactGA library
 * https://github.com/react-ga/react-ga/blob/master/demo/app/withTracker.jsx
 */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

export function privateRoute(PageComponent) {
  class WrapperComponent extends Component {
    render() {
      return this.props.isLoggedIn ? <PageComponent {...this.props} /> : null
    }
  }

  function mapStateToProps(state) {
    return {
      isLoggedIn: state.currentUser.isLoggedIn,
    }
  }

  WrapperComponent.propTypes = {
    isLoggedIn: PropTypes.bool,
  }

  const connectedWrapperComponent = connect(mapStateToProps, null, null, {
    pure: false,
  })(WrapperComponent)

  return { connectedWrapperComponent, WrapperComponent }
}
