import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import BrandsFilter from 'components/shared/brandsFilter/brandsFilter'
import CountriesFilter from 'components/shared/countriesFilter/countriesFilter'
import DeliveryDatePicker from 'components/shared/deliveryDatePicker/deliveryDatePicker'
import InfoMessage from 'components/shared/infoMessage/infoMessage'
import LostLabelsPostButton from 'components/lostLabels/lostLabelsPostButton/lostLabelsPostButton'
import LostLabelsTable from 'components/lostLabels/lostLabelsTable/lostLabelsTable'

import { SHIPPING_LABELS } from 'actions/context'

import 'components/lostLabels/lostLabels.css'

// TO-DO:
// remove this component and any other code for pending labels here and in the backend,
// if there is no need for it in the foreseable future.

const INFO_MESSAGE_TEXT =
  "Labels with a 'pending' status are shown here. " +
  'They do not typically require any action, so only add noise to the Missing Labels page. ' +
  'Pending labels are automatically fetched once daily.'

export const PendingLabels = props => {
  const graphqlApiData = () => {
    return {
      pendingLabels: true,
      countries: props.countries,
      brand: props.brands,
      deliveryDateRange: props.deliveryDateRange,
    }
  }

  React.useEffect(() => {
    const data = {
      ...graphqlApiData(),
      countries: props.countriesAllowedToView,
    }
    props.dispatch({
      type: `[${SHIPPING_LABELS}]_MISSING_LABELS_FETCH_REQUESTED`,
      data,
    })
  }, [])

  return (
    <Fragment>
      <InfoMessage message={INFO_MESSAGE_TEXT} />
      <div className='filters-block'>
        <CountriesFilter allCountries={props.countriesAllowedToView} context={SHIPPING_LABELS} />
        <BrandsFilter context={SHIPPING_LABELS} />
        <DeliveryDatePicker context={SHIPPING_LABELS} selectsDateRange />
        <LostLabelsPostButton graphqlApiData={graphqlApiData()} />
      </div>
      <LostLabelsTable data={props.data} status={props.status} />
    </Fragment>
  )
}

PendingLabels.propTypes = {
  dispatch: PropTypes.func.isRequired,
  brands: PropTypes.string,
  countriesAllowedToView: PropTypes.array,
  countries: PropTypes.array,
  data: PropTypes.array,
  deliveryDateRange: PropTypes.array.isRequired,
  status: PropTypes.number.isRequired,
}

const mapStateToProps = state => {
  return {
    brands: state.globalFilters.brands,
    countriesAllowedToView: state.currentUser.countriesAllowedToView,
    countries: state.globalFilters.countries,
    data: state.shippingLabels.missingLabelsData,
    deliveryDateRange: state.globalFilters.deliveryDateRange,
    status: state.shippingLabels.status,
  }
}

const connectedPendingLabels = connect(mapStateToProps)(PendingLabels)

export default connectedPendingLabels
