import {
  AUDIT_LOGS_FETCH_REQUESTED,
  AUDIT_LOGS_FETCH_SUCCEEDED,
  AUDIT_LOGS_FETCH_FAILED,
} from 'actions/types'

const initialState = {
  isLoading: false,
  data: null,
}

const auditLogs = (state = initialState, action) => {
  switch (action.type) {
    case AUDIT_LOGS_FETCH_REQUESTED:
      return {
        isLoading: true,
        data: null,
      }
    case AUDIT_LOGS_FETCH_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: action.data,
      }
    case AUDIT_LOGS_FETCH_FAILED:
      return initialState
    default:
      return state
  }
}

export default auditLogs
