import moment from 'moment'
import { SET_GLOBAL_FILTER, SET_GLOBAL_FILTERS } from 'actions/types'

const today = moment().format('YYYY-MM-DD')
const endDate = moment().add(1, 'week').format('YYYY-MM-DD')

const initialState = {
  country: '',
  countries: [],
  brand: 'ms',
  brands: 'all',
  regularDeliveryDate: today,
  deliveryDate: today,
  deliveryDateRange: [today, endDate],
  orderType: 'regular',
  regions: [],
}

const globalFilters = (state = initialState, action) => {
  switch (action.type) {
    case SET_GLOBAL_FILTER:
      return {
        ...state,
        [action.key]: action.value,
      }
    case SET_GLOBAL_FILTERS:
      return {
        ...state,
        ...action.data,
      }
    default:
      return state
  }
}

export default globalFilters
