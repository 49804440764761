import Moment from 'moment'
import { extendMoment } from 'moment-range'

const moment = extendMoment(Moment)

export function nextWeeks(numberOfWeeks, startDate = moment()) {
  const endDate = startDate.clone().add(numberOfWeeks - 1, 'w')

  return weeksInRange(startDate, endDate).map(date => weekData(date))
}

export function isPastDate(date, format = 'YYYY-MM-DD') {
  const yesterday = moment().subtract(1, 'days')

  return !moment(date, format).isAfter(yesterday)
}

export function weekdayString(weekdayNumber) {
  return ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'][weekdayNumber - 1]
}

function endOfWeek(date) {
  return date.endOf('isoWeek')
}

function formatWeekDate(date) {
  return date.format('dddd, MMMM D')
}

function startOfWeek(date) {
  return date.startOf('isoWeek')
}

function weekData(date) {
  return {
    iso8601Week: iso8601Week(date),
    weekNumber: weekNumber(date),
    startDate: formatWeekDate(startOfWeek(date)),
    endDate: formatWeekDate(endOfWeek(date)),
  }
}

function weeksInRange(startDate, endDate) {
  return Array.from(moment.range(startDate, endDate).by('week'))
}

function weekNumber(date) {
  const weekNumber = date.format('W')

  return parseInt(weekNumber, 10)
}

function iso8601Week(date) {
  const weekNumber = date.format('WW')
  const year = date.format('GGGG')

  return `${year}-W${weekNumber}`
}
