import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import AppConfig from 'config'
import Filter from '../filter/filter'
import { clearData, setGlobalFilter } from 'actions/index'

const ALL_BRANDS_TEXT = 'All Brands'
const ALL_BRANDS = 'all'

export const BrandsFilter = props => {
  const handleChange = e => {
    props.dispatch(clearData(props.context))
    props.dispatch(setGlobalFilter('brands', e.target.value))
  }

  return (
    <Filter
      name='brands'
      displayUpcase
      onChange={handleChange}
      data={AppConfig.brands}
      selectedValue={props.brands}
      labelText='Brand'
      defaultText={ALL_BRANDS_TEXT}
      defaultValue={ALL_BRANDS}
    />
  )
}

function mapStateToProps(state) {
  return {
    brands: state.globalFilters.brands,
  }
}

BrandsFilter.propTypes = {
  dispatch: PropTypes.func.isRequired,
  context: PropTypes.string.isRequired,
  brands: PropTypes.array,
}

const connectedBrandsFilter = connect(mapStateToProps)(BrandsFilter)
export default connectedBrandsFilter
