import superagent from 'superagent'

export const shipmentOptionsApi = {
  bulkAction,
  validate,
  bulkAdd,
}

function bulkAction(country, brand, csvContent, actionName) {
  if (actionName === 'remove') {
    return bulkRemove(country, brand, csvContent)
  } else {
    return bulkAdd(country, brand, csvContent)
  }
}

function validate(country, brand, csvContent, replace = false, remove = false) {
  const body = {
    country,
    brand,
    csv_content: csvContent,
    replace: replace,
    delete: remove,
  }

  return post('validate', body)
}

function bulkRemove(country, brand, csvContent) {
  const body = {
    country,
    brand,
    csv_content: csvContent,
  }

  return deleteRequest('bulk_delete', body)
}

function bulkAdd(country, brand, csvContent) {
  const body = {
    country,
    brand,
    csv_content: csvContent,
  }

  return post('bulk_create', body)
}

function post(action, body) {
  const url = `${process.env.REACT_APP_LOGISTICS_API_URL}/shipment_options/${action}`
  const logisticsToken = localStorage.getItem('logisticsToken')

  return superagent
    .post(url)
    .send(body)
    .set('Authorization', `Bearer ${logisticsToken}`)
    .set('Content-Type', 'application/json')
    .set('X-Issuer', 'ziplog')
}

function deleteRequest(action, body) {
  const url = `${process.env.REACT_APP_LOGISTICS_API_URL}/shipment_options/${action}`
  const logisticsToken = localStorage.getItem('logisticsToken')

  return superagent
    .delete(url)
    .send(body)
    .set('Authorization', `Bearer ${logisticsToken}`)
    .set('Content-Type', 'application/json')
    .set('X-Issuer', 'ziplog')
}
