import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import GraphqlPostButton from 'components/shared/graphqlPostButton/graphqlPostButton'
import { SHIPPING_LABELS } from 'actions/context'

export function ShippingLabelsPostButton(props) {
  const hasMissingFields = () => {
    const { continent, country, brand, deliveryDateRange } = props

    return !continent || !country || !brand || !deliveryDateRange[0] || !deliveryDateRange[1]
  }

  const gaParams = () => {
    return JSON.stringify(generateGraphqlApiData())
  }

  const generateGraphqlApiData = () => {
    return {
      continent: props.continent,
      country: props.country,
      brand: props.brand,
      deliveryDateRange: props.deliveryDateRange,
    }
  }

  return (
    <GraphqlPostButton
      data={generateGraphqlApiData()}
      featureFlags={props.featureFlags}
      disabled={hasMissingFields()}
      context={SHIPPING_LABELS}
      gaLabel={gaParams()}
    />
  )
}

ShippingLabelsPostButton.propTypes = {
  brand: PropTypes.string,
  continent: PropTypes.string,
  country: PropTypes.string,
  deliveryDateRange: PropTypes.array,
  featureFlags: PropTypes.array,
}

function mapStateToProps(state) {
  return {
    brand: state.globalFilters.brands,
    continent: state.shippingLabels.continent,
    country: state.shippingLabels.country,
    deliveryDateRange: state.globalFilters.deliveryDateRange,
  }
}

const connectedShippingLabelsButton = connect(mapStateToProps)(ShippingLabelsPostButton)

export default connectedShippingLabelsButton
