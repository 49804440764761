import React from 'react'
import PropTypes from 'prop-types'

import './ziplogLink.css'

export default function ZiplogLink(props) {
  const target = props.openInNewTab ? '_blank' : ''
  const classNames = ['ziplog-link', props.className].join(' ')

  return (
    <a href={props.href} target={target} rel='noopener noreferrer' className={classNames}>
      {props.text || props.children}
    </a>
  )
}

ZiplogLink.propTypes = {
  href: PropTypes.string.isRequired,
  text: PropTypes.string,
  openInNewTab: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.array,
}
