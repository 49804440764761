import { takeLatest } from 'redux-saga/effects'
import { fetchDeliveryOptions } from './deliveryOptions'
import { fetchDeliveryDateExceptions } from './deliveryDateExceptions'
import { fetchDeliveryExceptionsPermissions } from './deliveryExceptionsPermissions'
import { fetchDeliveryPromises } from './deliveryPromises'
import { fetchShippingLabels } from './shippingLabels'
import { fetchAuditLogs } from './auditLogs'
import { fetchDeliveryTracking } from './deliveryTracking'
import { fetchTrackedShippers } from './trackedShippers'
import { fetchShipmentOptions } from './shipmentOptions'
import { fetchShipAddress } from './shipAddress'
import { fetchRoles } from './roles'

import {
  AUDIT_LOGS_FETCH_REQUESTED,
  DELIVERY_DATE_EXCEPTIONS_FETCH_REQUESTED,
  DELIVERY_OPTIONS_FETCH_REQUESTED,
  DELIVERY_PROMISES_FETCH_REQUESTED,
  SHIP_ADDRESS_FETCH_REQUESTED,
  SHIPPING_LABELS_FETCH_REQUESTED,
  SHIPPING_LABELS_MISSING_LABELS_FETCH_REQUESTED,
  DELIVERY_TRACKING_FETCH_REQUESTED,
  TRACKED_SHIPPERS_FETCH_REQUESTED,
  SHIPMENT_OPTIONS_FETCH_REQUESTED,
  DELIVERY_EXCEPTIONS_PERMISSIONS_FETCH_REQUESTED,
  ROLES_FETCH_REQUESTED,
} from 'actions/types'

export const fetchGraphqlSagas = [
  takeLatest(AUDIT_LOGS_FETCH_REQUESTED, fetchAuditLogs),
  takeLatest(DELIVERY_DATE_EXCEPTIONS_FETCH_REQUESTED, fetchDeliveryDateExceptions),
  takeLatest(DELIVERY_EXCEPTIONS_PERMISSIONS_FETCH_REQUESTED, fetchDeliveryExceptionsPermissions),
  takeLatest(DELIVERY_PROMISES_FETCH_REQUESTED, fetchDeliveryPromises),
  takeLatest(DELIVERY_OPTIONS_FETCH_REQUESTED, fetchDeliveryOptions),
  takeLatest(SHIPPING_LABELS_FETCH_REQUESTED, fetchShippingLabels),
  takeLatest(SHIPPING_LABELS_MISSING_LABELS_FETCH_REQUESTED, fetchShippingLabels),
  takeLatest(DELIVERY_TRACKING_FETCH_REQUESTED, fetchDeliveryTracking),
  takeLatest(TRACKED_SHIPPERS_FETCH_REQUESTED, fetchTrackedShippers),
  takeLatest(SHIPMENT_OPTIONS_FETCH_REQUESTED, fetchShipmentOptions),
  takeLatest(ROLES_FETCH_REQUESTED, fetchRoles),
  takeLatest(SHIP_ADDRESS_FETCH_REQUESTED, fetchShipAddress),
]
