import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import AppConfig from 'config'
import Filter from 'components/shared/filter/filter'
import { setFilter } from 'actions/shippingLabels'

export const ContinentFilter = props => {
  const handleChange = e => {
    props.dispatch(setFilter('continent', e.target.value))
    props.dispatch(setFilter('country', 'All'))
  }

  return (
    <Filter
      name='continent'
      displayName='continent'
      displayUpcase
      onChange={handleChange}
      data={AppConfig.continents}
      selectedValue={props.continent}
      defaultValue=''
      defaultText='Please select'
      labelText='Continent'
    />
  )
}

ContinentFilter.propTypes = {
  dispatch: PropTypes.func.isRequired,
  continent: PropTypes.string,
}

function mapStateToProps(state) {
  return {
    continent: state.shippingLabels.continent,
  }
}

const connectedContinentFilter = connect(mapStateToProps)(ContinentFilter)

export default connectedContinentFilter
