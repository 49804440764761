import React from 'react'
import PropTypes from 'prop-types'

import { sortByWeekday } from 'helpers/helpers'
import Filter from 'components/shared/filter/filter'

export default function DeliveryDaysFilter(props) {
  const sortedDeliveryDays = sortByWeekday(props.deliveryDays)

  return (
    <Filter
      name='deliveryDay'
      displayName='Delivery Day'
      defaultText='All delivery days'
      onChange={props.onChange}
      data={sortedDeliveryDays}
      sortOptions={false}
    />
  )
}

DeliveryDaysFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  deliveryDays: PropTypes.array.isRequired,
}
