import moment from 'moment'

// This could also live in dateHelper.js
// But the 'moment-range' library used there adds extra overhead that the methods here don't need

export const defaultUIDateSetter = date => {
  return date ? moment(date).toDate() : moment().toDate()
}

export const UIDateFormat = date => (date ? moment(date).format('YYYY-MM-DD') : null)
