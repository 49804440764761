import {
  DELIVERY_EXCEPTIONS_PERMISSIONS_FETCH_REQUESTED,
  DELIVERY_EXCEPTIONS_PERMISSIONS_FETCH_SUCCEEDED,
  DELIVERY_EXCEPTIONS_PERMISSIONS_FETCH_FAILED,
} from 'actions/types'

const initialState = {
  isLoading: false,
  data: {},
}

const deliveryExceptionsPermissions = (state = initialState, action) => {
  switch (action.type) {
    case DELIVERY_EXCEPTIONS_PERMISSIONS_FETCH_REQUESTED:
      return {
        ...state,
        isLoading: true,
        data: {},
      }
    case DELIVERY_EXCEPTIONS_PERMISSIONS_FETCH_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: action.data,
      }
    case DELIVERY_EXCEPTIONS_PERMISSIONS_FETCH_FAILED:
      return initialState
    default:
      return state
  }
}

export default deliveryExceptionsPermissions
