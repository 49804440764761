import React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import PropTypes from 'prop-types'
import ZiplogLink from 'components/shared/ziplogLink/ziplogLink'

import './zplLabel.css'

export const ZplLabel = ({ imageText }) => {
  const [copied, setCopied] = React.useState(false)

  return (
    <div className='zpl-label__wrapper'>
      <p className='zpl-label__infotext'>
        To preview the label (and download as png), copy the info, follow the link below and paste
        the code there.
      </p>

      <textarea className='zpl-label__content'>{imageText}</textarea>

      <CopyToClipboard text={imageText} onCopy={() => setCopied(true)}>
        <button className='zpl-label__copy-button'>
          {copied ? 'Copied' : 'Copy to clipboard'}
        </button>
      </CopyToClipboard>

      <ZiplogLink
        href={'http://labelary.com/viewer.html'}
        className='zpl-label__labelary-link'
        text='Paste text and preview label here'
        openInNewTab
      />
    </div>
  )
}

ZplLabel.propTypes = {
  imageText: PropTypes.string,
}

export default ZplLabel
