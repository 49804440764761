import {
  SHIPPING_LABELS_FETCH_FAILED,
  SHIPPING_LABELS_FETCH_REQUESTED,
  SHIPPING_LABELS_FETCH_SUCCEEDED,
  SHIPPING_LABELS_MISSING_LABELS_FETCH_REQUESTED,
  SHIPPING_LABELS_MISSING_LABELS_FETCH_SUCCEEDED,
  SHIPPING_LABELS_MISSING_LABELS_FETCH_FAILED,
  SHIPPING_LABELS_UPDATE_FILTERS,
} from 'actions/types'
import { STATUSES } from 'helpers/constants'

const initialState = {
  status: STATUSES.initial,
  data: null,
  missingLabelsData: [],
  country: 'All',
  countries: [],
  continent: '',
}

const shippingLabels = (state = initialState, action) => {
  switch (action.type) {
    case SHIPPING_LABELS_FETCH_REQUESTED:
      return {
        ...state,
        status: STATUSES.requested,
        data: null,
      }
    case SHIPPING_LABELS_FETCH_SUCCEEDED:
      return {
        ...state,
        status: STATUSES.resolved,
        data: action.data,
      }
    case SHIPPING_LABELS_FETCH_FAILED:
      return {
        ...state,
        data: null,
        status: STATUSES.failed,
      }
    case SHIPPING_LABELS_MISSING_LABELS_FETCH_REQUESTED:
      return {
        ...state,
        status: STATUSES.requested,
        missingLabelsData: [],
      }
    case SHIPPING_LABELS_MISSING_LABELS_FETCH_SUCCEEDED:
      return {
        ...state,
        status: STATUSES.resolved,
        missingLabelsData: action.data,
      }
    case SHIPPING_LABELS_MISSING_LABELS_FETCH_FAILED:
      return {
        ...state,
        missingLabelsData: [],
        status: STATUSES.failed,
      }
    case SHIPPING_LABELS_UPDATE_FILTERS:
      return {
        ...state,
        [action.key]: action.value,
      }
    default:
      return state
  }
}

export default shippingLabels
