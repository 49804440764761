import React from 'react'
import PropTypes from 'prop-types'

import './infoMessage.css'

export default function InfoMessage(props) {
  const message = props.message
  const classNames = ['info-message', props.className].join(' ')

  if (message) {
    const html = { __html: message }
    return <p className={classNames} dangerouslySetInnerHTML={html} />
  }

  return ''
}

InfoMessage.propTypes = {
  message: PropTypes.string.isRequired,
  className: PropTypes.string,
}
