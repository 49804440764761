import 'styles/vendor/skeleton/skeleton.css'
import 'styles/global/mui-reset.css'

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { store, persistedStore } from './store'
import { PersistGate } from 'redux-persist/integration/react'

import App from 'components/app'

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistedStore}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
)
