import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import CountryFilter from 'components/shared/countryFilter/countryFilter'
import CsvUploader from 'components/shared/csvUploader/csvUploader'
import BrandFilter from 'components/shared/brandFilter/brandFilter'
import InfoMessage from 'components/shared/infoMessage/infoMessage'
import ZiplogLink from 'components/shared/ziplogLink/ziplogLink'

import PauseError from './pauseError/pauseError'

import { showModal } from 'actions/modal'
import { DELIVERY_OPTIONS_PAUSE_PREVIEW_MODAL } from 'components/modal/modalTypes'

import './deliveryOptionsPause.css'
import {
  pauseDeliveryOptionsCsvUpload,
  pauseDeliveryOptionsErrorClear,
} from 'actions/deliveryOptions'

const INFO_MESSAGE_TEXT =
  'Delivery Options will be paused in the selected period. ' +
  'Automatically created orders will be canceled for the period.'
const REGION_TEMPLATE_LINK =
  'https://docs.google.com/spreadsheets/d/1a-1RRjO-tMrC1xf8dvPjzCzqzMg4KINrl2msRAoM9DQ'

export class DeliveryOptionsPause extends React.Component {
  openPreviewModal = () => {
    this.props.dispatch(showModal(DELIVERY_OPTIONS_PAUSE_PREVIEW_MODAL))
  }

  onFileUpload = csvData => {
    this.props.dispatch(pauseDeliveryOptionsCsvUpload(csvData))
  }

  isBtnDisabled() {
    const { country, brand, csvContent } = this.props
    return !country || !brand || !csvContent
  }

  componentWillUnmount() {
    this.props.dispatch(pauseDeliveryOptionsErrorClear())
  }

  render() {
    if (this.props.isLoading) {
      return <div className='delivery-options-pause__loader'>Loading...</div>
    } else if (this.props.canManage) {
      return (
        <div className='delivery-options-pause'>
          <CountryFilter countries={this.props.countriesAllowedToManage} />
          <BrandFilter />
          <InfoMessage message={INFO_MESSAGE_TEXT} />

          <CsvUploader onFileUpload={this.onFileUpload} />

          <ZiplogLink
            href={REGION_TEMPLATE_LINK}
            className='delivery-options-pause__ziplog-link'
            text='CSV Template by Region'
            openInNewTab
          />

          <button
            className='delivery-options-pause__save-button button-primary'
            disabled={this.isBtnDisabled() || !this.props.isSuperUser}
            onClick={this.openPreviewModal}
          >
            Save
          </button>

          <br />

          <PauseError errors={this.props.errors} />
        </div>
      )
    }

    return null
  }
}

DeliveryOptionsPause.propTypes = {
  dispatch: PropTypes.func.isRequired,
  country: PropTypes.string,
  brand: PropTypes.string,
  csvContent: PropTypes.string,
  isLoading: PropTypes.bool,
  errors: PropTypes.array,
  canManage: PropTypes.bool.isRequired,
  countriesAllowedToManage: PropTypes.array.isRequired,
  isSuperUser: PropTypes.bool,
}

function mapStateToProps(state) {
  return {
    country: state.globalFilters.country,
    brand: state.globalFilters.brand,
    csvContent: state.deliveryOptionsPause.csvContent,
    isLoading: state.deliveryOptionsPause.isLoading,
    errors: state.deliveryOptionsPause.errors,
    canManage: state.currentUser.canManage,
    countriesAllowedToManage: state.currentUser.countriesAllowedToManage,
    isSuperUser: state.currentUser.roles.includes('superuser'),
  }
}

const connectedDeliveryOptionsPause = connect(mapStateToProps)(DeliveryOptionsPause)

export default connectedDeliveryOptionsPause
