import React from 'react'
import PropTypes from 'prop-types'
import authenticationApi from 'apis/authentication.api'
import './userinfo.css'

export class UserInfo extends React.Component {
  render() {
    return (
      <div className='user-info'>
        <span className='user-info__email'>{this.props.email}</span>
        <div className='user-info__dropdown'>
          <a className='user-info__link' onClick={authenticationApi.logout}>
            Logout
          </a>
        </div>
      </div>
    )
  }
}

UserInfo.propTypes = {
  email: PropTypes.string,
}

export default UserInfo
