import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase, startCase, isNil } from 'lodash'

import BaseTable from 'components/shared/baseTable/baseTable'

const ZiplogTable = props => {
  const renderHead = () => {
    return props.headers.map(columnName => getColumnDisplayName(columnName))
  }

  const getColumnDisplayName = columnName => {
    const columnRenames = props.columnRenames

    return columnRenames && Object.prototype.hasOwnProperty.call(columnRenames, columnName)
      ? columnRenames[columnName]
      : startCase(columnName)
  }

  const renderRows = () => {
    if (!props.data) {
      return []
    }

    return props.data.map((tableRecord, index) => (
      <tr className={`${props.tableName}__table-row`} key={index}>
        {renderColumns(tableRecord)}
      </tr>
    ))
  }

  const renderColumns = rowData => {
    return props.headers.map(columnName => {
      const value = rowData[columnName]
      const tableName = props.tableName

      return (
        <td
          key={`${columnName}-${value}`}
          className={`${tableName}__row-column ${tableName}__${kebabCase(columnName)}`}
        >
          {isNil(value) ? 'N/A' : value}
        </td>
      )
    })
  }

  return (
    <BaseTable headers={renderHead()} tableName={props.tableName}>
      {renderRows()}
    </BaseTable>
  )
}

ZiplogTable.defaultProps = {
  tableName: 'ziplog-table',
  data: [],
  columnRenames: {},
}

ZiplogTable.propTypes = {
  tableName: PropTypes.string,
  headers: PropTypes.array.isRequired,
  data: PropTypes.array,
  columnRenames: PropTypes.object,
}

export default ZiplogTable
