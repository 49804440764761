import { call, put } from 'redux-saga/effects'
import { graphqlApi } from 'apis/graphql.api'
import authenticationApi from 'apis/authentication.api'

import { ROLES_FETCH_SUCCEEDED, ROLES_FETCH_FAILED } from 'actions/types'

export function* fetchRoles(action) {
  try {
    const response = yield call(graphqlApi.postRoles)

    graphqlApi.handleGraphqlErrors(response)
    yield put({
      type: ROLES_FETCH_SUCCEEDED,
      data: response.body.data.roles,
    })
  } catch (error) {
    const failureAction = {
      type: ROLES_FETCH_FAILED,
    }

    authenticationApi.handleError(error, action, failureAction)
    yield put(failureAction)
  }
}
