import React, { useState } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import moment from 'moment'
import PropTypes from 'prop-types'
import { enGB } from 'date-fns/esm/locale'
import { openErrorAlert } from 'actions/alert/alert'

registerLocale('en-GB', enGB)

const DateRangePicker = props => {
  const {
    deliveryDateRange,
    defaultDateSetter,
    onDateRangeSelect,
    formatDate,
    dispatch,
    minDate,
    maxDate,
  } = props

  const [startDate, setStartDate] = useState(defaultDateSetter(deliveryDateRange?.[0]))
  const [endDate, setEndDate] = useState(defaultDateSetter(deliveryDateRange?.[1]))

  const validateRangeSelection = (startDate, endDate) => {
    if (!startDate || !endDate) {
      // only bother with the rest of the validation if both are present
      return { status: 'pending' }
    }
    if (startDate > endDate) {
      return {
        status: 'invalid',
        message: 'Please, select a valid Start Date and End Date',
      }
    }
    let dateRangeDifferenceInDays = moment(endDate).diff(moment(startDate), 'days')

    if (dateRangeDifferenceInDays > 30) {
      return {
        status: 'invalid',
        message: 'Please, limit date range to 30 days',
      }
    }
    return { status: 'valid' }
  }

  const displayAlertMessage = message => dispatch(openErrorAlert(message))

  const updateSelectedDate = dates => {
    const [start, end] = dates
    const validation = validateRangeSelection(start, end)

    if (validation.status === 'invalid') {
      displayAlertMessage(validation.message)
      return false
    }
    setStartDate(start)
    setEndDate(end)

    onDateRangeSelect([formatDate(start), formatDate(end)])
  }

  // TO-DO
  // Fix bug where date-range picker does not get set properly when its state is updated
  // dynamically e.g using date values from URL query strings
  return (
    <DatePicker
      name='selectedDateRange'
      selected={startDate}
      onChange={updateSelectedDate}
      startDate={startDate}
      endDate={endDate}
      dateFormat='MMMM d, yyyy'
      monthsShown={2}
      showWeekNumbers
      selectsRange
      locale='en-GB'
      minDate={minDate}
      maxDate={maxDate}
      className='ziplog-datepicker__date-range'
    />
  )
}

DateRangePicker.propTypes = {
  dispatch: PropTypes.func.isRequired,
  defaultDateSetter: PropTypes.func.isRequired,
  formatDate: PropTypes.func.isRequired,
  onDateRangeSelect: PropTypes.func.isRequired,
  deliveryDateRange: PropTypes.array,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
}

export default DateRangePicker
