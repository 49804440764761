import React from 'react'
import PropTypes from 'prop-types'
import { capitalize } from 'lodash'
import './filter.css'

const Filter = props => {
  const sortedData = props.sortOptions ? props.data.sort() : props.data
  const displayName = props.displayName || props.name
  const labelText = props.labelText

  const renderOptions = () => {
    const optionsHtml = []

    if (props.defaultText) {
      optionsHtml.push(buildDefaultOption())
    }

    sortedData.forEach(option => {
      if (option === null || option === '') {
        optionsHtml.push(<option key={'null'} value={'null'}>{`No ${displayName}`}</option>)
      } else {
        optionsHtml.push(
          <option key={option} value={option}>
            {optionDisplayValue(option)}
          </option>,
        )
      }
    })

    return optionsHtml
  }

  const optionDisplayValue = option => {
    if (props.displayUpcase) {
      return option.toUpperCase()
    }

    if (props.displayCapitalized) {
      return capitalize(option)
    }

    if (props.customDisplay) {
      return props.customDisplay(option)
    }

    return option
  }

  const buildDefaultOption = () => {
    return (
      <option className='filter__default-option' key='default-option' value={props.defaultValue}>
        {props.defaultText}
      </option>
    )
  }

  if (props.hidden) {
    return null
  }

  return (
    <div className='filter'>
      {labelText ? <label className='filter__label'>{labelText}</label> : null}

      <select
        className='filter__select'
        name={props.name}
        onChange={props.onChange}
        value={props.selectedValue}
        disabled={props.disabled}
      >
        {renderOptions()}
      </select>
    </div>
  )
}

Filter.defaultProps = {
  hidden: false,
  sortOptions: true,
  defaultValue: 'all',
}

Filter.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
  displayName: PropTypes.string,
  displayUpcase: PropTypes.bool,
  displayCapitalized: PropTypes.bool,
  customDisplay: PropTypes.func,
  selectedValue: PropTypes.string,
  defaultText: PropTypes.string,
  sortOptions: PropTypes.bool,
  labelText: PropTypes.string,
  defaultValue: PropTypes.string,
}

export default Filter
