import {
  DELIVERY_DATE_EXCEPTIONS_CLEAR_NEW,
  DELIVERY_DATE_EXCEPTIONS_CREATE_REQUESTED,
  DELIVERY_DATE_EXCEPTIONS_DELETE_REQUESTED,
  DELIVERY_DATE_EXCEPTIONS_SELECT_NEW,
} from './types'

const createKey = data => {
  const identifiers = [
    data.region,
    data.suburb,
    data.transitTime,
    data.regularSlot.shipperName,
    data.regularSlot.from,
    data.regularSlot.to,
  ]

  return identifiers.join('__')
}

export const newExceptionSelect = (
  newDate,
  newShipmentDate,
  newCutoffDate,
  newSlot,
  newType,
  data,
) => {
  const newExceptionKey = createKey(data)

  const newExceptionData = {}
  newExceptionData[newExceptionKey] = {
    ...data,
    exceptionalDeliveryDate: newDate,
    exceptionalShipmentDate: newShipmentDate,
    exceptionalCutoffDate: newCutoffDate,
    exceptionalSlot: newSlot,
    exceptionType: newType,
    errors: !newDate || !newSlot || !newType,
  }

  return {
    type: DELIVERY_DATE_EXCEPTIONS_SELECT_NEW,
    data: newExceptionData,
  }
}

export const newExceptionClear = data => {
  const newExceptionKey = createKey(data)

  return {
    type: DELIVERY_DATE_EXCEPTIONS_CLEAR_NEW,
    key: newExceptionKey,
  }
}

export const createExceptions = (country, brand, orderType, regularDeliveryDate, data) => {
  return {
    type: DELIVERY_DATE_EXCEPTIONS_CREATE_REQUESTED,
    country: country,
    brand: brand,
    orderType: orderType,
    regularDeliveryDate: regularDeliveryDate,
    data: Object.values(data),
  }
}

export const deleteExceptions = (country, brand, orderType, regularDeliveryDate, data) => {
  return {
    type: DELIVERY_DATE_EXCEPTIONS_DELETE_REQUESTED,
    country: country,
    brand: brand,
    orderType: orderType,
    regularDeliveryDate: regularDeliveryDate,
    data: Object.values(data),
  }
}
