import { combineReducers } from 'redux'

import auditLogs from './auditLogs'
import currentUser from './currentUser'
import deliveryDateExceptions from './deliveryDateExceptions'
import deliveryExceptionsPermissions from './deliveryExceptionsPermissions'
import deliveryOptions from './deliveryOptions'
import deliveryOptionsCSV from './deliveryOptionsCSV'
import deliveryOptionsNew from './deliveryOptionsNew'
import deliveryOptionsUpdate from './deliveryOptionsUpdate'
import dynamicOptionsUpdate from './dynamicOptionsUpdate'
import deliveryOptionsPause from './deliveryOptionsPause'
import deliveryTracking from './deliveryTracking'
import deliveryPromises from './deliveryPromises'
import deliveryPromisesCSV from './deliveryPromisesCSV'
import featureFlags from './featureFlags'
import globalFilters from './globalFilters'
import mappings from './mappings'
import modal from './modal'
import roles from './roles'
import shipAddress from './shipAddress'
import shipmentOptions from './shipmentOptions'
import shippingCsvs from './shippingCsvs'
import shippingLabels from './shippingLabels'
import trackingCsvs from './trackingCsvs'
import trackingActivitiesCsvs from './trackingActivitiesCsvs'
import zipcodesNew from './zipcodesNew'
import zipcodesUpdate from './zipcodesUpdate'
import orderMovement from './orderMovement'
import failedOrderMovements from './failedOrderMovements'
import alert from './alert'

const reducers = combineReducers({
  auditLogs,
  currentUser,
  deliveryDateExceptions,
  deliveryExceptionsPermissions,
  deliveryOptions,
  deliveryOptionsCSV,
  deliveryOptionsNew,
  deliveryOptionsUpdate,
  deliveryOptionsPause,
  deliveryTracking,
  deliveryPromises,
  deliveryPromisesCSV,
  featureFlags,
  globalFilters,
  mappings,
  modal,
  roles,
  shipAddress,
  shipmentOptions,
  shippingCsvs,
  shippingLabels,
  trackingActivitiesCsvs,
  trackingCsvs,
  zipcodesNew,
  zipcodesUpdate,
  orderMovement,
  failedOrderMovements,
  alert,
  dynamicOptionsUpdate,
})

export default reducers
