import { call, put, takeLatest } from 'redux-saga/effects'
import authenticationApi from 'apis/authentication.api'
import { zipcodesApi } from 'apis/zipcodes.api'
import {
  ZIPCODES_CREATE_SUCCEEDED,
  ZIPCODES_CREATE_FAILED,
  ZIPCODES_CREATE_REQUESTED,
} from 'actions/types'
import { openSuccessAlert } from 'actions/alert/alert'

export function* createZipcodes(action) {
  try {
    const response = yield call(
      zipcodesApi.createZipcodes,
      action.country,
      action.brand,
      action.csvContent,
    )

    yield call(handleSuccess, response)
  } catch (error) {
    const failureAction = { type: ZIPCODES_CREATE_FAILED }

    authenticationApi.handleError(error, action, failureAction)
    yield put(failureAction)
  }
}

function* handleSuccess(response) {
  const msg = response.body.message || 'Successfully created zipcodes'

  yield put(openSuccessAlert(msg))
  yield put({ type: ZIPCODES_CREATE_SUCCEEDED })
}

export const createZipcodesSagas = [takeLatest(ZIPCODES_CREATE_REQUESTED, createZipcodes)]
