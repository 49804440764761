import { call, put, takeLatest } from 'redux-saga/effects'
import authenticationApi from 'apis/authentication.api'
import { deliveryOptionsApi } from 'apis/deliveryOptions.api'
import {
  DELIVERY_OPTIONS_CREATE_SUCCEEDED,
  DELIVERY_OPTIONS_CREATE_FAILED,
  DELIVERY_OPTIONS_CREATE_REQUESTED,
} from 'actions/types'
import { openSuccessAlert, openErrorAlert } from 'actions/alert/alert'

export function* createDeliveryOptions(action) {
  try {
    const response = yield call(
      deliveryOptionsApi.createDeliveryOptions,
      action.country,
      action.brand,
      action.csvContent,
    )

    yield call(handleSuccess, response)
  } catch (error) {
    authenticationApi.handleError(error, action, deliveryOptionsFailedAction)

    yield put(deliveryOptionsFailedAction)
  }
}

function* handleSuccess(response) {
  let message = response.body.message || ''

  if (response.body.errors) {
    message += `\nErrors: ${response.body.errors.map(error => error.message)}`

    yield put(openErrorAlert(message))
    yield put(deliveryOptionsFailedAction)
  } else {
    yield put(openSuccessAlert(message))
    yield put(deliveryOptionsSuccessAction)
  }
}

const deliveryOptionsFailedAction = { type: DELIVERY_OPTIONS_CREATE_FAILED }
const deliveryOptionsSuccessAction = {
  type: DELIVERY_OPTIONS_CREATE_SUCCEEDED,
}

export const createDeliveryOptionsSagas = [
  takeLatest(DELIVERY_OPTIONS_CREATE_REQUESTED, createDeliveryOptions),
]
