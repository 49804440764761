import React from 'react'
import PropTypes from 'prop-types'

import './typeSelect.css'

const TypeSelect = props => {
  const onSelectChange = e => {
    const newType = e.target.value

    props.onSelect(e.target.name, newType)
  }

  return (
    <select
      className='type-select'
      name='newType'
      onChange={onSelectChange}
      value={props.type}
      disabled={!props.enabled}
    >
      <option key='holiday' value='holiday'>
        holiday
      </option>
      <option key='incident' value='incident'>
        incident
      </option>
    </select>
  )
}

TypeSelect.propTypes = {
  type: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  enabled: PropTypes.bool.isRequired,
}

export default TypeSelect
