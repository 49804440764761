import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import CountryFilter from 'components/shared/countryFilter/countryFilter'
import CsvUploader from 'components/shared/csvUploader/csvUploader'
import BrandFilter from 'components/shared/brandFilter/brandFilter'
import InfoMessage from 'components/shared/infoMessage/infoMessage'
import ZiplogLink from 'components/shared/ziplogLink/ziplogLink'
import FirstDeliveryWeekPicker from '../deliveryOptionsUpdate/firstDeliveryWeekPicker/firstDeliveryWeekPicker'
import FlashMessage from '../deliveryOptionsUpdate/flashMessage/flashMessage'

import Toggle from 'react-toggle'
import 'react-toggle/style.css'

import './dynamicOptionsUpdate.css'
import { updateDynamicOptionsCsvUpload } from 'actions/dynamicOptions'

import { showModal } from 'actions/modal'
import { DYNAMIC_OPTIONS_UPDATE_PREVIEW_MODAL } from 'components/modal/modalTypes'

const INFO_MESSAGE_TEXT =
  'Updating either Transit Time or Production Site would move the orders that were  ' +
  'associated with the previous values. Old delivery options will no longer be available.'
const TEMPLATE_LINK =
  'https://docs.google.com/spreadsheets/d/1rTHzt9rVBlrEPdWuk7CpgVf64a18ECTOIIMpHyTiTQA'

export class DynamicOptionsUpdate extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedFirstDeliveryWeek: null,
      createNewIfNotExist: false,
      showMessage: false,
    }
  }

  componentDidMount() {
    this.setState({ showMessage: false, createNewIfNotExist: false })
  }

  componentDidUpdate(prevProps) {
    if (this.props.csvContent !== prevProps.csvContent) {
      this.setState({ selectedFirstDeliveryWeek: null })
    }

    if (this.props.responseBody !== prevProps.responseBody) {
      this.setState({ showMessage: !!this.props.responseBody })
    }
  }

  hideMessage = () => {
    this.setState({ showMessage: false })
  }

  setFirstDeliveryWeek = selectedFirstDeliveryWeek => {
    this.setState({ selectedFirstDeliveryWeek })
  }

  setCreateNewIfNotExist = () => {
    this.setState(prevState => {
      return { createNewIfNotExist: !prevState.createNewIfNotExist }
    })
  }

  onFileUpload = csvData => {
    this.props.dispatch(updateDynamicOptionsCsvUpload(csvData))
  }

  openPreviewModal = () => {
    this.props.dispatch(
      showModal(DYNAMIC_OPTIONS_UPDATE_PREVIEW_MODAL, {
        firstDeliveryWeek: this.state.selectedFirstDeliveryWeek,
        createNewIfNotExist: this.state.createNewIfNotExist,
      }),
    )
  }

  isBtnDisabled() {
    const { country, brand, csvContent } = this.props
    return !country || !brand || !csvContent || !this.state.selectedFirstDeliveryWeek
  }

  render() {
    if (this.props.isLoading) {
      return <div className='dynamic-options-update__loader'>Loading...</div>
    } else if (this.props.canManage) {
      return (
        <div className='dynamic-options-update'>
          <FlashMessage
            showMessage={this.state.showMessage}
            message={(this.props.responseBody && this.props.responseBody.message) || ''}
            errors={(this.props.responseBody && this.props.responseBody.errors) || []}
            onClose={this.hideMessage}
          />

          <CountryFilter countries={this.props.countriesAllowedToManage} />
          <BrandFilter />
          <InfoMessage message={INFO_MESSAGE_TEXT} />

          <CsvUploader onFileUpload={this.onFileUpload} />

          <ZiplogLink
            href={TEMPLATE_LINK}
            className='dynamic-options-update__ziplog-link'
            text='CSV Template'
            openInNewTab
          />

          <FirstDeliveryWeekPicker
            selectedFirstDeliveryWeek={this.state.selectedFirstDeliveryWeek}
            onChange={this.setFirstDeliveryWeek}
          />

          <div className='create-new-do'>
            <label className='create-new-toggle-label' htmlFor='cheese-status'>
              Create new delivery option if it does not exist
            </label>

            <Toggle
              className='create-new-toggle'
              defaultChecked={this.state.createNewIfNotExist}
              onChange={this.setCreateNewIfNotExist}
            />
          </div>

          <button
            className='dynamic-options-update__save-button button-primary'
            disabled={this.isBtnDisabled()}
            onClick={this.openPreviewModal}
          >
            Save
          </button>
        </div>
      )
    }

    return null
  }
}

DynamicOptionsUpdate.propTypes = {
  dispatch: PropTypes.func.isRequired,
  country: PropTypes.string,
  brand: PropTypes.string,
  csvContent: PropTypes.string,
  isLoading: PropTypes.bool,
  canManage: PropTypes.bool.isRequired,
  countriesAllowedToManage: PropTypes.array.isRequired,
  responseBody: PropTypes.object,
}

function mapStateToProps(state) {
  return {
    country: state.globalFilters.country,
    brand: state.globalFilters.brand,
    csvContent: state.dynamicOptionsUpdate.csvContent,
    isLoading: state.dynamicOptionsUpdate.isLoading,
    responseBody: state.dynamicOptionsUpdate.responseBody,
    canManage: state.currentUser.canManage,
    countriesAllowedToManage: state.currentUser.countriesAllowedToManage,
  }
}

const connectedDynamicOptionsUpdate = connect(mapStateToProps)(DynamicOptionsUpdate)

export default connectedDynamicOptionsUpdate
