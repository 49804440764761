import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { SHIPPING_LABELS } from 'actions/context'

export const LostLabelsPostButton = props => {
  const hasMissingFields = () => {
    const { countries, brand, deliveryDateRange } = props.graphqlApiData

    return countries.length === 0 || !brand || !deliveryDateRange[0] || !deliveryDateRange[1]
  }

  const onClick = () => {
    props.dispatch({
      type: `[${SHIPPING_LABELS}]_MISSING_LABELS_FETCH_REQUESTED`,
      data: props.graphqlApiData,
    })
  }

  return (
    <button
      className='graphql-post-button button-primary'
      disabled={hasMissingFields()}
      onClick={onClick}
    >
      View
    </button>
  )
}

LostLabelsPostButton.propTypes = {
  dispatch: PropTypes.func.isRequired,
  graphqlApiData: PropTypes.object.isRequired,
}

const connectedShippingLabelsButton = connect()(LostLabelsPostButton)

export default connectedShippingLabelsButton
