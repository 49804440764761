import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import BaseModal from 'components/modal/baseModal'
import { hideModal } from 'actions/modal'
import { ShipAddressForm } from 'components/shipAddressForm/shipAddressForm'

export const ShipAddressUpdateModal = props => {
  const closeModal = () => {
    props.dispatch(hideModal())
  }

  React.useEffect(() => {
    const data = { shipmentNumber: props.shipmentNumber }

    props.dispatch({
      type: '[SHIP_ADDRESS]_FETCH_REQUESTED',
      data,
    })
  }, [])

  let form = <div className='ship-address-form-fetch__loader'>Loading...</div>

  if (props.shipAddress) {
    form = (
      <ShipAddressForm
        shipAddress={props.shipAddress}
        dispatch={props.dispatch}
        onClose={closeModal}
      />
    )
  }

  return (
    <BaseModal onClose={closeModal}>
      <h3>Editing shipment #{props.shipmentNumber}</h3>

      <div className='ship-address-form-fetch__container'>{form}</div>
    </BaseModal>
  )
}

ShipAddressUpdateModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  shipmentNumber: PropTypes.string,
  shipAddress: PropTypes.object,
}

const mapStateToProps = state => {
  return {
    shipmentNumber: state.modal.data.shipmentNumber,
    shipAddress: state.shipAddress.data,
  }
}

const connectedShipAddressUpdateModal = connect(mapStateToProps)(ShipAddressUpdateModal)

export default connectedShipAddressUpdateModal
