import React from 'react'
import { CSVLink } from 'react-csv'
import PropTypes from 'prop-types'

import './csvDownloadButton.css'

export default function CSVDownloadButton(props) {
  return (
    <CSVLink
      data={props.data}
      headers={props.headers}
      filename={props.filename}
      className='csv-download-button button button-secondary'
      target='_blank'
    >
      &#10515; {props.text || 'Download CSV'}
    </CSVLink>
  )
}

CSVDownloadButton.propTypes = {
  data: PropTypes.array,
  filename: PropTypes.string,
  headers: PropTypes.array,
  text: PropTypes.string,
}
