import {
  FAILED_ORDER_MOVEMENT_REQUESTED,
  ORDER_MOVEMENT_UPDATE_CSV_UPLOADED,
  ORDER_MOVEMENT_UPDATE_REQUESTED,
} from './types'

export const moveOrderCsvUpload = data => {
  return {
    type: ORDER_MOVEMENT_UPDATE_CSV_UPLOADED,
    csvContent: data,
  }
}
export const moveOrders = (country, data) => {
  return {
    type: ORDER_MOVEMENT_UPDATE_REQUESTED,
    country: country,
    csvContent: data,
  }
}

export const setFilters = (country, movementDate) => {
  return {
    type: FAILED_ORDER_MOVEMENT_REQUESTED,
    country: country,
    movementDate: movementDate,
  }
}
