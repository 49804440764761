import { call, put, takeLatest } from 'redux-saga/effects'
import authenticationApi from 'apis/authentication.api'
import { deliveryOptionsApi } from 'apis/deliveryOptions.api'
import {
  DELIVERY_OPTIONS_PAUSE_SUCCEEDED,
  DELIVERY_OPTIONS_PAUSE_FAILED,
  DELIVERY_OPTIONS_PAUSE_REQUESTED,
} from 'actions/types'
import { openSuccessAlert, openErrorAlert } from 'actions/alert/alert'
import { pauseDeliveryOptionsErrorReturned } from 'actions/deliveryOptions'

export function* pauseDeliveryOptions(action) {
  try {
    const response = yield call(
      deliveryOptionsApi.pauseDeliveryOptions,
      action.country,
      action.brand,
      action.csvContent,
    )

    yield call(handleSuccess, response)
  } catch (error) {
    authenticationApi.handleError(error, action, deliveryOptionsPauseFailedAction)

    yield put(pauseDeliveryOptionsErrorReturned(error?.response?.body?.errors))
  }
}

function* handleSuccess(response) {
  let message = response.body.message || ''

  if (response.body.errors) {
    message += `\nErrors: ${response.body.errors.map(error => error.message)}`

    yield put(openErrorAlert(message))
    yield put(pauseDeliveryOptionsErrorReturned(response?.body?.errors))
  } else {
    yield put(openSuccessAlert(message))
    yield put(deliveryOptionsPauseSuccessAction)
  }
}

const deliveryOptionsPauseFailedAction = {
  type: DELIVERY_OPTIONS_PAUSE_FAILED,
}
const deliveryOptionsPauseSuccessAction = {
  type: DELIVERY_OPTIONS_PAUSE_SUCCEEDED,
}

export const pauseDeliveryOptionsSagas = [
  takeLatest(DELIVERY_OPTIONS_PAUSE_REQUESTED, pauseDeliveryOptions),
]
