import { AUTHENTICATION_LOGGED_OUT, AUTHENTICATION_LOGIN_SUCCEEDED } from 'actions/types'

const initialState = {
  isLoggedIn: false,
  roles: [],
  canManage: false,
  countriesAllowedToManage: [],
  countriesAllowedToView: [],
}

const currentUser = (state = initialState, action) => {
  switch (action.type) {
    case AUTHENTICATION_LOGIN_SUCCEEDED:
      return {
        ...state,
        isLoggedIn: true,
        email: action.payload.token_info.email,
        roles: action.payload.roles,
        canManage: action.payload.can_manage,
        countriesAllowedToManage: action.payload.countries_allowed_to_manage,
        countriesAllowedToView: action.payload.countries_allowed_to_view,
      }
    case AUTHENTICATION_LOGGED_OUT:
      return initialState
    default:
      return state
  }
}

export default currentUser
