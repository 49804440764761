import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import queryString from 'query-string'

import { setFeatureFlags } from 'actions/featureFlags'

export class FeatureFlags extends Component {
  componentDidMount() {
    const parsedURL = this.parseURL()
    const featureFlags = this.formatFlags(parsedURL)

    this.props.dispatch(setFeatureFlags(featureFlags))
  }

  parseURL() {
    const searchString = window.location.search

    return queryString.parse(searchString)['featureFlags']
  }

  formatFlags(string) {
    if (!string) {
      return {}
    }

    return string.split(',').reduce(function (collect, flag) {
      if (flag) {
        collect[flag] = true
      }
      return collect
    }, {})
  }

  render() {
    return ''
  }
}

FeatureFlags.propTypes = {
  dispatch: PropTypes.func.isRequired,
}

export default connect()(FeatureFlags)
