import React from 'react'
import PropTypes from 'prop-types'

import Preview from './preview/preview'
import ValidationErrors from './validationErrors/validationErrors'

import { STATUSES } from 'helpers/constants'

import './csvSummary.css'

const CsvSummary = props => {
  const { summary, status, previewDetails } = props

  if (status === STATUSES.initial) {
    return <div className='csv-summary' />
  }

  if (status === STATUSES.requested) {
    return (
      <div className='csv-summary'>
        <div className='csv-summary__loader'>Loading...</div>
      </div>
    )
  }

  if (status === STATUSES.failed) {
    return (
      <div className='csv-summary'>
        <ValidationErrors
          headers={props.errorHeaders}
          formattedKeys={props.formattedKeys}
          shortFormattedKeys={props.shortFormattedKeys}
          summary={summary}
        />
      </div>
    )
  }

  return (
    <div className='csv-summary'>
      <Preview
        details={previewDetails}
        formattedKeys={props.formattedKeys}
        onAbort={props.onAbort}
        onSubmit={props.onSubmit}
        shortFormattedKeys={props.shortFormattedKeys}
        summary={summary}
      />
    </div>
  )
}

CsvSummary.defaultProps = {
  errorHeaders: [],
  onAbort: () => {},
  onSubmit: () => {},
  status: STATUSES.initial,
}

CsvSummary.propTypes = {
  errorHeaders: PropTypes.array,
  formattedKeys: PropTypes.object.isRequired,
  onAbort: PropTypes.func,
  onSubmit: PropTypes.func,
  shortFormattedKeys: PropTypes.object.isRequired,
  status: PropTypes.number.isRequired,
  summary: PropTypes.object,
  previewDetails: PropTypes.object.isRequired,
}

export default CsvSummary
