import React from 'react'
import PropTypes from 'prop-types'
import { sortBy } from 'lodash'

import ZiplogTable from 'components/shared/ziplogTable/ziplogTable'

import './auditLogsTable.css'

const AuditLogsTable = props => {
  const fields = ['timestamp', 'email', 'action', 'payload']

  const sortedData = () => {
    const auditLogs = props.data || []

    return sortBy(auditLogs, log => -Date.parse(log.timestamp))
  }

  if (props.isLoading) {
    return <div className='audit-logs-table__loader'>Loading...</div>
  }

  return (
    <div>
      {props.data && props.data.length > 0 && (
        <ZiplogTable tableName='audit-logs-table' headers={fields} data={sortedData()} />
      )}
    </div>
  )
}

AuditLogsTable.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      timestamp: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      action: PropTypes.string.isRequired,
      payload: PropTypes.string.isRequired,
    }),
  ),
}

export default AuditLogsTable
