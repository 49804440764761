import React from 'react'
import PropTypes from 'prop-types'
import 'react-datepicker/dist/react-datepicker.css'
import './ziplogDatepicker.css'

import { defaultUIDateSetter, UIDateFormat } from 'helpers/ziplogDatePickerHelper'
import DateRangePicker from './dateRangePicker/dateRangePicker'
import SingleDatePicker from './singleDatePicker/singleDatePicker'

const ZiplogDatepicker = props => {
  return (
    <div className='ziplog-datepicker'>
      {props.labelText ? (
        <label className='ziplog-datepicker__label'>{props.labelText}</label>
      ) : null}

      {props.selectsDateRange ? (
        <DateRangePicker
          deliveryDateRange={props.deliveryDateRange}
          minDate={props.minDate}
          maxDate={props.maxDate}
          onDateRangeSelect={props.onDateRangeSelect}
          defaultDateSetter={defaultUIDateSetter}
          formatDate={UIDateFormat}
        />
      ) : (
        <SingleDatePicker
          defaultDateSetter={defaultUIDateSetter}
          formatDate={UIDateFormat}
          onDateSelect={props.onDateSelect}
          defaultDate={props.defaultDate}
        />
      )}
    </div>
  )
}

ZiplogDatepicker.propTypes = {
  onDateSelect: PropTypes.func,
  onDateRangeSelect: PropTypes.func,
  deliveryDateRange: PropTypes.array,
  selectsDateRange: PropTypes.bool,
  defaultDate: PropTypes.string,
  labelText: PropTypes.string,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
}

export default ZiplogDatepicker
