import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { hideModal } from 'actions/modal'
import { deleteExceptions } from 'actions/deliveryDateExceptions'
import BaseModal from 'components/modal/baseModal'
import ExceptionsPreviewTable from '../exceptionsPreviewTable/exceptionsPreviewTable'

import './exceptionsDeleteModal.css'

export const ExceptionsDeleteModal = props => {
  const onSubmit = () => {
    props.dispatch(
      deleteExceptions(
        props.country,
        props.brand,
        props.orderType,
        props.regularDeliveryDate,
        props.exceptionsToDelete,
      ),
    )

    closeModal()
  }

  const closeModal = () => {
    props.dispatch(hideModal())
  }

  return (
    <BaseModal onClose={closeModal}>
      <div className='exceptions-delete-modal__title'>
        Are you sure you want to delete this exception?
      </div>

      <ExceptionsPreviewTable country={props.country} exceptions={props.exceptionsToDelete} />

      <div className='exceptions-delete-modal__subtitle'>
        {`By clicking 'yes',`}
        <span className='exceptions-delete-modal__order-type'>
          {' '}
          {props.orderType.toUpperCase()}{' '}
        </span>
        orders generated from now on will be delivered on the regular delivery date and slot as
        specified above.
      </div>

      <button className='exceptions-delete-modal__cancel-button' onClick={closeModal}>
        Cancel
      </button>
      <button className='button-primary exceptions-delete-modal__submit-button' onClick={onSubmit}>
        {`Yes, I'm sure`}
      </button>
    </BaseModal>
  )
}

ExceptionsDeleteModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  country: PropTypes.string,
  brand: PropTypes.string,
  orderType: PropTypes.string,
  regularDeliveryDate: PropTypes.string,
  exceptionsToDelete: PropTypes.array,
}

function mapStateToProps(state) {
  return {
    country: state.globalFilters.country,
    brand: state.globalFilters.brand,
    orderType: state.globalFilters.orderType,
    regularDeliveryDate: state.globalFilters.regularDeliveryDate,
    exceptionsToDelete: state.modal.data.exceptionsToDelete,
  }
}

const connectedExceptionsDeleteModal = connect(mapStateToProps)(ExceptionsDeleteModal)

export default connectedExceptionsDeleteModal
