import { exceptionsApi } from 'apis/exceptions.api'
import { call, put, takeLatest } from 'redux-saga/effects'
import authenticationApi from 'apis/authentication.api'
import {
  DELIVERY_DATE_EXCEPTIONS_CREATE_REQUESTED,
  DELIVERY_DATE_EXCEPTIONS_CREATE_ACCEPTED,
  DELIVERY_DATE_EXCEPTIONS_CREATE_FAILED,
} from 'actions/types'
import { openSuccessAlert } from 'actions/alert/alert'

export function* createExceptions(action) {
  try {
    const response = yield call(
      exceptionsApi.createExceptions,
      action.country,
      action.brand,
      action.orderType,
      action.regularDeliveryDate,
      action.data,
    )

    yield call(handleSuccess, response)
  } catch (error) {
    const failureAction = {
      type: DELIVERY_DATE_EXCEPTIONS_CREATE_FAILED,
    }

    authenticationApi.handleError(error, action, failureAction)

    //TODO should we ensure failureaction is always executed in authApi instead? and not double
    // call it
    yield put(failureAction)
  }
}

function* handleSuccess(response) {
  const msg = response.body.message || 'Successfully created exceptions'

  yield put(openSuccessAlert(msg))

  yield put({ type: DELIVERY_DATE_EXCEPTIONS_CREATE_ACCEPTED })
}

export const createExceptionsSagas = [
  takeLatest(DELIVERY_DATE_EXCEPTIONS_CREATE_REQUESTED, createExceptions),
]
