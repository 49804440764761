import React from 'react'
import PropTypes from 'prop-types'
import './deliveryTrackingTable.css'
import ZiplogTable from 'components/shared/ziplogTable/ziplogTable'
import { displayField } from 'helpers/helpers'

function DeliveryTrackingTable(props) {
  const fields = [
    'timestamp',
    'shipperStatus',
    'internalStatus',
    'internalStatusDetails',
    'location',
  ]

  const rowData = () => {
    return props.activities.map(data => ({
      timestamp: displayField(data['timestamp']),
      shipperStatus: displayField(data['shipperStatus']),
      internalStatus: displayField(data['internalStatus']),
      internalStatusDetails: displayField(data['internalStatusDetails']),
      location: displayField(generateAddress(data)),
    }))
  }

  if (!props.activities || props.activities.length === 0) {
    return <div>No shipment tracking activities.</div>
  } else {
    return <ZiplogTable tableName='delivery-tracking-table' headers={fields} data={rowData()} />
  }
}

const generateAddress = data => {
  return [data['address'], data['zipcode'], data['city'], data['state'], data['country']]
    .filter(Boolean)
    .join(', ')
}
DeliveryTrackingTable.propTypes = {
  activities: PropTypes.arrayOf(
    PropTypes.shape({
      timestamp: PropTypes.string,
      shipmentNumber: PropTypes.string,
      internalStatus: PropTypes.string,
      internalStatusDetails: PropTypes.string,
      country: PropTypes.string,
      state: PropTypes.string,
      city: PropTypes.string,
      address: PropTypes.string,
      zipcode: PropTypes.string,
      receivedBy: PropTypes.string,
      boxPlacedLocation: PropTypes.string,
      otherDeliveryInfo: PropTypes.string,
    }),
  ),
}
DeliveryTrackingTable.defaultProps = {
  activities: [],
}

export default DeliveryTrackingTable
