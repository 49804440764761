import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import BaseTable from 'components/shared/baseTable/baseTable'
import HelpGuideCaption from './helpGuideCaption/helpGuideCaption'
import OrderPageLink from 'components/shared/orderPageLink/orderPageLink'
import { showModal } from 'actions/modal'
import { SHIP_ADDRESS_UPDATE_MODAL } from 'components/modal/modalTypes'
import { shippingLabelApi } from 'apis/shippingLabel.api'
import { displayField } from 'helpers/helpers'
import { STATUSES } from 'helpers/constants'
import './lostLabelsTable.css'
import AppConfig from 'config'

const TABLE_NAME = 'lost-labels-table'
const TABLE_HEADERS = [
  'Shipment #',
  'Shipment Date',
  'Delivery Date',
  'Shipper',
  'Status',
  'Actions',
  'Details',
]

export const LostLabelsTable = props => {
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState(props.data)

  useEffect(() => {
    setData(props.data)
  }, [props.data])

  const countries = () => AppConfig.countries.map(country => country.name)

  const renderRows = () => {
    return data.map((label, index) => (
      <Fragment key={index}>
        <tr className={`${TABLE_NAME}__table-row label-data`} key={`${index}-1`}>
          {renderColumns(label, index)}
        </tr>
      </Fragment>
    ))
  }

  const onChangeAddressClick = (event, label) => {
    event.preventDefault()

    props.dispatch(
      showModal(SHIP_ADDRESS_UPDATE_MODAL, {
        shipmentNumber: label.shipmentNumber,
      }),
    )
  }

  const extractCountryFromShipmentNumber = shipmentNumber => {
    const shipmentCountryRegex = new RegExp(`(DN-)?(${countries().join('|')})-R\\d+`, 'i')
    const match = shipmentNumber.match(shipmentCountryRegex)
    return match[2].toLowerCase()
  }

  const formatStatusData = error => {
    try {
      return (
        `${error.response.body.status_data.message}\n` +
        `Operation: ${error.response.body.status_data.operation}\n` +
        `Attempts: ${error.response.body.status_data.attempts}`
      )
    } catch (error) {
      return `Something went wrong. Error: ${error.message}`
    }
  }

  const retryLabelGeneration = async (shipmentNumber, index) => {
    let statusData = ''
    let status = 'failed'
    try {
      setIsLoading(true)
      const country = extractCountryFromShipmentNumber(shipmentNumber)
      await shippingLabelApi.generateShippingLabel(shipmentNumber, country)
      status = 'completed'
    } catch (error) {
      statusData = formatStatusData(error)
    }

    const newData = data.map((d, i) => (i === index ? { ...d, status, statusData } : d))
    setData(newData)
    setIsLoading(false)
  }

  const renderColumns = (label, index) => {
    const labelRow = (
      <Fragment>
        <td key={`shipment-number-${index}`} className={`${TABLE_NAME}__row-column`}>
          <OrderPageLink shipmentNumber={label.shipmentNumber} />
        </td>
        <td key={`shipment-date-${index}`} className={`${TABLE_NAME}__row-column`}>
          {displayField(label.shipmentDate)}
        </td>
        <td key={`delivery-date-${index}`} className={`${TABLE_NAME}__row-column`}>
          {label.deliveryDate}
        </td>
        <td key={`shipper-${index}`} className={`${TABLE_NAME}__row-column`}>
          {label.shipper}
        </td>
        <td key={`status-${index}`} className={`${TABLE_NAME}__row-column`}>
          <span className={`label-status ${label.status}`}>{label.status}</span>
        </td>
        <td key={`action-buttons-${index}`} className={`${TABLE_NAME}__row-column`}>
          <img
            src='images/pencil-icon.svg'
            alt='change address'
            title='Change address'
            className='change-address-button action-icon'
            onClick={e => onChangeAddressClick(e, label)}
          />
          <img
            src='images/refresh-icon.svg'
            alt='retry label generation'
            title='Retry label generation'
            className={`retry-label-generation-button action-icon ${isLoading ? 'disabled-img' : ''}`}
            onClick={() => retryLabelGeneration(label.shipmentNumber, index)}
          />
        </td>
        <td key={`details-${index}`} className={`${TABLE_NAME}__row-column break-spaces`}>
          {displayField(label.statusData)}
        </td>
      </Fragment>
    )

    return labelRow
  }

  if (props.status === STATUSES.initial) {
    return null
  }

  if (props.status === STATUSES.requested) {
    return <div className='lost-labels__loader'>Loading...</div>
  }

  if (props.status === STATUSES.failed) {
    return (
      <div className='lost-labels__no-results-msg'>
        Something went wrong. Please try again later.
      </div>
    )
  }

  if (props.status === STATUSES.resolved && props.data.length === 0) {
    return <div className='lost-labels__no-results-msg'>No labels found.</div>
  }

  return (
    <Fragment>
      <HelpGuideCaption />
      <BaseTable headers={TABLE_HEADERS} tableName={TABLE_NAME}>
        {renderRows()}
      </BaseTable>
    </Fragment>
  )
}

LostLabelsTable.propTypes = {
  dispatch: PropTypes.func.isRequired,
  data: PropTypes.array,
  status: PropTypes.number.isRequired,
  updateData: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
}

LostLabelsTable.defaultProps = {
  data: [],
}

export default LostLabelsTable
