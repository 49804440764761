import {
  ZIPCODES_UPDATE_CSV_UPLOADED,
  ZIPCODES_UPDATE_FILTERS,
  ZIPCODES_UPDATE_REQUESTED,
  ZIPCODES_UPDATE_SUCCEEDED,
  ZIPCODES_UPDATE_FAILED,
} from 'actions/types'

const initialState = {
  isLoading: false,
  csvContent: null,
}

const deliveryZipsUpdate = (state = initialState, action) => {
  switch (action.type) {
    case ZIPCODES_UPDATE_CSV_UPLOADED:
      return {
        ...state,
        csvContent: action.csvContent,
      }
    case ZIPCODES_UPDATE_FILTERS:
      return {
        ...state,
        [action.key]: action.value,
      }
    case ZIPCODES_UPDATE_REQUESTED:
      return {
        ...state,
        isLoading: true,
      }
    case ZIPCODES_UPDATE_SUCCEEDED:
    case ZIPCODES_UPDATE_FAILED:
      return initialState
    default:
      return state
  }
}

export default deliveryZipsUpdate
