import { csvDownloadApi } from 'apis/csvDownload.api'
import { call, put, takeLatest } from 'redux-saga/effects'
import authenticationApi from 'apis/authentication.api'

import {
  DELIVERY_OPTIONS_CSV_FETCH_REQUESTED,
  DELIVERY_OPTIONS_CSV_FETCH_SUCCEEDED,
  DELIVERY_OPTIONS_CSV_FETCH_FAILED,
  DELIVERY_PROMISES_CSV_FETCH_REQUESTED,
  DELIVERY_PROMISES_CSV_FETCH_SUCCEEDED,
  DELIVERY_PROMISES_CSV_FETCH_FAILED,
} from 'actions/types'

export function* fetchDeliveryOptionsCSV(action) {
  try {
    const response = yield call(csvDownloadApi.deliveryOptions, action.params)

    csvDownloadApi.handleSuccess(response, action.filename)

    yield put({ type: DELIVERY_OPTIONS_CSV_FETCH_SUCCEEDED })
  } catch (error) {
    const failureAction = {
      type: DELIVERY_OPTIONS_CSV_FETCH_FAILED,
    }

    authenticationApi.handleError(error, action, failureAction)
    yield put(failureAction)
  }
}

export function* fetchDeliveryPromisesCSV(action) {
  try {
    const response = yield call(csvDownloadApi.deliveryPromises, action.params)

    csvDownloadApi.handleSuccess(response, action.filename)

    yield put({ type: DELIVERY_PROMISES_CSV_FETCH_SUCCEEDED })
  } catch (error) {
    const failureAction = {
      type: DELIVERY_PROMISES_CSV_FETCH_FAILED,
    }

    authenticationApi.handleError(error, action, failureAction)
    yield put(failureAction)
  }
}

export const fetchCsvSagas = [
  takeLatest(DELIVERY_OPTIONS_CSV_FETCH_REQUESTED, fetchDeliveryOptionsCSV),
  takeLatest(DELIVERY_PROMISES_CSV_FETCH_REQUESTED, fetchDeliveryPromisesCSV),
]
