import React from 'react'
import PropTypes from 'prop-types'

import Filter from 'components/shared/filter/filter'

export default function ShippersFilter(props) {
  return (
    <Filter
      name='shipper'
      defaultText='All shippers'
      onChange={props.onChange}
      data={props.shippers}
    />
  )
}

ShippersFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  shippers: PropTypes.array.isRequired,
}
