import {
  ZIPCODES_CREATE_CSV_UPLOADED,
  ZIPCODES_CREATE_REQUESTED,
  ZIPCODES_CREATE_SUCCEEDED,
  ZIPCODES_CREATE_FAILED,
} from 'actions/types'

const initialState = {
  isLoading: false,
  csvContent: null,
}

const deliveryOptionsNew = (state = initialState, action) => {
  switch (action.type) {
    case ZIPCODES_CREATE_CSV_UPLOADED:
      return {
        ...state,
        csvContent: action.csvContent,
      }
    case ZIPCODES_CREATE_REQUESTED:
      return {
        ...state,
        isLoading: true,
      }
    case ZIPCODES_CREATE_SUCCEEDED:
    case ZIPCODES_CREATE_FAILED:
      return initialState
    default:
      return state
  }
}

export default deliveryOptionsNew
