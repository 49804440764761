import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { fetchData } from 'actions/index'

import './pagination.css'

export function Pagination(props) {
  const nextPage = () => {
    const nextData = {
      ...props.data,
      first: props.perPage,
      after: props.pageInfo.endCursor,
    }
    props.dispatch(fetchData(props.context, nextData))
  }

  const previousPage = () => {
    const previousData = {
      ...props.data,
      last: props.perPage,
      before: props.pageInfo.startCursor,
    }
    props.dispatch(fetchData(props.context, previousData))
  }

  return (
    <div className='pagination'>
      <button
        className='button-primary'
        disabled={!props.pageInfo.hasPreviousPage}
        onClick={previousPage}
      >
        Previous Page
      </button>
      <button className='button-primary' disabled={!props.pageInfo.hasNextPage} onClick={nextPage}>
        Next Page
      </button>
    </div>
  )
}

Pagination.propTypes = {
  dispatch: PropTypes.func.isRequired,
  perPage: PropTypes.number,
  context: PropTypes.string.isRequired,
  data: PropTypes.object,
  pageInfo: PropTypes.shape({
    endCursor: PropTypes.string,
    hasNextPage: PropTypes.bool,
    hasPreviousPage: PropTypes.bool,
    startCursor: PropTypes.string,
  }).isRequired,
}

const connectedPagination = connect()(Pagination)
export default connectedPagination
