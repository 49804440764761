import superagent from 'superagent'

function getShipments(type, country, brand, deliveryDate, productionSite) {
  const url = `${process.env.REACT_APP_LOGISTICS_API_URL}/exports/shipments`
  const logisticsToken = localStorage.getItem('logisticsToken')
  const params = {
    type: type,
    country: country,
    brand: brand,
    delivery_date: deliveryDate,
    'production_site[]': productionSite,
  }

  return superagent
    .get(url)
    .responseType('blob')
    .query(params)
    .set('Authorization', `Bearer ${logisticsToken}`)
    .set('Content-Type', 'text/csv;charset=utf-8')
    .set('X-Issuer', 'ziplog')
}

const fetchFilterData = () => {
  const url = `${process.env.REACT_APP_LOGISTICS_API_URL}/exports/shipment_filters`
  const logisticsToken = localStorage.getItem('logisticsToken')

  return superagent
    .get(url)
    .set('Authorization', `Bearer ${logisticsToken}`)
    .set('Content-Type', 'application/json')
    .set('X-Issuer', 'ziplog')
}

export const shipmentsApi = {
  getShipments,
  fetchFilterData,
}
