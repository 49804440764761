import React from 'react'
import { useForm } from 'react-hook-form'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { changeShipAddress } from 'actions/shipAddress'

import './shipAddressForm.css'

// Base input component
const Input = React.forwardRef(function Input(
  { label, placeholder, onChange, required, name, type },
  ref,
) {
  return (
    <>
      <label className='label ship-address-form__label'>{label}</label>
      <input
        className={`input ship-address-form__input u-full-width ${required ? 'required' : ''}`}
        name={name}
        ref={ref}
        type={type}
        placeholder={placeholder || label}
        onChange={onChange}
        required={required}
      />
    </>
  )
})

Input.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  name: PropTypes.string,
  type: PropTypes.string,
}

// Base text area component
const TextArea = React.forwardRef(function TextArea({ label, onChange, name, placeholder }, ref) {
  return (
    <>
      <label className='label ship-address-form__label'>{label}</label>
      <textarea
        className='u-full-width ship-address-form__input'
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        ref={ref}
        rows={5}
      />
    </>
  )
})

TextArea.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
}

// Shipping address form component
export const ShipAddressForm = props => {
  const { register, handleSubmit } = useForm({
    defaultValues: props.shipAddress,
  })

  const onSubmit = data => {
    props.dispatch(changeShipAddress(data))
    props.onClose()
  }

  return (
    <form
      className='container u-full-width ship-address-form__form'
      onSubmit={handleSubmit(onSubmit)}
    >
      <hr />

      <div className='row'>
        <div className='column one-half'>
          <div className='row'>
            <div className='column one-third'>
              <Input label='Country' required {...register('country')} />
            </div>
            <div className='column one-third'>
              <Input label='State' {...register('state')} />
            </div>
            <div className='column one-third'>
              <Input label='State abbr.' {...register('stateAbbreviation')} />
            </div>
          </div>
          <div className='row'>
            <div className='column one-half'>
              <Input label='City' required {...register('city')} />
            </div>

            <div className='column one-half'>
              <Input label='Zipcode' required {...register('zipcode')} />
            </div>
          </div>
          <Input label='Line1' required placeholder='Address line 1' {...register('line1')} />
          <Input label='Line2' placeholder='Address line 2' {...register('line2')} />
        </div>
        <div className='column one-half'>
          <div className='row'>
            <div className='column one-half'>
              <Input label='First name' required {...register('firstname')} />
            </div>
            <div className='column one-half'>
              <Input label='Last name' required {...register('lastname')} />
            </div>
          </div>
          <div className='row'>
            <div className='column one-half'>
              <Input label='Company' {...register('company')} />
            </div>
            <div className='column one-half'>
              <Input label='Phone number' type='phone' required {...register('phone')} />
            </div>
          </div>
          <Input label='House number' placeholder='House number' {...register('houseNumber')} />
          <TextArea
            label='Delivery instructions'
            placeholder='How to deliver the shipment'
            {...register('deliveryInstructions')}
          />
        </div>
      </div>
      <hr />
      <input className='button-primary zipcodes-add-preview-modal__submit-button' type='submit' />
      <button className='zipcodes-add-preview-modal__cancel-button' onClick={props.onClose}>
        Cancel
      </button>
    </form>
  )
}

ShipAddressForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  shipAddress: PropTypes.object,
}

const mapStateToProps = state => {
  return { shipAddress: state.shipAddress.data }
}

const connectedShipAddressForm = connect(mapStateToProps)(ShipAddressForm)

export default connectedShipAddressForm
