import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import ShipmentOptionsPostButton from './shipmentOptionsPostButton/shipmentOptionsPostButton'
import ShipmentOptionsTable from './shipmentOptionsTable/shipmentOptionsTable'
import BrandsFilter from 'components/shared/brandsFilter/brandsFilter'
import CountryFilter from 'components/shared/countryFilter/countryFilter'
import RegionsFilter from 'components/shared/regionsFilter/regionsFilter'
import InfoMessage from 'components/shared/infoMessage/infoMessage'
import ProductionSitesFilter from './shipmentOptionsFilters/productionSitesFilter/productionSitesFilter'
import ShippersFilter from './shipmentOptionsFilters/shippersFilter/shippersFilter'
import WdaysFilter from 'components/shared/wdaysFilter/wdaysFilter'
import ZipcodeInput from 'components/shared/zipcodeInput/zipcodeInput'
import { formatAllValue } from 'helpers/helpers'

import { fetchData, setFilters } from 'actions/index'
import { SHIPMENT_OPTIONS, MAPPINGS } from 'actions/context'
import './shipmentOptions.css'

const PER_PAGE = 100

const INFO_MESSAGE_TEXT =
  'This page is a work in progress and should not yet be used for daily work. ' +
  'However you are very welcome to explore it and ask us any questions you may have. ' +
  'Feedback would be awesome.'

export const ShipmentOptions = props => {
  const { country, zipcode } = props

  useEffect(() => {
    props.dispatch(fetchData(MAPPINGS))
  }, [])

  const handleZipcodeChange = zipcode => {
    props.dispatch(setFilters(SHIPMENT_OPTIONS, 'zipcode', zipcode))
  }

  const handleWdaysChange = wdays => {
    props.dispatch(setFilters(SHIPMENT_OPTIONS, 'wdays', wdays))
  }

  const graphqlData = {
    country: props.country,
    brand: formatAllValue(props.brands),
    zipcode: props.zipcode ?? '',
    regions: props.regions ?? '',
    wdays: props.wdays ?? [],
    productionSite: formatAllValue(props.productionSite),
    shipper: formatAllValue(props.shipper),
  }
  const graphqlPostData = {
    ...graphqlData,
    first: PER_PAGE,
  }
  const paginationData = {
    data: graphqlData,
    pageInfo: props.pageInfo,
    perPage: PER_PAGE,
  }

  return (
    <div className='shipment-options'>
      <InfoMessage message={INFO_MESSAGE_TEXT} />
      <div className='shipment-options__required-filters'>
        <CountryFilter context={SHIPMENT_OPTIONS} countries={props.countriesAllowedToView} />
        <BrandsFilter context={SHIPMENT_OPTIONS} />
        <ZipcodeInput zipcode={zipcode} changeCallback={handleZipcodeChange} />
        <RegionsFilter
          context={SHIPMENT_OPTIONS}
          countryRegionsMapping={props.countryRegionsMapping}
          country={props.country}
          regions={props.regions}
        />
        <ProductionSitesFilter {...props} />
        <ShippersFilter {...props} />
        <WdaysFilter
          context={SHIPMENT_OPTIONS}
          changeCallback={handleWdaysChange}
          wdays={props.wdays}
        />
        <br />
        <ShipmentOptionsPostButton data={graphqlPostData} />
      </div>
      {!country && 'Please choose a country'}
      <ShipmentOptionsTable
        shipmentOptions={props.shipmentOptions}
        paginationData={paginationData}
        status={props.status}
      />
    </div>
  )
}

ShipmentOptions.propTypes = {
  dispatch: PropTypes.func.isRequired,
  brands: PropTypes.string,
  productionSite: PropTypes.string,
  shipper: PropTypes.string,
  countriesAllowedToView: PropTypes.array,
  countryRegionsMapping: PropTypes.object,
  countryProductionSitesMapping: PropTypes.object,
  countryBrandShippersMapping: PropTypes.array,
  country: PropTypes.string,
  shipmentOptions: PropTypes.array,
  pageInfo: PropTypes.shape({
    endCursor: PropTypes.string,
    hasNextPage: PropTypes.bool,
    hasPreviousPage: PropTypes.bool,
    startCursor: PropTypes.string,
  }).isRequired,
  status: PropTypes.number.isRequired,
  regions: PropTypes.array,
  wdays: PropTypes.array,
  zipcode: PropTypes.string,
  availableFilters: PropTypes.object,
}

const mapStateToProps = state => {
  return {
    brands: state.globalFilters.brands,
    productionSite: state.shipmentOptions.productionSite,
    shipper: state.shipmentOptions.shipper,
    countriesAllowedToView: state.currentUser.countriesAllowedToView,
    countryRegionsMapping: state.mappings.countryRegionsMapping,
    countryProductionSitesMapping: state.mappings.countryProductionSitesMapping,
    countryBrandShippersMapping: state.mappings.countryBrandShippersMapping,
    country: state.globalFilters.country,
    shipmentOptions: state.shipmentOptions.data,
    pageInfo: state.shipmentOptions.pageInfo,
    availableFilters: state.shipmentOptions.availableFilters,
    wdays: state.shipmentOptions.wdays,
    zipcode: state.shipmentOptions.zipcode,
    hasPreviousPage: state.shipmentOptions.hasPreviousPage,
    hasNextPage: state.shipmentOptions.hasNextPage,
    regions: state.globalFilters.regions,
    status: state.shipmentOptions.status,
  }
}

const connectedShipmentOptions = connect(mapStateToProps)(ShipmentOptions)

export default connectedShipmentOptions
