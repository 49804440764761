import { SET_FEATURE_FLAGS } from 'actions/types'

const initialState = {}

const featureFlags = (state = initialState, action) => {
  switch (action.type) {
    case SET_FEATURE_FLAGS:
      return { ...action.flags }
    default:
      return state
  }
}

export default featureFlags
