import superagent from 'superagent'

import { helpers } from 'helpers/helpers'

export const csvDownloadApi = {
  deliveryOptions,
  deliveryPromises,
  handleSuccess,
}

function handleSuccess(response, filename) {
  helpers.downloadFile(response.text, filename)
}

function deliveryOptions(params) {
  return get(params, 'exports/delivery_options')
}

function deliveryPromises(params) {
  return get(params, 'delivery_promises/exports')
}

function get(params, endpoint) {
  const url = `${process.env.REACT_APP_LOGISTICS_API_URL}/${endpoint}`
  const logisticsToken = localStorage.getItem('logisticsToken')

  return superagent
    .get(url)
    .query(params)
    .set('Authorization', `Bearer ${logisticsToken}`)
    .set('Content-Type', 'text/csv;charset=utf-8')
    .set('X-Issuer', 'ziplog')
}
