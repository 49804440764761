import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { helpers } from 'helpers/helpers'
import { fetchCSV } from 'actions/index'
import './downloadDeliveryOptionsByZipcodeButton.css'

export class DownloadDeliveryOptionsByZipcodeButton extends React.Component {
  _fileName() {
    const { country, brand, zipcode } = this.props
    const { productionSite, region, suburb, shipper, deliveryDay } = this.props.filters

    return `delivery_options_${[
      country,
      brand,
      zipcode,
      region,
      suburb,
      productionSite,
      shipper,
      deliveryDay,
    ]
      .filter(param => {
        return param && param.length > 0
      })
      .join('_')
      .replace(' ', '')}.csv`
  }

  _appendToObject(obj, key, value) {
    if (key === 'region' && value === null) {
      obj[key] = value
      return obj
    }

    if (value === undefined || value === null) {
      return obj
    }

    obj[key] = value

    return obj
  }

  _generateParams = () => {
    let params = {}
    const filters = this.props.filters

    for (let field of ['country', 'brand', 'zipcode']) {
      params = this._appendToObject(params, field, this.props[field])
    }
    for (let field of ['region', 'shipper', 'suburb']) {
      params = this._appendToObject(params, field, filters[field])
    }
    params = this._appendToObject(params, 'production_site', filters.productionSite)
    params = this._appendToObject(params, 'wday', helpers.weekdayNumber(filters.deliveryDay))

    return params
  }

  _downloadDeliveryOptions = () => {
    const params = this._generateParams()
    const context = 'DELIVERY_OPTIONS_CSV'

    this.props.dispatch(fetchCSV(context, params, this._fileName()))
  }

  _isBtnDisabled() {
    return !this.props.country || !this.props.brand || this.props.isLoading
  }

  render() {
    return (
      <button
        className='download-delivery-options-button button-secondary'
        disabled={this._isBtnDisabled()}
        onClick={this._downloadDeliveryOptions}
      >
        {this.props.isLoading ? 'Downloading...' : '\u2913 Download CSV by Zipcode'}
      </button>
    )
  }
}

DownloadDeliveryOptionsByZipcodeButton.propTypes = {
  dispatch: PropTypes.func.isRequired,
  country: PropTypes.string,
  brand: PropTypes.string,
  zipcode: PropTypes.string,
  filters: PropTypes.object,
  isLoading: PropTypes.bool,
}

function mapStateToProps(state) {
  return {
    isLoading: state.deliveryOptionsCSV.isLoading,
    country: state.globalFilters.country,
    brand: state.globalFilters.brand,
    filters: state.deliveryOptions.filters,
  }
}

const connectedDownloadDeliveryOptionsByZipcodeButton = connect(mapStateToProps)(
  DownloadDeliveryOptionsByZipcodeButton,
)

export default connectedDownloadDeliveryOptionsByZipcodeButton
