import authenticationApi from 'apis/authentication.api'
import { openErrorAlert } from 'actions/alert/alert'
import { store } from 'store'

export function handleError(response, notFoundText) {
  const notFoundMessage = notFoundText || response.message || 'Not Found'
  const { dispatch } = store

  if (response.status === 401) {
    dispatch(openErrorAlert(`${response.message}. Please login again.`))

    authenticationApi.logout()
    window.location = '/'
  } else {
    const errMsg = response.status === 404 ? notFoundMessage : `Error: ${response.message}`

    dispatch(openErrorAlert(errMsg))
  }
}
