import React from 'react'
import PropTypes from 'prop-types'
import BaseTable from 'components/shared/baseTable/baseTable'
import { moveOrdersApi } from 'apis/move_orders.api'
import { openErrorAlert } from 'actions/alert/alert'

export const FailedOrderMovementsTable = props => {
  const { data, isLoading, tableName, country, dispatch } = props

  const fields = ['File name']

  const isEmpty = data => data && data.length === 0

  const extractFileName = fullFilename => fullFilename.split('/').reverse()[0]

  const downloadFile = async filename => {
    try {
      const response = await moveOrdersApi.fetchFile(country, filename)
      moveOrdersApi.downloadFile(response, extractFileName(filename))
    } catch (error) {
      dispatch(openErrorAlert('Something went wrong. Error: ' + error))
      console.error(error)
    }
  }

  if (data) {
    if (isEmpty(data)) {
      return (
        <div className='delivery-options-table__no-results-msg'>
          No order movement errors found.
        </div>
      )
    }

    return (
      <div>
        <BaseTable tableName={tableName} headers={fields}>
          {data.map((filename, index) => (
            <tr className={`${tableName}__table-row`} key={index}>
              <td key={`${filename}-${index}`}>{filename}</td>
              <td>
                <button
                  className='download-delivery-options-button button-secondary'
                  onClick={() => downloadFile(filename)}
                >
                  {isLoading ? 'Downloading...' : '\u2913 Download file'}
                </button>
              </td>
            </tr>
          ))}
        </BaseTable>
      </div>
    )
  }
}

FailedOrderMovementsTable.propTypes = {
  dispatch: PropTypes.func.isRequired,
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  country: PropTypes.string,
  tableName: PropTypes.string,
}

export default FailedOrderMovementsTable
