import superagent from 'superagent'

function getTrackingActivities(params) {
  const url = `${process.env.REACT_APP_LOGISTICS_API_URL}/exports/tracking_activities`
  const logisticsToken = localStorage.getItem('logisticsToken')
  return superagent
    .get(url)
    .responseType('blob')
    .query(params)
    .set('Authorization', `Bearer ${logisticsToken}`)
    .set('Content-Type', 'text/csv;charset=utf-8')
    .set('X-Issuer', 'ziplog')
}

function getTrackings(params) {
  const url = `${process.env.REACT_APP_LOGISTICS_API_URL}/exports/trackings`
  const logisticsToken = localStorage.getItem('logisticsToken')
  return superagent
    .get(url)
    .responseType('blob')
    .query(params)
    .set('Authorization', `Bearer ${logisticsToken}`)
    .set('Content-Type', 'text/csv;charset=utf-8')
    .set('X-Issuer', 'ziplog')
}

export const trackingApi = {
  getTrackingActivities,
  getTrackings,
}
