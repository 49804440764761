import React from 'react'
import PropTypes from 'prop-types'

import AppConfig from 'config'

import './orderPageLink.css'

export function OrderPageLink(props) {
  const orderNumber = () => {
    const orderNumberRegex = new RegExp(`(DN-)?(${countries().join('|')})-R\\d+`, 'i')
    const match = props.shipmentNumber.match(orderNumberRegex)

    return match && match[0]
  }

  const countries = () => AppConfig.countries.map(country => country.name)

  return (
    <a
      href={`${process.env.REACT_APP_MS_ADMIN_URL}/orders/${orderNumber()}/delivery`}
      target='_blank'
      rel='noopener noreferrer'
      className='ship-address-link'
    >
      {props.shipmentNumber}
    </a>
  )
}

OrderPageLink.propTypes = {
  shipmentNumber: PropTypes.string.isRequired,
}

export default OrderPageLink
