import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { fetchCSV } from 'actions/index'
import './deliveryPromisesDownloadButton.css'

export const DeliveryPromisesDownloadButton = props => {
  // Without this local 'isLoading' state, the text of *both* [region and zipcode] Download buttons
  // will change to "Downloading...", when *either* one of them is clicked.
  // This is because both buttons share the 'isLoading' state in the deliveryPromisesCSV store.
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    // required after DELIVERY_PROMISES_CSV_FETCH_SUCCEEDED or DELIVERY_PROMISES_CSV_FETCH_FAILED
    if (!props.isLoading && isLoading) setIsLoading(props.isLoading)
  }, [props.isLoading])

  const fileName = () => {
    const { country, brand, zipcode } = props
    const suffix = props.type + '_download'

    return `delivery_promises_${[country, brand, zipcode, suffix]
      .filter(param => {
        return param && param.length > 0
      })
      .join('_')
      .replace(' ', '')}.csv`
  }

  const appendToObject = (obj, key, value) => {
    if (value === undefined || value === null) {
      return obj
    }

    if (Array.isArray(value)) {
      obj[`${key}[]`] = value
      return obj
    }

    obj[key] = value
    return obj
  }

  const generateParams = () => {
    let params = {}
    for (let field of ['country', 'brand', 'zipcode', 'regions', 'wdays', 'type']) {
      params = appendToObject(params, field, props[field])
    }
    return params
  }

  const downloadDeliveryPromises = () => {
    setIsLoading(true)
    const params = generateParams()
    const context = 'DELIVERY_PROMISES_CSV'

    props.dispatch(fetchCSV(context, params, fileName()))
  }

  const isBtnDisabled = () => {
    return !props.country || isLoading
  }

  return (
    <button
      className='download-delivery-promises-button button-secondary'
      disabled={isBtnDisabled()}
      onClick={downloadDeliveryPromises}
    >
      {isLoading ? 'Downloading...' : `\u2913 ${props.buttonText}`}
    </button>
  )
}

DeliveryPromisesDownloadButton.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  country: PropTypes.string,
  brand: PropTypes.string,
  zipcode: PropTypes.string,
  regions: PropTypes.array,
  wdays: PropTypes.array,
  type: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
}

function mapStateToProps(state) {
  return {
    isLoading: state.deliveryPromisesCSV.isLoading,
    country: state.globalFilters.country,
    brand: state.globalFilters.brand,
    zipcode: state.deliveryPromises.zipcode,
    regions: state.globalFilters.regions,
    wdays: state.deliveryPromises.wdays,
  }
}

const connectedDeliveryPromisesDownloadButton = connect(mapStateToProps)(
  DeliveryPromisesDownloadButton,
)

export default connectedDeliveryPromisesDownloadButton
