import superagent from 'superagent'

export const usersApi = {
  createUser,
}

function createUser(email, roles) {
  const url = `${process.env.REACT_APP_LOGISTICS_API_URL}/users`
  const logisticsToken = localStorage.getItem('logisticsToken')
  const params = {
    email: email,
    roles: roles,
  }

  return superagent
    .post(url)
    .responseType('json')
    .send(params)
    .set('Authorization', `Bearer ${logisticsToken}`)
    .set('Content-Type', 'application/json')
    .set('X-Issuer', 'ziplog')
}
