import superagent from 'superagent'

export const deliveryOptionsApi = {
  createDeliveryOptions,
  updateDeliveryOptions,
  pauseDeliveryOptions,
  updateDynamicOptions,
}

function createDeliveryOptions(country, brand, csvContent) {
  const body = {
    country,
    brand,
    csv_content: csvContent,
  }

  return post('bulk_create', body)
}

function updateDeliveryOptions(country, brand, csvContent, firstDeliveryWeek) {
  const body = {
    country,
    brand,
    csv_content: csvContent,
    first_delivery_week: firstDeliveryWeek,
  }

  return post('bulk_replace', body)
}

function pauseDeliveryOptions(country, brand, csvContent) {
  const body = {
    country,
    brand,
    csv_content: csvContent,
  }

  return post('pauses', body)
}

function updateDynamicOptions(country, brand, csvContent, firstDeliveryWeek, createNewIfNotExist) {
  const body = {
    country,
    brand,
    csv_content: csvContent,
    first_delivery_week: firstDeliveryWeek,
    create_new_if_not_exist: createNewIfNotExist,
  }

  return put('dynamic_options', body)
}

function post(action, body) {
  const url = `${process.env.REACT_APP_LOGISTICS_API_URL}/delivery_options/${action}`
  const logisticsToken = localStorage.getItem('logisticsToken')

  return superagent
    .post(url)
    .send(body)
    .set('Authorization', `Bearer ${logisticsToken}`)
    .set('Content-Type', 'application/json')
    .set('X-Issuer', 'ziplog')
}

function put(action, body) {
  const url = `${process.env.REACT_APP_LOGISTICS_API_URL}/delivery_options/${action}`
  const logisticsToken = localStorage.getItem('logisticsToken')

  return superagent
    .put(url)
    .send(body)
    .set('Authorization', `Bearer ${logisticsToken}`)
    .set('Content-Type', 'application/json')
    .set('X-Issuer', 'ziplog')
}
