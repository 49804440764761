import { call, put } from 'redux-saga/effects'
import { delay } from 'redux-saga'

import { graphqlApi } from 'apis/graphql.api'
import authenticationApi from 'apis/authentication.api'
import { shipAddressFetchSuccess } from 'actions/shipAddress'
import { SHIP_ADDRESS_FETCH_FAILED } from 'actions/types'

// Fetches the shipping address by shipment number
export function* fetchShipAddress(action) {
  try {
    const response = yield call(graphqlApi.postShipAddress, action.data)
    yield delay(50) // just to make the loader more smooth

    graphqlApi.handleGraphqlErrors(response)

    yield put(shipAddressFetchSuccess(response.body.data?.shipAddress))
  } catch (error) {
    const failureAction = { type: SHIP_ADDRESS_FETCH_FAILED }
    authenticationApi.handleError(error, action, failureAction)

    yield put(failureAction)
  }
}
