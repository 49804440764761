import superagent from 'superagent'

export const zipcodesApi = {
  createZipcodes,
  updateZipcodes,
  logisticsToken,
}

function logisticsToken() {
  return localStorage.getItem('logisticsToken')
}

function updateZipcodes(country, brand, firstDeliveryDate, csvContent) {
  const url = `${process.env.REACT_APP_LOGISTICS_API_URL}/delivery_zips/bulk_update`

  const body = {
    country,
    brand,
    first_delivery_date: firstDeliveryDate,
    csv_content: csvContent,
  }

  return put(url, body)
}

function createZipcodes(country, brand, csvContent) {
  const url = `${process.env.REACT_APP_LOGISTICS_API_URL}/delivery_zips/bulk_create`

  const body = {
    country,
    brand,
    csv_content: csvContent,
  }

  return post(url, body)
}

function post(url, body) {
  return superagent
    .post(url)
    .send(body)
    .set('Authorization', `Bearer ${logisticsToken()}`)
    .set('Content-Type', 'application/json')
    .set('X-Issuer', 'ziplog')
}

function put(url, body) {
  return superagent
    .put(url)
    .send(body)
    .set('Authorization', `Bearer ${logisticsToken()}`)
    .set('Content-Type', 'application/json')
    .set('X-Issuer', 'ziplog')
}
