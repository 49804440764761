import './deliveryTrackingInformation.css'
import React from 'react'
import PropTypes from 'prop-types'
import { displayField } from 'helpers/helpers'

const DeliveryTrackingInformation = ({ information, latestActivity }) => {
  if (information) {
    return (
      <div className='delivery-tracking-information'>
        <div className='row'>
          <div className='column'>
            <label>Shipper name</label>
            {displayField(information['shipperName'])}
          </div>
          <div className='column'>
            <label>Brand</label>
            {displayField(information['brand'])}
          </div>
          <div className='column'>
            <label>Shipment country</label>
            {displayField(information['shipmentCountry'])}
          </div>
        </div>
        <div className='row'>
          <div className='column'>
            <label>Received by</label>
            {displayField(latestActivity['receivedBy'])}
          </div>
          <div className='column'>
            <label>Placed at</label>
            {displayField(latestActivity['boxPlacedLocation'])}
          </div>
          <div className='column'>
            <label>Other information</label>
            {displayField(latestActivity['otherDeliveryInfo'])}
          </div>
        </div>
        <hr className='delivery-tracking-information__divider' />
      </div>
    )
  }
  return null
}

DeliveryTrackingInformation.propTypes = {
  information: PropTypes.shape({
    shipperName: PropTypes.string,
    brand: PropTypes.string,
    shipmentCountry: PropTypes.string,
  }),
  latestActivity: PropTypes.shape({
    timestamp: PropTypes.string,
    shipmentNumber: PropTypes.string,
    internalStatus: PropTypes.string,
    internalStatusDetails: PropTypes.string,
    country: PropTypes.string,
    state: PropTypes.string,
    city: PropTypes.string,
    address: PropTypes.string,
    zipcode: PropTypes.string,
    receivedBy: PropTypes.string,
    boxPlacedLocation: PropTypes.string,
    otherDeliveryInfo: PropTypes.string,
  }),
}

DeliveryTrackingInformation.defaultProps = {
  information: {},
}

export default DeliveryTrackingInformation
