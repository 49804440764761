import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import CountryFilter from 'components/shared/countryFilter/countryFilter'
import ZiplogDatepicker from 'components/shared/ziplogDatepicker/ziplogDatepicker'
import { setFilters } from 'actions/orderMovement'
import FailedOrderMovementsTable from './failedOrderMovementsTable'
import { setGlobalFilter } from 'actions'

export class FailedOrderMovements extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      activeFilters: {},
    }
  }

  isBtnDisabled() {
    const { country, movementDate } = this.props
    return !country || !movementDate
  }

  updateSelectedDate = date => {
    this.props.dispatch(setGlobalFilter('movementDate', date))
  }

  fetchFailedOrderMovements = () => {
    this.props.dispatch(setFilters(this.props.country, this.props.movementDate))
  }

  getData() {
    return this.props.responseBody == null ? [] : this.props.responseBody
  }

  render() {
    if (this.props.isLoading) {
      return <div className='failed-order-movement__loader'>Loading...</div>
    } else if (this.props.canManage) {
      return (
        <div>
          <CountryFilter countries={this.props.countriesAllowedToManage} />
          <ZiplogDatepicker
            defaultDate={this.props.movementDate}
            onDateSelect={this.updateSelectedDate}
            labelText='Movement Date'
          />
          <button
            className='button-primary failed-order-movement__submit-button'
            disabled={this.isBtnDisabled()}
            onClick={this.fetchFailedOrderMovements}
          >
            View
          </button>
          <FailedOrderMovementsTable
            tableName='failed-order-movements-table'
            country={this.props.country}
            data={this.getData()}
            isLoading={this.props.isLoading}
          />
        </div>
      )
    }
  }
}

FailedOrderMovements.propTypes = {
  dispatch: PropTypes.func.isRequired,
  country: PropTypes.string,
  movementDate: PropTypes.string,
  isLoading: PropTypes.bool,
  canManage: PropTypes.bool.isRequired,
  countriesAllowedToManage: PropTypes.array.isRequired,
  responseBody: PropTypes.object,
}

function mapStateToProps(state) {
  return {
    country: state.globalFilters.country,
    movementDate: state.globalFilters.movementDate,
    canManage: state.currentUser.canManage,
    isLoading: state.failedOrderMovements.isLoading,
    countriesAllowedToManage: state.currentUser.countriesAllowedToManage,
    responseBody: state.failedOrderMovements.responseBody,
  }
}

const connectedFailedOrderMovements = connect(mapStateToProps)(FailedOrderMovements)

export default connectedFailedOrderMovements
