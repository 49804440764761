import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { clearData, setGlobalFilter } from 'actions'
import ZipLogDatePicker from '../ziplogDatepicker/ziplogDatepicker'

export const RegularDeliveryDatePicker = props => {
  const updateSelectedDate = date => {
    props.dispatch(clearData(props.context))
    props.dispatch(setGlobalFilter('regularDeliveryDate', date))
  }

  return (
    <ZipLogDatePicker
      defaultDate={props.regularDeliveryDate}
      onDateSelect={updateSelectedDate}
      labelText='Regular delivery date'
    />
  )
}

RegularDeliveryDatePicker.propTypes = {
  dispatch: PropTypes.func.isRequired,
  regularDeliveryDate: PropTypes.string,
  context: PropTypes.string.isRequired,
}

function mapStateToProps(state) {
  return {
    regularDeliveryDate: state.globalFilters.regularDeliveryDate,
  }
}

const connectedRegularDeliveryDatePicker = connect(mapStateToProps)(RegularDeliveryDatePicker)

export default connectedRegularDeliveryDatePicker
