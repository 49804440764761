import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import BaseModal from 'components/modal/baseModal'
import { hideModal } from 'actions/modal'
import { createZipcodes } from 'actions/zipcodes'

import './previewModal.css'

export const PreviewModal = props => {
  const closeModal = () => {
    props.dispatch(hideModal())
  }

  const submitNewZipcodes = () => {
    props.dispatch(createZipcodes(props.country, props.brand, props.csvContent))

    closeModal()
  }

  return (
    <BaseModal onClose={closeModal}>
      <p className='zipcodes-add-preview-modal__text'>
        Are you sure you want to create these zipcodes?
      </p>
      <button className='zipcodes-add-preview-modal__cancel-button' onClick={closeModal}>
        Cancel
      </button>
      <button
        className='button-primary zipcodes-add-preview-modal__submit-button'
        onClick={submitNewZipcodes}
      >
        {`Yes, I'm sure`}
      </button>
    </BaseModal>
  )
}

PreviewModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  country: PropTypes.string,
  brand: PropTypes.string,
  csvContent: PropTypes.string,
}

function mapStateToProps(state) {
  return {
    country: state.globalFilters.country,
    brand: state.globalFilters.brand,
    csvContent: state.zipcodesNew.csvContent,
  }
}

const connectedPreviewModal = connect(mapStateToProps)(PreviewModal)

export default connectedPreviewModal
