import { mappingsApi } from 'apis/mappings.api'
import { call, put, takeLatest } from 'redux-saga/effects'
import {
  MAPPINGS_FETCH_REQUESTED,
  MAPPINGS_FETCH_SUCCEEDED,
  MAPPINGS_FETCH_FAILED,
} from 'actions/types'
import authenticationApi from 'apis/authentication.api'

export function* fetchMappings(action) {
  try {
    const response = yield call(mappingsApi.fetchMappings, action.data)

    yield put({ type: MAPPINGS_FETCH_SUCCEEDED, data: response.body.data })
  } catch (error) {
    const failureAction = {
      type: MAPPINGS_FETCH_FAILED,
    }

    authenticationApi.handleError(error, action, failureAction)

    yield put(failureAction)
  }
}

export const fetchMappingsSagas = [takeLatest(MAPPINGS_FETCH_REQUESTED, fetchMappings)]
