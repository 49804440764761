import { applyMiddleware, createStore, compose } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import createSagaMiddleware from 'redux-saga'

import combinedReducers from 'reducers'
import combinedSagas from 'sagas'

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['currentUser'],
}

const persistedReducer = persistReducer(persistConfig, combinedReducers)

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const sagaMiddleware = createSagaMiddleware()

export const store = createStore(persistedReducer, composeEnhancer(applyMiddleware(sagaMiddleware)))

sagaMiddleware.run(combinedSagas)

export const persistedStore = persistStore(store)
