import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { camelCase } from 'lodash'
import './deliveryTracking.css'

import DeliveryTrackingPostButton from './deliveryTrackingPostButton/deliveryTrackingPostButton'
import DeliveryTrackingAccordion from './deliveryTrackingAccordion/deliveryTrackingAccordion'
import Filter from 'components/shared/filter/filter'
import { STATUSES } from 'helpers/constants'

const searchByOptions = ['Order Number', 'Shipment Number', 'Tracking Number']

export const DeliveryTracking = props => {
  const [searchBy, setSearchBy] = useState(searchByOptions[0])
  const [searchValue, setSearchValue] = useState('')

  const handleSearchByFilter = event => {
    const value = event.target.value
    setSearchValue('')
    setSearchBy(value)
  }

  const handleSearchInput = event => {
    const value = event.target.value
    setSearchValue(value)
  }

  const postButtonProps = { [camelCase(searchBy)]: searchValue }

  return (
    <Fragment>
      <Filter
        name='searchBy'
        data={searchByOptions}
        labelText='Search By'
        selectedValue={searchBy}
        onChange={handleSearchByFilter}
      />
      <div className='filter'>
        <label className='filter__label'>{searchBy}</label>
        <input
          className='filter__input'
          name='search-value'
          placeholder={searchBy}
          type='search'
          value={searchValue}
          onChange={handleSearchInput}
        />
      </div>
      <div className='filter__post-button'>
        <DeliveryTrackingPostButton {...postButtonProps} />
      </div>

      <DeliveryTrackingAccordion data={props.data} status={props.status} />
    </Fragment>
  )
}

DeliveryTracking.propTypes = {
  status: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      orderNumber: PropTypes.string.isRequired,
      shipmentNumber: PropTypes.string.isRequired,
      trackingNumber: PropTypes.string.isRequired,
      shipperName: PropTypes.string,
      brand: PropTypes.string,
      shipmentTrackingActivities: PropTypes.arrayOf(
        PropTypes.shape({
          timestamp: PropTypes.string,
          shipperStatus: PropTypes.string,
          internalStatus: PropTypes.string,
          internalStatusDetails: PropTypes.string,
          country: PropTypes.string,
          state: PropTypes.string,
          city: PropTypes.string,
          address: PropTypes.string,
          zipcode: PropTypes.string,
          receivedBy: PropTypes.string,
          boxPlacedLocation: PropTypes.string,
          otherDeliveryInfo: PropTypes.string,
        }),
      ),
    }),
  ),
}

DeliveryTracking.defaultProps = {
  status: STATUSES.initial,
  data: [],
}

const mapStateToProps = state => {
  return {
    data: state.deliveryTracking.data,
    status: state.deliveryTracking.status,
  }
}
const connectedDeliveryTracking = connect(mapStateToProps)(DeliveryTracking)
export default connectedDeliveryTracking
