import React, { useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { MultiSelect } from 'react-multi-select-component'

import { clearData } from 'actions/index'

const WDAYS = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
const WDAY_OPTIONS = WDAYS.map((day, ind) => {
  return { label: day, value: ind + 1 }
})

export const WdaysFilter = props => {
  const selectedWdays = props.wdays.map(i => {
    return { label: WDAYS[i], value: i }
  })
  const [selected, setSelected] = useState(selectedWdays)
  const overrideStrings = {
    allItemsAreSelected: 'All Weekdays',
    selectSomeItems: 'Please select',
  }

  const handleChange = wdays => {
    props.dispatch(clearData(props.context))
    setSelected(wdays)
    if (props.changeCallback) {
      props.changeCallback(wdays.map(i => i.value))
    }
  }

  return (
    <div className='wdays-filter multi-select-filter'>
      <label className='filter__label'>Weekdays</label>
      <MultiSelect
        options={WDAY_OPTIONS}
        value={selected}
        onChange={handleChange}
        labelledBy='Select'
        overrideStrings={overrideStrings}
        disableSearch
      />
    </div>
  )
}

WdaysFilter.propTypes = {
  dispatch: PropTypes.func.isRequired,
  changeCallback: PropTypes.func,
  context: PropTypes.string.isRequired,
  wdays: PropTypes.array.isRequired,
}

const connectedFilter = connect()(WdaysFilter)

export default connectedFilter
