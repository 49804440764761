import { DELIVERY_OPTIONS_UPDATE_FILTERS, SET_GLOBAL_FILTER, SET_GLOBAL_FILTERS } from './types'

export const clearData = context => {
  return {
    type: `[${context}]_CLEAR_DATA`,
  }
}

export const fetchData = (context, data, featureFlags) => {
  return {
    type: `[${context}]_FETCH_REQUESTED`,
    data,
    featureFlags,
  }
}

export const fetchCSV = (context, params, filename) => {
  return {
    type: `[${context}]_FETCH_REQUESTED`,
    params,
    filename,
  }
}

export const setFilters = (context, key, value) => {
  return {
    type: `[${context}]_UPDATE_FILTERS`,
    key: key,
    value: value,
  }
}

export const setGlobalFilters = data => {
  return {
    type: SET_GLOBAL_FILTERS,
    data,
  }
}

export const setGlobalFilter = (key, value) => {
  return {
    type: SET_GLOBAL_FILTER,
    key: key,
    value: value,
  }
}

export const deliveryOptionsUpdateFilters = filters => {
  return {
    type: DELIVERY_OPTIONS_UPDATE_FILTERS,
    filters,
  }
}
