import {
  DELIVERY_PROMISES_FETCH_REQUESTED,
  DELIVERY_PROMISES_FETCH_SUCCEEDED,
  DELIVERY_PROMISES_FETCH_FAILED,
  DELIVERY_PROMISES_UPDATE_FILTERS,
  DELIVERY_PROMISES_CLEAR_DATA,
} from 'actions/types'
import { STATUSES } from 'helpers/constants'

const initialState = {
  data: [],
  status: STATUSES.initial,
  pageInfo: {},
  wdays: [],
  zipcode: '',
}

const deliveryPromises = (state = initialState, action) => {
  switch (action.type) {
    case DELIVERY_PROMISES_FETCH_REQUESTED:
      return {
        ...state,
        data: [],
        status: STATUSES.requested,
        pageInfo: {},
      }
    case DELIVERY_PROMISES_FETCH_SUCCEEDED:
      return {
        ...state,
        status: STATUSES.resolved,
        data: action.deliveryPromises,
        pageInfo: {
          ...action.pageInfo,
          hasNextPage: action.pageInfo.hasNextPage ?? state.pageInfo.hasNextPage,
          hasPreviousPage: action.pageInfo.hasPreviousPage ?? state.pageInfo.hasPreviousPage,
        },
      }
    case DELIVERY_PROMISES_FETCH_FAILED:
      return { ...initialState, status: STATUSES.failed }
    case DELIVERY_PROMISES_UPDATE_FILTERS:
      return {
        ...state,
        [action.key]: action.value,
      }
    case DELIVERY_PROMISES_CLEAR_DATA:
      return {
        ...state,
        data: [],
        status: STATUSES.initial,
        pageInfo: {},
      }
    default:
      return state
  }
}

export default deliveryPromises
