import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import AppConfig from 'config'
import Filter from 'components/shared/filter/filter'
import { setFilter } from 'actions/shippingLabels'

export const CountryFilter = props => {
  const handleChange = e => {
    props.dispatch(setFilter('country', e.target.value))
  }

  const calculateAvailableCountries = () => {
    let countries = AppConfig.countries.filter(country => props.countries.includes(country.name))

    return countries
      .filter(country => country.continent === props.continent)
      .map(country => country.name)
  }

  const continentNotSelected = () => !props.continent

  return (
    <Filter
      name='country'
      displayName='country'
      displayUpcase
      onChange={handleChange}
      data={calculateAvailableCountries()}
      selectedValue={props.country}
      disabled={continentNotSelected()}
      defaultValue='All'
      defaultText='All Countries'
      labelText='Country'
    />
  )
}

CountryFilter.propTypes = {
  dispatch: PropTypes.func.isRequired,
  countries: PropTypes.array.isRequired,
  country: PropTypes.string,
  continent: PropTypes.string,
}

function mapStateToProps(state) {
  return {
    continent: state.shippingLabels.continent,
    country: state.shippingLabels.country,
  }
}

const connectedCountryFilter = connect(mapStateToProps)(CountryFilter)

export default connectedCountryFilter
