import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { fetchData } from 'actions/index'
import { AUDIT_LOGS } from 'actions/context'
import AuditLogsTable from './auditLogsTable/auditLogsTable'

export const AuditLogs = props => {
  useEffect(() => {
    props.dispatch(fetchData(AUDIT_LOGS, null))
  }, [])

  if (props.canManage) {
    return <AuditLogsTable data={props.data} isLoading={props.isLoading} />
  } else {
    return null
  }
}

AuditLogs.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  data: PropTypes.object,
  canManage: PropTypes.bool,
}

function mapStateToProps(state) {
  return {
    isLoading: state.auditLogs.isLoading,
    data: state.auditLogs.data,
    canManage: state.currentUser.canManage,
  }
}

const connectedAuditLogs = connect(mapStateToProps)(AuditLogs)

export default connectedAuditLogs
