import { call, put, takeLatest } from 'redux-saga/effects'

import authenticationApi from 'apis/authentication.api'
import { deliveryOptionsApi } from 'apis/deliveryOptions.api'
import {
  DELIVERY_OPTIONS_UPDATE_SUCCEEDED,
  DELIVERY_OPTIONS_UPDATE_FAILED,
  DELIVERY_OPTIONS_UPDATE_REQUESTED,
} from 'actions/types'

export function* updateDeliveryOptions(action) {
  try {
    const response = yield call(
      deliveryOptionsApi.updateDeliveryOptions,
      action.country,
      action.brand,
      action.csvContent,
      action.firstDeliveryWeek,
    )

    yield put({
      type: DELIVERY_OPTIONS_UPDATE_SUCCEEDED,
      data: { responseBody: response.body },
    })
  } catch (error) {
    const failureAction = { type: DELIVERY_OPTIONS_UPDATE_FAILED }

    authenticationApi.handleError(error, action, failureAction)
    yield put(failureAction)
  }
}

export const updateDeliveryOptionsSagas = [
  takeLatest(DELIVERY_OPTIONS_UPDATE_REQUESTED, updateDeliveryOptions),
]
