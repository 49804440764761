import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Filter from 'components/shared/filter/filter'
import { setFilters } from 'actions/shippingCsvs'

export const ProductionSitesFilter = props => {
  const handleChange = e => {
    props.dispatch(setFilters('productionSite', e.target.value))
  }

  return (
    <Filter
      name='productionSite'
      displayName='productionSite'
      displayUpcase
      onChange={handleChange}
      data={props.productionSites}
      selectedValue={props.productionSite}
      defaultValue='All'
      defaultText='All FCs'
      labelText='Production Site'
    />
  )
}

ProductionSitesFilter.propTypes = {
  dispatch: PropTypes.func.isRequired,
  productionSite: PropTypes.string,
  productionSites: PropTypes.array,
}

function mapStateToProps(state) {
  return {
    productionSite: state.shippingCsvs.productionSite,
    productionSites: state.shippingCsvs.productionSites,
    countryProductionSitesMapping: state.shippingCsvs.data.countryProductionSitesMapping,
    country: state.globalFilters.country,
  }
}

const connectedProductionSitesFilter = connect(mapStateToProps)(ProductionSitesFilter)

export default connectedProductionSitesFilter
