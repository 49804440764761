import React from 'react'
import { connect } from 'react-redux'
import { filter, isEmpty } from 'lodash'
import queryString from 'query-string'
import PropTypes from 'prop-types'
import CountryFilter from './countryFilter/countryFilter'
import ContinentFilter from './continentFilter/continentFilter'
import CSVDownloadButton from 'components/shared/csvDownloadButton/csvDownloadButton'
import BrandsFilter from 'components/shared/brandsFilter/brandsFilter'
import ShippingLabelsPostButton from './shippingLabelsPostButton/shippingLabelsPostButton'
import ShippingLabelsTable from './shippingLabelsTable/shippingLabelsTable'
import ShippingLabelsFilters from './shippingLabelsFilters/shippingLabelsFilters'
import DeliveryDatePicker from 'components/shared/deliveryDatePicker/deliveryDatePicker'
import { fetchData } from 'actions/index'
import { setFilter } from 'actions/shippingLabels'
import { helpers } from 'helpers/helpers'
import { SHIPPING_LABELS } from 'actions/context'
import { openErrorAlert } from 'actions/alert/alert'

import './shippingLabels.css'

function computeFeatureFlags(searchString) {
  let data = []

  if (searchString.length > 0) {
    const queryObj = queryString.parse(searchString)

    if (queryObj['featureFlags']) {
      data = queryObj['featureFlags'].split(',')
    }
  }

  return data
}

function computeQueryStringFilters(searchString) {
  let data = {}

  if (searchString.length > 0) {
    const queryObj = queryString.parse(searchString)
    const filters = ['continent', 'country', 'brand', 'deliveryDate']

    filters.forEach(key => {
      if (queryObj[key]) {
        data[key] = queryObj[key].toLowerCase()
      }
    })
  }

  return data
}

export function ShippingLabels(props) {
  const { location, data, countriesAllowedToView, status, dispatch } = props
  const [activeFilters, setActiveFilters] = React.useState({})
  const [filteredData, setFilteredData] = React.useState(data)
  const [featureFlags] = React.useState(() => computeFeatureFlags(location.search))
  const [filtersFromQueryString] = React.useState(() => computeQueryStringFilters(location.search))

  const REQUIRED_CONTINENT_FILTER_MESSAGE =
    '"continent" value is required to filter Shipping Labels by URL params'

  const filterData = newFilters => {
    setActiveFilters(newFilters)
    setFilteredData(filter(data, activeFilters))
  }

  const setFiltersAndloadDataByQueryString = () => {
    // TO-DO
    // Find out if these query strings are being used by anyone and remove if not
    // For now, not dispatching/setting the delivery date range value here
    // because it's causing other bugs with the date range selector (on click)

    const continent = filtersFromQueryString.continent
    const country = filtersFromQueryString.country || 'All'
    const brand = filtersFromQueryString.brand || 'All'

    // getting deliveryDateRange from deliveryDate URL Param
    const deliveryDate = filtersFromQueryString.deliveryDate || helpers.today()
    const deliveryDateRange = [deliveryDate, deliveryDate]

    dispatch(setFilter('continent', continent))
    dispatch(setFilter('country', country))
    dispatch(setFilter('brand', brand))

    const data = { continent, country, brand, deliveryDateRange }
    dispatch(fetchData(SHIPPING_LABELS, data, featureFlags))
  }

  const hasQueryStringFilters = () => !isEmpty(filtersFromQueryString)

  const showRequiredContinentError = () => {
    dispatch(openErrorAlert(REQUIRED_CONTINENT_FILTER_MESSAGE))
  }

  React.useEffect(() => {
    if (hasQueryStringFilters()) {
      filtersFromQueryString.continent
        ? setFiltersAndloadDataByQueryString()
        : showRequiredContinentError()
    }
  }, [])

  React.useEffect(() => {
    setActiveFilters({})
    setFilteredData(data ? filter(data, activeFilters) : null)
  }, [data])

  const csvDownloadLink = data => {
    const { continent, country, brand, deliveryDateRange } = props

    if (data && data.length > 0) {
      const continentWithoutSpaces = continent.replace(/\s/g, '')
      const formattedDateRange = deliveryDateRange.join('-to-')
      const formattedCountry = country.toUpperCase() === 'ALL' ? 'All_Countries' : country
      const formattedBrand = brand.toUpperCase() === 'ALL' ? 'All_Brands' : brand

      return (
        <CSVDownloadButton
          filename={`tracking_${continentWithoutSpaces}_${formattedCountry}_${formattedBrand}_${formattedDateRange}.csv`}
          data={data}
        />
      )
    }

    return null
  }

  return (
    <div className='shipping-labels'>
      <div className='shipping-labels__required-filters'>
        <ContinentFilter context={SHIPPING_LABELS} />
        <CountryFilter context={SHIPPING_LABELS} countries={countriesAllowedToView} />
        <BrandsFilter context={SHIPPING_LABELS} />
        <DeliveryDatePicker context={SHIPPING_LABELS} selectsDateRange />

        <div className='filter__post-button'>
          <ShippingLabelsPostButton featureFlags={featureFlags} />
        </div>
      </div>

      <ShippingLabelsFilters onFiltersUpdate={filterData} data={data} activeFilters={activeFilters}>
        {csvDownloadLink(filteredData)}
      </ShippingLabelsFilters>

      <ShippingLabelsTable status={status} data={filteredData} featureFlags={featureFlags} />
    </div>
  )
}

ShippingLabels.propTypes = {
  dispatch: PropTypes.func.isRequired,
  country: PropTypes.string,
  brand: PropTypes.string,
  deliveryDateRange: PropTypes.array,
  status: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      shipmentNumber: PropTypes.string.isRequired,
      shipmentDate: PropTypes.string.isRequired,
      deliveryDate: PropTypes.string.isRequired,
      shipper: PropTypes.string.isRequired,
      trackingNumber: PropTypes.string,
    }),
  ),
  countriesAllowedToView: PropTypes.array,
  location: PropTypes.string,
  continent: PropTypes.string,
}

function mapStateToProps(state) {
  return {
    continent: state.shippingLabels.continent,
    country: state.shippingLabels.country,
    deliveryDateRange: state.globalFilters.deliveryDateRange,
    brand: state.globalFilters.brands,
    status: state.shippingLabels.status,
    data: state.shippingLabels.data,
    countriesAllowedToView: state.currentUser.countriesAllowedToView,
  }
}

const connectedShippingLabels = connect(mapStateToProps)(ShippingLabels)

export default connectedShippingLabels
