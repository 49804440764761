import {
  TRACKING_ACTIVITIES_CSVS_UPDATE_FILTERS,
  TRACKING_ACTIVITIES_CSVS_UPDATE_LOADING,
} from './types'

export const setFilters = (key, value) => {
  return {
    type: TRACKING_ACTIVITIES_CSVS_UPDATE_FILTERS,
    key: key,
    value: value,
  }
}

export const setLoading = loading => {
  return {
    type: TRACKING_ACTIVITIES_CSVS_UPDATE_LOADING,
    loading,
  }
}
