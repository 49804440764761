import React from 'react'
import PropTypes from 'prop-types'

import Filter from 'components/shared/filter/filter'

export default function ProductionSitesFilter(props) {
  return (
    <Filter
      name='productionSite'
      displayName='FC'
      defaultText='All FCs'
      onChange={props.onChange}
      data={props.productionSites}
    />
  )
}

ProductionSitesFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  productionSites: PropTypes.array.isRequired,
}
