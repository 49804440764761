import {
  SHIP_ADDRESS_FETCH_REQUESTED,
  SHIP_ADDRESS_FETCH_SUCCEEDED,
  SHIP_ADDRESS_FETCH_FAILED,
  SHIP_ADDRESS_UPDATE_SUCCEEDED,
  SHIP_ADDRESS_UPDATE_FAILED,
  SHIP_ADDRESS_UPDATE_REQUESTED,
} from 'actions/types'

const initialState = {
  isLoading: false,
  data: {
    firstname: '',
    lastname: '',
    line1: '',
    line2: '',
    houseNumber: '',
    city: '',
    state: '',
    stateAbbreviation: '',
    phone: '',
    zipcode: '',
  },
}

const shipAddress = (state = initialState, action) => {
  switch (action.type) {
    case SHIP_ADDRESS_FETCH_REQUESTED:
      return { isLoading: true, data: null }
    case SHIP_ADDRESS_FETCH_SUCCEEDED:
      return { isLoading: false, data: action.data }
    case SHIP_ADDRESS_FETCH_FAILED:
      return initialState
    case SHIP_ADDRESS_UPDATE_REQUESTED:
      return { isLoading: true, data: action.data }
    case SHIP_ADDRESS_UPDATE_SUCCEEDED:
      return { isLoading: false, data: action.data }
    case SHIP_ADDRESS_UPDATE_FAILED:
      return initialState
    default:
      return state
  }
}

export default shipAddress
