import React from 'react'
import { sortBy, kebabCase, startCase } from 'lodash'
import PropTypes from 'prop-types'

import DeliveryDateExceptionsTableRow from './deliveryDateExceptionsTableRow/deliveryDateExceptionsTableRow'
import CutoffInfoTip from './cutoffInfoTip/cutoffInfoTip'
import TypeInfoTip from './typeInfoTip/typeInfoTip'

import './deliveryDateExceptionsTable.css'
import { helpers } from 'helpers/helpers'

class DeliveryDateExceptionsTable extends React.Component {
  fields() {
    let header_fields = [
      'Region',
      'Regular Cutoff Date',
      'Regular Shipment Date',
      'Regular Delivery Date',
      'Regular Slot',
      'New Cutoff Date',
      'New Shipment Date',
      'New Delivery Date',
      'New Slot',
      'Type',
    ]

    if (helpers.showSuburbs(this.props.country)) {
      header_fields.splice(1, 0, 'Suburb')
    }
    return header_fields
  }

  sortExceptionsData(data) {
    if (!data || data.length === 0) {
      return []
    }

    const regionSortFn = exception => {
      return [
        exception.region,
        exception.suburb,
        exception['transitTime'],
        exception['regularSlot']['shipperName'],
        exception['regularSlot']['from'],
        exception['regularSlot']['to'],
      ]
    }

    return sortBy(data, regionSortFn).filter(exception => exception.region)
  }

  renderHead() {
    const headerData = this.fields().map((field, index) => (
      <th
        className={`delivery-date-exceptions-table__table-head-columns
          delivery-date-exceptions-table__${kebabCase(field)}`}
        key={index}
      >
        <span className='delivery-date-exceptions-table__header-text'>{startCase(field)}</span>

        {field.includes('Cutoff') && <CutoffInfoTip />}
        {field.includes('Type') && <TypeInfoTip />}
      </th>
    ))

    return (
      <thead className='delivery-date-exceptions-table__table-head'>
        <tr>{headerData}</tr>
      </thead>
    )
  }

  renderRows(sortedOptions) {
    return sortedOptions.map((option, index) => (
      <DeliveryDateExceptionsTableRow
        data={option}
        deliveryOptions={this.props.deliveryOptions}
        key={index}
        country={this.props.country}
        orderType={this.props.orderType}
        currentUserCanCreateExceptions={this.props.currentUserCanCreateExceptions}
        currentUserCanDestroyExceptions={this.props.currentUserCanDestroyExceptions}
      />
    ))
  }

  renderTable(sortedOptions) {
    return (
      <table className='delivery-date-exceptions-table'>
        {this.renderHead()}
        <tbody>{this.renderRows(sortedOptions)}</tbody>
      </table>
    )
  }

  isEmpty(data) {
    return data && data.length === 0
  }

  displayNoResults() {
    return this.props.options && this.isEmpty(this.state.sortedOptions)
  }

  displayTable() {
    return !this.isEmpty(this.state.sortedOptions)
  }

  render() {
    if (this.props.isLoading) {
      return <div className='delivery-date-exceptions-table__loader'>Loading...</div>
    } else if (this.props.options) {
      const sortedOptions = this.sortExceptionsData(this.props.options)

      if (this.isEmpty(sortedOptions)) {
        return (
          <div className='delivery-date-exceptions-table__no-results-msg'>
            No deliveries today! Please select another date.
          </div>
        )
      }

      return this.renderTable(sortedOptions)
    } else {
      return false
    }
  }
}

DeliveryDateExceptionsTable.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  country: PropTypes.string,
  options: PropTypes.array,
  deliveryOptions: PropTypes.array,
  currentUserCanCreateExceptions: PropTypes.bool.isRequired,
  currentUserCanDestroyExceptions: PropTypes.bool.isRequired,
  orderType: PropTypes.string,
}

export default DeliveryDateExceptionsTable
