import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { clearData, setGlobalFilter } from 'actions'
import ZipLogDatePicker from '../ziplogDatepicker/ziplogDatepicker'

export const DeliveryDatePicker = props => {
  const updateSelectedDate = date => {
    props.dispatch(clearData(props.context))
    props.dispatch(setGlobalFilter('deliveryDate', date))
  }

  const onDateRangeSelect = dates => {
    props.dispatch(setGlobalFilter('deliveryDateRange', dates))
  }

  return (
    <ZipLogDatePicker
      defaultDate={props.deliveryDate}
      onDateSelect={updateSelectedDate}
      labelText='Delivery date'
      selectsDateRange={props.selectsDateRange}
      onDateRangeSelect={onDateRangeSelect}
      minDate={props.minDate}
      maxDate={props.maxDate}
      deliveryDateRange={props.deliveryDateRange}
    />
  )
}

DeliveryDatePicker.propTypes = {
  dispatch: PropTypes.func.isRequired,
  deliveryDate: PropTypes.string,
  context: PropTypes.string.isRequired,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  selectsDateRange: PropTypes.bool,
  deliveryDateRange: PropTypes.array,
}

function mapStateToProps(state) {
  return {
    deliveryDate: state.globalFilters.deliveryDate,
    deliveryDateRange: state.globalFilters.deliveryDateRange,
  }
}

const connectedDeliveryDatePicker = connect(mapStateToProps)(DeliveryDatePicker)

export default connectedDeliveryDatePicker
