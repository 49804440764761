import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import DeliveryPromisesAccordion from '../deliveryPromisesAccordion/deliveryPromisesAccordion'
import BaseTable from 'components/shared/baseTable/baseTable'
import Pagination from 'components/shared/pagination/pagination'
import { displayField, numberToWeekday } from 'helpers/helpers'
import { DELIVERY_PROMISES } from 'actions/context'

import { STATUSES } from 'helpers/constants'

import './deliveryPromisesTable.css'

const TABLE_NAME = 'delivery-promises-table'
const TABLE_HEADERS = [
  'Region',
  'Zipcode',
  'Weekday',
  'From',
  'To',
  'Start Date',
  'End Date',
  'Brand',
]

export const DeliveryPromisesTable = ({ deliveryPromises, paginationData, status }) => {
  const renderRows = () => {
    return deliveryPromises.map((deliveryPromise, index) => (
      <Fragment key={index}>
        <tr className={`${TABLE_NAME}__table-row delivery-promise-data`} key={`${index}-1`}>
          {renderColumns(deliveryPromise, index)}
        </tr>
        <tr className={`${TABLE_NAME}__table-row shipment-options-data`} key={`${index}-2`}>
          <td colSpan='8' key={`${index}-shipment-options`}>
            <DeliveryPromisesAccordion
              keyValue={`${index}-accordion`}
              shipmentOptions={deliveryPromise.shipmentOptions}
            />
          </td>
        </tr>
      </Fragment>
    ))
  }

  const renderColumns = (deliveryPromise, index) => {
    const deliveryPromiseRow = (
      <Fragment>
        <td key={`region-${index}`} className={`${TABLE_NAME}__row-column`}>
          {displayField(deliveryPromise.region)}
        </td>
        <td key={`zipcode-${index}`} className={`${TABLE_NAME}__row-column`}>
          {deliveryPromise.zipcode}
        </td>
        <td key={`wday-${index}`} className={`${TABLE_NAME}__row-column`}>
          {numberToWeekday(deliveryPromise.wday)}
        </td>
        <td key={`from-${index}`} className={`${TABLE_NAME}__row-column}`}>
          {deliveryPromise.from}
        </td>
        <td key={`to-${index}`} className={`${TABLE_NAME}__row-column}`}>
          {deliveryPromise.to}
        </td>
        <td key={`start-date-${index}`} className={`${TABLE_NAME}__row-column}`}>
          {deliveryPromise.startDate}
        </td>
        <td key={`end-date-${index}`} className={`${TABLE_NAME}__row-column}`}>
          {displayField(deliveryPromise.endDate)}
        </td>
        <td key={`brand-${index}`} className={`${TABLE_NAME}__row-column}`}>
          {deliveryPromise.brand}
        </td>
      </Fragment>
    )

    return deliveryPromiseRow
  }

  if (status === STATUSES.requested) {
    return <div className='delivery-promises-table__loader'>Loading...</div>
  } else if (status === STATUSES.resolved && deliveryPromises.length === 0) {
    return (
      <div className='delivery-promises-table__no-results-msg'>No Delivery Promises found.</div>
    )
  } else if (deliveryPromises.length === 0) {
    return null
  } else {
    return (
      <Fragment>
        <BaseTable headers={TABLE_HEADERS} tableName={TABLE_NAME}>
          {renderRows()}
        </BaseTable>
        <Pagination context={DELIVERY_PROMISES} {...paginationData} />
      </Fragment>
    )
  }
}

DeliveryPromisesTable.propTypes = {
  deliveryPromises: PropTypes.array,
  paginationData: PropTypes.shape({
    data: PropTypes.object,
    pageInfo: PropTypes.shape({
      endCursor: PropTypes.string,
      hasNextPage: PropTypes.bool,
      hasPreviousPage: PropTypes.bool,
      startCursor: PropTypes.string,
    }),
  }),
  status: PropTypes.number.isRequired,
}

DeliveryPromisesTable.defaultProps = {
  deliveryPromises: [],
  status: STATUSES.initial,
}

export default DeliveryPromisesTable
