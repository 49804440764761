import React from 'react'
import { MultiSelect } from 'react-multi-select-component'
import PropTypes from 'prop-types'

import './rolesSelect.css'

export const RolesSelect = props => {
  const getOptions = () => {
    return props.roles ? props.roles.map(r => roleOption(r)) : []
  }

  const roleOption = role => {
    return { label: role.title, value: role.title }
  }

  return (
    <>
      <div className='roles-filter multi-select-filter'>
        <label className='filter__label'>Roles</label>
        <MultiSelect
          options={getOptions()}
          onChange={props.onChange}
          value={props.selectedValue}
          labelledBy='Select'
          hasSelectAll={false}
          disableSearch
        />
      </div>
      <br />
    </>
  )
}

RolesSelect.propTypes = {
  onChange: PropTypes.func,
  selectedValue: PropTypes.string,
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
    }),
  ),
}

RolesSelect.defaultProps = {
  roles: [],
}

export default RolesSelect
